import { IconsFormat } from '../types/icons-format.type';
import { IconDefinition, faSpinner, faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import { ActionTrigger } from '../types/action-trigger.type';
import { Tooltip } from '../types/tooltip.type';

export class Icons {
  private icon?: IconDefinition;
  private class?: string;
  private tooltip?: Tooltip;
  private loading?: boolean;
  private condition?: (param?: any) => boolean;
  private actionTrigger?: ActionTrigger;
  private action?: (event: any) => any;

  constructor(config?: IconsFormat) {
    this.icon = config?.icon;
    this.class = config?.class;
    this.tooltip = config?.tooltip;
    this.loading = config?.isLoading ?? false;
    this.condition = config?.condition;
    this.actionTrigger = config?.actionTrigger;
    this.action = config?.action;
  }

  public getIcon(): IconDefinition | undefined {
    return this.icon;
  }

  public setIcon(icon: IconDefinition): void {
    this.icon = icon;
  }

  public unsetIcon(): void {
    this.icon = undefined;
  }

  public getClass(): string {
    return this.class ?? '';
  }

  public setClass(classList: string[]): void {
    this.class = classList.join(' ');
  }

  public getTooltip(): Tooltip | undefined {
    return this.tooltip;
  }

  public setTooltip(tooltip: Tooltip): void {
    this.tooltip = tooltip;
  }

  public isLoading(): boolean {
    return this.loading ?? false;
  }

  public setLoading(isLoading: boolean): void {
    this.loading = isLoading;
  }

  public runCondition(): ((param?: any) => boolean) | undefined {
    return this.condition;
  }

  public getActionTrigger(): ActionTrigger | undefined {
    return this.actionTrigger;
  }

  public setActionTrigger(actionTrigger: ActionTrigger): void {
    this.actionTrigger = actionTrigger;
  }

  public runAction(): ((event: any) => any) | undefined {
    return this.action;
  }

  public startLoading(): void {
    this.loading = true;
    this.icon = faSpinner;
    this.class = 'text-neutral-500';
  }

  public stopLoading(isValid: boolean): void {
    this.loading = false;
    this.icon = isValid ? faCheck : faTimes;
    this.class = 'text-' + (isValid ? 'success' : 'alert') + '-500';
  }
}
