import * as fromHeader from 'src/app/core/state/header';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AbonnementService } from './abonnement.service';
import { map, catchError } from 'rxjs/operators';
import { GeneriqueResponse } from '../models/shared/generiqueResponse';
import { Store } from '@ngrx/store';
import { State } from 'src/app/core/state/core.state';
import { MessageService } from 'primeng/api';
import { MessageServiceUtils } from 'src/app/shared/utils/messageServiceUtils';
import { AppResource } from 'src/app/app.resource';
import { NavigationService } from './../../api/services/navigation.service';

@Injectable({
  providedIn: 'root'
})
export class AbonnementValidationService {
  messageServiceUtils: MessageServiceUtils;

  constructor(
    private abonnementService: AbonnementService,
    private router: Router,
    private store: Store<State>,
    private messageService: MessageService,
    private resources: AppResource,
    private navigationService: NavigationService
  ) {
    this.messageServiceUtils = new MessageServiceUtils(this.messageService, this.resources);
  }

  openAbonnementOnNewTab(numAbonnement: string): void {
    this.validateAbonnement(numAbonnement).subscribe(isValid => {
      if (isValid) {
        const url = this.router.serializeUrl(
          this.router.createUrlTree(['/abonnement/synthese', numAbonnement])
        );
        console.log(url);
        window.open(url, '_blank');
      }
    });
  }

  validateAbonnement(numAbonnement: string): Observable<boolean> {
    return this.abonnementService.exist(numAbonnement).pipe(
      map((res: GeneriqueResponse) => {
        if (res.Code == 100) {
          return true;
        } else {
          this.handleNavigationError(res.Description, res.Code, res);
          return false;
        }
      }),
      catchError(err => {
        this.handleNavigationError(err, err.error.Code, undefined);
        return of(false);
      })
    );
  }

  private handleNavigationError(errorDescription: string, code: number, res?: GeneriqueResponse) {
    if (this.navigationService.isDirectAccess()) {
      // Redirect to error page if accessed directly or from an external source
      this.router.navigate(['/error']);
      if (code == 102) {
        this.store.dispatch(fromHeader.updateError({ errorSource: 'abonnement', message: null }));
        this.router.navigate(['erreur']);
      }
      if (code == 1158) {
        errorDescription = res?.Description ?? errorDescription;
        this.store.dispatch(
          fromHeader.updateError({ errorSource: 'abonnement', message: res?.Description ?? null })
        );
        this.router.navigate(['erreur']);
      }
    }
    // Show popup whatever if direct access or not
    this.messageServiceUtils.warning(errorDescription ?? 'Erreur de validation');
  }
}
