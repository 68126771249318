import { isUndefined } from 'is-what';
import { RegexBank } from 'src/app/shared/utils/regex-bank';
import { IToTreeNode } from '../../interface/to-tree-node.interface';
import { WaterpTreeNode } from '../../interface/waterp-tree-node.interface';
import { INiveauHabilitation } from '../../interface/autorisations/niveauHabilitation.interface';
import { Autorisation } from './autorisation';
import { IAutorisations } from '../../interface/autorisations/autorisation-profil.interface';
import { IRight } from '../../interface/autorisations/right.interface';
import { ITemplateHabilitation } from '../../interface/autorisations/template-habilitation.interface';
import { IProcedure } from '../../interface/autorisations/procedure.interface';
import { ISensible } from '../../interface/autorisations/information-sensible.interface';
import { IBindedRight } from '../../interface/autorisations/binded-right.interface';

export class ListAutorisations implements IToTreeNode {
  /** @var web Groupe d'autorisation séparé entre les Fonctions et les Informations sensibles */
  private web: Map<string, Autorisation[]>;
  /** @var clientLourd Groupe d'assignation regroupant les autorisations par Code Application */
  private clientLourd: Map<string, Autorisation[]>;
  /** @const categoryName Constantes des noms des différentes catégories d'autorisations */
  private categoryName: {
    fonctionsWeb: string;
    infosSensibleWeb: string;
    procedures: string;
    zonesSensible: string;
    applications: string;
  };

  private niveauHabilitationRessource?: INiveauHabilitation;

  /**
   * Constructeur de l'objet réunissant les différentes liste en fonction du résultat tiré de la
   * requête /Profil/GetAutorisations
   * @param distantAutorisations Objet distant reçu dans la response
   * @param niveauHabilitationRessource NiveauHabilitation minimum entre la ressource cible et l'utilisateur
   */
  constructor(
    distantAutorisations?: IAutorisations,
    niveauHabilitationRessource?: INiveauHabilitation
  ) {
    this.niveauHabilitationRessource = niveauHabilitationRessource;
    // Initialisation des Mapper
    this.web = new Map<string, Autorisation[]>();
    this.clientLourd = new Map<string, Autorisation[]>();

    // Nommage des catégories
    this.categoryName = {
      fonctionsWeb: 'Fonctions',
      infosSensibleWeb: 'Informations Sensibles',
      procedures: 'Procédures',
      zonesSensible: 'Zones sensibles',
      applications: 'Applications'
    };

    if ((distantAutorisations?.AutorisationWeb?.FonctionsWeb?.length ?? 0) > 0) {
      // Initialisation de la partie "Autorisations Web/FonctionsWeb"
      this.web.set(
        this.categoryName.fonctionsWeb,
        distantAutorisations?.AutorisationWeb.FonctionsWeb.map(
          (right: IRight) => new Autorisation({ autorisation: right })
        ).sort((a: Autorisation, b: Autorisation) => a.getName().localeCompare(b.getName())) ??
          new Array<Autorisation>()
      );
    }

    if ((distantAutorisations?.AutorisationWeb?.InformationsSensiblesWeb?.length ?? 0) > 0) {
      // Initialisation de la partie "Autorisations Web/Informations Sensibles"
      this.web.set(
        this.categoryName.infosSensibleWeb,
        distantAutorisations?.AutorisationWeb.InformationsSensiblesWeb.map(
          (right: IRight) => new Autorisation({ autorisation: right })
        ).sort((a: Autorisation, b: Autorisation) => a.getName().localeCompare(b.getName())) ??
          new Array<Autorisation>()
      );
    }

    // Initialisation de la partie "Autorisations Client Lourd"
    this.clientLourd = this.extractHabilitations(
      distantAutorisations?.AutorisationClientLourd.TemplateHabilitations
    );

    this.sort();
  }

  //#region Accesseurs
  public getWeb(): Map<string, Autorisation[]> {
    return this.web;
  }

  private setWeb(web: Map<string, Autorisation[]>): void {
    this.web = web;
  }

  private setWebAutorisation(list: string, right: Autorisation): void {
    this.web.has(list)
      ? this.web
          .get(list)
          ?.find((autorisation: Autorisation) => autorisation.getName() === right.getName()) ??
        this.web.get(list)?.push(right)
      : this.web.set(list, new Array<Autorisation>(right));
  }

  private removeWebAutorisation(list: string, right: Autorisation): void {
    if (this.web.has(list)) {
      let length: number = this.web.get(list)?.length ?? 0;
      let index: number =
        this.web
          .get(list)
          ?.findIndex((autorisation: Autorisation) => autorisation.getName() === right.getName()) ??
        -1;

      if (index > -1) length > 1 ? this.web.get(list)?.splice(index, 1) : this.web.delete(list);
    }
  }

  public getClientLourd(): Map<string, Autorisation[]> {
    return this.clientLourd;
  }

  private setClientLourd(clientLourd: Map<string, Autorisation[]>): void {
    this.clientLourd = clientLourd;
  }

  private setClientLourdAutorisation(
    list: string,
    right: Autorisation,
    parent?: Autorisation
  ): void {
    if (this.clientLourd.has(this.categoryName.applications)) {
      let applications: Autorisation[] =
        this.clientLourd.get(this.categoryName.applications) ?? new Array<Autorisation>();

      let application: Autorisation | undefined = applications.find(
        (autorisation: Autorisation) => autorisation.getName() === right.getName()
      );

      if (isUndefined(application)) {
        if (list === this.categoryName.applications) this.clientLourd.get(list)?.push(right);
        else {
          let application: Autorisation | undefined = applications?.find(
            (application: Autorisation) => application.getName() === parent?.getName()
          );

          if (!isUndefined(application)) application.set({ this: right, in: list });
          else if (parent) {
            parent.set({ this: right, in: list });
            applications?.push(parent);
          }
        }
      } else {
        application.set({ this: right, in: list });
      }
    } else if (parent) {
      parent.set({ this: right, in: list });
      this.clientLourd.set(this.categoryName.applications, new Array<Autorisation>(parent));
    }
  }

  private removeClientLourdAutorisation(
    list: string,
    right: Autorisation,
    parent?: Autorisation
  ): void {
    if (!this.clientLourd.has(this.categoryName.applications)) {
      return;
    }
    let applications: Autorisation[] =
      this.clientLourd.get(this.categoryName.applications) ?? new Array<Autorisation>();

    let listLength: number = this.clientLourd.get(list)?.length ?? 0;
    let indexRight: number =
      this.clientLourd
        .get(this.categoryName.applications)
        ?.findIndex((autorisation: Autorisation) => autorisation.getName() === right.getName()) ??
      -1;

    if (indexRight > -1) {
      // Cas où le droit à supprimer est directement dans la liste "Applications"
      if (!applications[indexRight].isLeaf()) {
        return;
      }

      listLength > 1
        ? this.clientLourd.get(list)?.splice(indexRight, 1)
        : this.clientLourd.delete(list);
    } else {
      // Cas où le droit à supprimer n'est pas dans la liste "Applications"
      // On cherche dans son parent
      let indexAppParent: number =
        applications.findIndex(
          (application: Autorisation) => application.getName() === parent?.getName()
        ) ?? -1;

      if (indexAppParent > -1) {
        applications[indexAppParent].remove({ this: right, in: list });
      }
      if (applications.length === 0) {
        this.clientLourd.delete(this.categoryName.applications);
      }
    }
  }
  //#endregion

  /**
   * Permet de convertir l'objet reçu contenant tous les éléments et listes en un format
   * compréhensible par l'interface
   * @param fromDistant Liste des Habilitations du Client Lourd
   * @returns La version convertie et interprétable par l'interface
   */
  private extractHabilitations(fromDistant?: ITemplateHabilitation[]): Map<string, Autorisation[]> {
    let applications: Map<string, Autorisation[]> = new Map<string, Autorisation[]>();

    fromDistant?.forEach((habilitation: ITemplateHabilitation) => {
      // On regroupe les procédures par applications
      habilitation.Procedures.forEach((procedure: IProcedure) => {
        this.extract(
          procedure.Procedure,
          this.categoryName.procedures,
          applications,
          habilitation.Applications
        );
      });

      // On regroupe les informations sensible par applications
      habilitation.InformationsSensibles.forEach((infoSensible: ISensible) =>
        this.extract(
          infoSensible,
          this.categoryName.zonesSensible,
          applications,
          habilitation.Applications
        )
      );

      // On récupère les Applications qui n'ont pas de procédures ou d'informations sensible liées
      this.extractApplications(habilitation, applications);
    });

    return applications;
  }

  /**
   * Permet d'extraire les Applications qui n'ont ni Procédures ni Zones sensibles
   * @param habilitation Template distant actuellement traité
   * @param applications Tableau final à renseigner par les Applications manquantes
   */
  private extractApplications(
    habilitation: ITemplateHabilitation,
    applications: Map<string, Autorisation[]>
  ): void {
    habilitation.Applications.forEach((application: IRight) => {
      let autorisation: Autorisation = new Autorisation(
        { autorisation: application },
        this.niveauHabilitationRessource
      );
      if (applications.has(this.categoryName.applications)) {
        let autorisationExist: Autorisation | undefined = applications
          .get(this.categoryName.applications)
          ?.find((pushedRight: Autorisation) => pushedRight.getName() === autorisation.getName());
        if (isUndefined(autorisationExist)) {
          applications.get(this.categoryName.applications)?.push(autorisation);
        }
      } else {
        applications.set(this.categoryName.applications, new Array<Autorisation>(autorisation));
      }
    });
  }

  /**
   * Permet de contrôller les différentes étape de conversion lors de la création de la liste au
   * nouveau format
   * @param T Permet de ne pas dupliquer le code et de permettre sa réutilisation, il étend l'interface
   * IBindedRight pour englober la possibilité de comprendre des interfaces tel que ISensible ou IProcedure
   * @param right L'autorisation à intégrer dans le Mapping
   * @param list Permet de nommer la liste où l'autorisation doit être intégré
   * @param map Fait référence à notre liste entière
   * @param applications Fait référence à la liste lié d'applications
   */
  private extract<T extends IBindedRight>(
    right: T,
    list: string,
    map: Map<string, Autorisation[]>,
    applications: IRight[]
  ): void {
    // On cherche dans les applications de l'habilitation d'origine l'élément nous permettant
    //d'avoir la combinaison "code + libellé". Il nous servira de nom dans la liste.
    let application: IRight | undefined = applications.find(
      (application: IRight) => application.Code === right.CodeApplication
    );

    if (!isUndefined(application)) {
      // Nous avons notre nom pour ce groupe d'autorisation
      let appName: string =
        application.Code + (application.Libelle ? '~' + application.Libelle : '');

      // Dans notre Mapping nous voulons que le groupe "Applications" soit présent
      if (map.has(this.categoryName.applications)) {
        // On recherche le code d'application à traiter
        let autorisation: Autorisation | undefined = map
          .get(this.categoryName.applications)
          ?.find((autorisation: Autorisation) => autorisation.getName() === appName);

        // Dans le cas où on le trouve on ira ajouter l'autorisation en direct, sinon on ajoutera
        //ce code au reste des codes d'applications de la liste tout en lui ajoutant la liste souhaité
        //et l'autorisation qui doit s'y trouver.
        autorisation
          ? autorisation.set({
              this: new Autorisation({ autorisation: right }, this.niveauHabilitationRessource),
              in: list
            })
          : map.get(this.categoryName.applications)?.push(
              new Autorisation(
                {
                  habilitationName: appName,
                  list: list,
                  code: application.Code,
                  autorisation: right
                },
                this.niveauHabilitationRessource
              )
            );
      } else {
        // Si c'est la première fois qu'on trouve "Application" alors on vient créer sa référence
        //et on assigne le premier code d'application souhaité, la liste qu'on traite et
        //sa première autorisation.
        map.set(
          this.categoryName.applications,
          new Array<Autorisation>(
            new Autorisation(
              {
                habilitationName: appName,
                list: list,
                code: application.Code,
                autorisation: right
              },
              this.niveauHabilitationRessource
            )
          )
        );
      }
    }
  }

  /**
   * Permet de convertir cet objet en TreeNode
   * @returns La version TreeNode de cet objet
   */
  public toTreeNode(): WaterpTreeNode<Autorisation> {
    let autorisationTree: WaterpTreeNode<Autorisation> = {
      children: new Array<WaterpTreeNode<Autorisation>>(),
      expanded: true,
      leaf: false
    };

    Object.entries(this).forEach(
      (property: [string, Map<string, Autorisation[]>], index: number) => {
        // On ignore les noms des catégories
        if (!['web', 'clientLourd'].includes(property[0])) {
          return;
        }

        // Mise en UpperCamelCase
        let trunkName: string = property[0][0].toUpperCase() + property[0].substring(1);

        // Séparation du UpperCamelCase par des espaces via Regex
        trunkName = trunkName.match(RegexBank.FirstLetterUppercaseWords)?.join(' ') ?? trunkName;

        let newTrunk: WaterpTreeNode<Autorisation> = this.toTrunk(
          index,
          'Autorisations ' + trunkName,
          property[1]
        );
        if ((newTrunk.children?.length ?? 0) > 0) autorisationTree.children?.push(newTrunk);
      }
    );

    return autorisationTree;
  }

  /**
   * Traitement de la propriété à transformer en tronc pour le future TreeNode
   * @param keyIndex Correspond à l'index de la propriété, est utilisé pour démarer la clé dans l'arbre
   * @param trunkName Nom du tronc
   * @param map Données de la propriété, utilisé pour la construction du tronc et de ses branches
   * @returns Le TreeNode à intégrer dans le TreeNode principale
   */
  private toTrunk(
    keyIndex: number,
    trunkName: string,
    map: Map<string, Autorisation[]>
  ): WaterpTreeNode {
    let level: number = 0;
    let keyTable: number[] = new Array<number>();
    keyTable.push(keyIndex);

    let trunk: WaterpTreeNode = {
      key: keyTable.join('-'),
      label: trunkName,
      type: 'N-' + level,
      expanded: map.size > 0,
      leaf: false,
      children: new Array<WaterpTreeNode<Autorisation>>()
    };

    if (!isUndefined(map)) {
      level++;
      let childLevel: number = level + 1;

      map.forEach((values: Autorisation[], key: string) => {
        if (keyTable[level] >= 0) {
          keyTable[level]++;
          keyTable.splice(childLevel);
        } else {
          keyTable.push(0);
        }

        let keyTree: WaterpTreeNode<Autorisation> = {
          key: keyTable.join('-'),
          label: key,
          parent: trunk,
          expanded: false,
          leaf: false,
          type: 'N-' + level
        };
        keyTree.children = values.map((autorisation: Autorisation, index: number) => {
          keyTable[childLevel] = index;
          keyTable.splice(childLevel + 1);

          return autorisation.toTreeNode(keyTable, keyTree, childLevel);
        });

        trunk.children?.push(keyTree);
      });
    }
    return trunk;
  }

  /**
   * Permet d'ajouter un droit dans la liste de cet Objet
   * @param params Formulation du droit de la liste où le trouver et potentiellementson parent
   */
  public set(params: { this: Autorisation; in: string; from?: Autorisation }): void {
    if (
      !this.hasInto(this.web, params.this, params.in) &&
      [this.categoryName.fonctionsWeb, this.categoryName.infosSensibleWeb].includes(params.in)
    ) {
      this.setWebAutorisation(params.in, params.this);
    }
    if (
      !this.hasInto(this.clientLourd, params.this) &&
      [
        this.categoryName.applications,
        this.categoryName.procedures,
        this.categoryName.zonesSensible
      ].includes(params.in)
    ) {
      this.setClientLourdAutorisation(params.in, params.this, params.from);
    }
  }

  /**
   * Permet de supprimer un droit de la liste de cet Objet
   * @param params Formulation du droit de la liste où le trouver et potentiellement son parent
   */
  public remove(params: { this: Autorisation; in: string; from?: Autorisation }): void {
    if (this.hasInto(this.web, params.this, params.in)) {
      this.removeWebAutorisation(params.in, params.this);
    }
    if (this.hasInto(this.clientLourd, params.this)) {
      this.removeClientLourdAutorisation(params.in, params.this, params.from);
    }
  }

  /**
   * Permet de vérifier si l'autorisation à examiner est dans l'une de nos listes de droits
   * ? Ne remonte pas dans quelle liste il a été trouvé
   * @param right Autorisation à examiner
   * @returns Vrai si c'est le cas, Faux sinon
   */
  public has(right: Autorisation): boolean {
    return this.hasInto(this.web, right) || this.hasInto(this.clientLourd, right);
  }

  /**
   * Permet de vérifier si l'autorisation à examiner se trouve dans la liste mappée mentionnée en paramètre
   * et - potentiellement si précisé - dans la liste contenue dans la liste de droits donné
   * @param map Liste mappée de droits (dans notre format il s'agira de web ou client lourd)
   * @param right Autorisation à examiner
   * @param list? Liste à inspecter
   * @returns
   */
  private hasInto(map: Map<string, Autorisation[]>, right: Autorisation, list?: string): boolean {
    let resultWithoutList: boolean = false;
    map.forEach(
      (values: Autorisation[]) =>
        (resultWithoutList =
          resultWithoutList ||
          !isUndefined(
            values.find(
              (autorisation: Autorisation) =>
                autorisation.getName() === right.getName() || autorisation.has(right)
            )
          ))
    );
    return list
      ? map.has(list) &&
          !isUndefined(
            map
              .get(list)
              ?.find((autorisation: Autorisation) => autorisation.getName() === right.getName())
          )
      : resultWithoutList;
  }

  /**
   * Permet de trier les listes contenues dans l'ordre souhaité
   * @param ascend Trie dans l'ordre croissant/alphabétique si Vrai
   */
  public sort(ascend: boolean = true): void {
    this.web = new Map<string, Autorisation[]>(
      [...this.web.entries()].sort((a: [string, Autorisation[]], b: [string, Autorisation[]]) =>
        ascend ? a[0].localeCompare(b[0]) : b[0].localeCompare(a[0])
      )
    );
    this.web
      .get(this.categoryName.fonctionsWeb)
      ?.sort((a: Autorisation, b: Autorisation) =>
        ascend ? a.getName().localeCompare(b.getName()) : b.getName().localeCompare(a.getName())
      );

    this.web
      .get(this.categoryName.infosSensibleWeb)
      ?.sort((a: Autorisation, b: Autorisation) =>
        ascend ? a.getName().localeCompare(b.getName()) : b.getName().localeCompare(a.getName())
      );

    this.clientLourd
      .get(this.categoryName.applications)
      ?.sort((a: Autorisation, b: Autorisation) =>
        ascend ? a.getName().localeCompare(b.getName()) : b.getName().localeCompare(a.getName())
      )
      .forEach((application: Autorisation) => application.sort(ascend));
  }

  /**
   * Lors de la sélection du node, extrait les équivalents de l'autorisation client lourd
   * @param node Autorisation client lourd
   * @returns Équivalents web de l'autorisation client lourd
   */
  public doWhenSelect(node: WaterpTreeNode<Autorisation>): string[] {
    if (node.data && this.hasInto(this.web, node.data)) {
      return new Array<string>();
    }

    return (
      node.data?.doWhenSelect().flatMap(
        (equivalent: { Key: string; Value: string[] }) =>
          this.web
            .get(this.convertCategoryNames(equivalent.Key))
            ?.filter((right: Autorisation) => equivalent.Value.includes(right.getCode()))
            .map((right: Autorisation) => right.getName()) ?? new Array<string>()
      ) ?? new Array<string>()
    );
  }

  /**
   * Lors de la désélection du node, extrait les équivalents de l'autorisation client lourd
   * @param data Autorisation client lourd
   * @returns Équivalents web de l'autorisation client lourd
   */
  public doWhenUnselect(node: WaterpTreeNode<Autorisation>): string[] {
    if (node.data && this.hasInto(this.web, node.data)) {
      return new Array<string>();
    }

    return (
      node.data?.doWhenUnselect().flatMap(
        (equivalent: { Key: string; Value: string[] }) =>
          this.web
            .get(this.convertCategoryNames(equivalent.Key))
            ?.filter((right: Autorisation) => equivalent.Value.includes(right.getCode()))
            .map((right: Autorisation) => right.getName()) ?? new Array<string>()
      ) ?? new Array<string>()
    );
  }

  private convertCategoryNames(name: string): string {
    switch (name) {
      case 'FonctionWeb':
        return this.categoryName.fonctionsWeb;
      case 'InformationSensibleWeb':
        return this.categoryName.infosSensibleWeb;
      default:
        return '';
    }
  }
}
