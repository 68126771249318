<div class="consulterIntervention" *ngIf="isVisible">
  <app-frame [title]="titleFrame" [bounceIn]="bounceIn">
    <i
      id="modifyIsVisible"
      *ngIf="!isFromRoute"
      class="pi pi-times content-header"
      (click)="modifyIsVisible(false)"></i>
    <app-loader class="content-frame form-content" *ngIf="isLoading"></app-loader>
    <div class="content-frame p-5" id="numAbonnement" *ngIf="!isLoading">
      <div class="mb-5">
        <div *ngIf="intervention.NumeroAbonnement; else withoutAbonnementTemplate">
          <div class="infos-abonnement flex flex-row space-x-5">
            <fa-icon class="info" [icon]="faInfoCircle"> </fa-icon>
            Abonnement n°{{ intervention.NumeroAbonnement }}
          </div>
        </div>
        <ng-template #withoutAbonnementTemplate>
          <div id="ExistIntervention" class="grid sm:grid-col-1 md:grid-cols-12">
            <div class="infos-localisation sm:col-span-12 space-x-4 md:col-span-12 lg:col-span-12">
              <div class="header">Localisation</div>
              <div class="grid sm:grid-col-1 md:grid-cols-2">
                <div class="infos">
                  <div class="grid sm:grid-col-3 md:grid-cols-3" id="CommuneRue">
                    <span class="pl-2 sm:col-span-1 md:col-span-1 lg:col-span-1 titre">Commune</span
                    ><span class="sm:col-span-2 md:col-span-2 lg:col-span-2 description">
                      {{ intervention.LibelleCommune }}
                    </span>
                    <span class="pl-2 sm:col-span-1 md:col-span-1 lg:col-span-1 titre">Rue</span
                    ><span class="sm:col-span-2 md:col-span-2 lg:col-span-2 description">
                      {{ intervention.LibelleTypeRue + ' ' + intervention.NomRue }}
                    </span>
                  </div>
                </div>
                <div class="infos">
                  <div class="grid sm:grid-col-3 md:grid-cols-3" id="NumerosDansRue">
                    <span class="pl-2 sm:col-span-1 md:col-span-1 lg:col-span-1 titre"
                      >N° début</span
                    ><span class="sm:col-span-2 md:col-span-2 lg:col-span-2 description">
                      {{ intervention.NumeroDansLaRueDebut }}
                    </span>
                    <span class="pl-2 sm:col-span-1 md:col-span-1 lg:col-span-1 titre">N° fin</span
                    ><span class="sm:col-span-2 md:col-span-2 lg:col-span-2 description">
                      {{ intervention.NumeroDansLaRueFin }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
      <div id="ExistIntervention" class="grid sm:grid-col-1 md:grid-cols-11" *ngIf="intervention">
        <div class="infos-lancement sm:col-span-1 space-x-4 md:col-span-5 lg:col-span-5">
          <div class="header">Lancement</div>
          <div class="grid sm:grid-col-1 md:grid-cols-2">
            <div class="infos">
              <H3>QUOI ?</H3>
              <div class="infos-complementaire">
                <p-chip
                  [hidden]="!intervention.LibelleTypeIntervention"
                  styleClass="libelle"
                  label="{{ intervention.LibelleTypeIntervention }}"></p-chip>
                <button
                  id="affichIntOrigine"
                  class="arrow"
                  pTooltip="Ouvrir l'intervention d'origine"
                  *ngIf="intervention.IdentifiantInterventionOrigine"
                  (click)="moveToIntervention(intervention.IdentifiantInterventionOrigine)">
                  <i class="pi pi-arrow-left"></i>
                </button>
                <button
                  id="affichIntAdministrative"
                  pTooltip="Ouvrir l'intervention administrative"
                  *ngIf="intervention.IdentifiantInterventionAdministrative"
                  (click)="moveToIntervention(intervention.IdentifiantInterventionAdministrative)"
                  class="arrow">
                  <i class="pi pi-arrow-right"></i>
                </button>
              </div>
              <div class="grid sm:grid-col-2 md:grid-cols-3" id="Motif">
                <span class="pl-2 sm:col-span-1 md:col-span-1 lg:col-span-1 titre">Motif</span
                ><span class="sm:col-span-2 md:col-span-2 lg:col-span-2 description">
                  {{ intervention.LibelleMotifLancement }}
                </span>
              </div>
              <div class="grid sm:grid-col-2 md:grid-cols-3" id="Action">
                <span class="pl-2 sm:col-span-1 md:col-span-1 lg:col-span-1 titre">Action</span
                ><span class="sm:col-span-2 md:col-span-2 lg:col-span-2 description">
                  {{ intervention.LibelleActionLancement }}
                </span>
              </div>
              <H3>QUI ?</H3>
              <div class="grid sm:grid-col-2 md:grid-cols-3" id="Unite">
                <span class="pl-2 sm:col-span-1 md:col-span-1 lg:col-span-1 titre">Unité</span
                ><span class="sm:col-span-2 md:col-span-2 lg:col-span-2 description">
                  {{ intervention.NumeroUniteLancement }} <br />{{ intervention.NomUniteLancement }}
                </span>
              </div>

              <div class="grid sm:grid-col-2 md:grid-cols-3">
                <span class="pl-2 sm:col-span-1 md:col-span-1 lg:col-span-1 titre">Ressource</span
                ><span class="sm:col-span-2 md:col-span-2 lg:col-span-2 description">
                  {{ intervention.MatriculeRessourceLancement }} <br />{{
                    intervention.NomRessourceLancement
                  }}
                </span>
              </div>
            </div>
            <div class="infos">
              <H3>
                QUAND ?
                <fa-icon
                  *ngIf="intervention.IndicateurUrgent"
                  class="warning"
                  [icon]="faWarning"
                  pTooltip="Intervention urgente"></fa-icon>
              </H3>
              <div class="grid sm:grid-col-2 md:grid-cols-2">
                <span class="pl-2 sm:col-span-1 md:col-span-1 lg:col-span-1 titre">Date limite</span
                ><span class="sm:col-span-2 md:col-span-2 lg:col-span-1 description">
                  {{ intervention.DateLimite
                  }}<!-- Date limite str-->
                </span>
              </div>
              <div class="grid sm:grid-col-2 md:grid-cols-2">
                <span class="pl-2 sm:col-span-1 md:col-span-1 lg:col-span-1 titre">Date prévue</span
                ><span class="sm:col-span-2 md:col-span-2 lg:col-span-1 description">
                  {{ intervention.DateRendezVous }}
                </span>
              </div>
              <div class="grid sm:grid-col-2 md:grid-cols-2">
                <span class="pl-2 sm:col-span-1 md:col-span-1 lg:col-span-1 titre"
                  >Tranche horaire</span
                ><span class="sm:col-span-2 md:col-span-2 lg:col-span-1 description">
                  {{ intervention.LibelleTypeTrancheHoraire }}
                </span>
              </div>
              <div class="grid sm:grid-col-2 md:grid-cols-2">
                <span class="pl-2 sm:col-span-1 md:col-span-1 lg:col-span-1 titre"
                  >Nombre intervenant</span
                ><span class="sm:col-span-2 md:col-span-2 lg:col-span-1 description">
                  {{ intervention.NombreRessource }}
                </span>
              </div>
              <div class="grid sm:grid-col-2 md:grid-cols-2">
                <span class="pl-2 sm:col-span-1 md:col-span-1 lg:col-span-1 titre">Durée</span>
                <span class="sm:col-span-2 md:col-span-2 lg:col-span-1 description">
                  {{ intervention.DureeStr }}
                </span>
              </div>
            </div>
          </div>
          <div class="grid sm:grid-col-2 md:grid-cols-2 infos-commentaire">
            <div class="infos">
              <div class="grid sm:grid-col-2 md:grid-cols-2">
                <span class="pl-2 sm:col-span-1 md:col-span-1 lg:col-span-1 titre"
                  >Contrat juridique </span
                ><span class="description">{{ intervention.LibelleTraiteJuridique }}</span>
              </div>
            </div>
            <div class="infos">
              <div class="grid sm:grid-col-2 md:grid-cols-2">
                <span class="pl-2 sm:col-span-1 md:col-span-1 lg:col-span-1 titre">Chantier</span>
                <span class="description">{{ intervention.CodeChantier }}</span>
              </div>
            </div>
          </div>
          <div class="mt-4">
            <div class="pl-2 sm:col-span-1 md:col-span-1 lg:col-span-1 titre">Commentaire</div>
            <div class="pl-2 description">{{ intervention.CommentaireLancement }}</div>
          </div>
        </div>
        <!-- #region Icon transition intervention -->
        <div class="flex justify-center icon-container md:col-span-1 lg:col-span-1 sm:col-span-0">
          <div class="icon-intervention"></div>
        </div>
        <!-- #endregion -->
        <div class="infos-execution sm:col-span-1 md:col-span-5 lg:col-span-5 space-x-4">
          <div class="header">Exécution</div>
          <div class="grid sm:grid-col-1 md:grid-cols-2">
            <div class="infos">
              <H3>QUOI ?</H3>
              <div class="grid sm:grid-col-2 md:grid-cols-3">
                <span class="pl-2 sm:col-span-1 md:col-span-1 lg:col-span-1 titre">Motif</span
                ><span class="sm:col-span-2 md:col-span-2 lg:col-span-2 description">
                  {{ intervention.LibelleMotifExecution }}
                </span>
              </div>
              <div class="grid sm:grid-col-2 md:grid-cols-3">
                <span class="pl-2 sm:col-span-1 md:col-span-1 lg:col-span-1 titre">Action</span
                ><span class="sm:col-span-2 md:col-span-2 lg:col-span-2 description">
                  {{ intervention.LibelleActionExecution }}
                </span>
              </div>
              <H3>QUI ?</H3>
              <div class="grid sm:grid-col-2 md:grid-cols-3">
                <span class="pl-2 sm:col-span-1 md:col-span-1 lg:col-span-1 titre">Unité</span
                ><span class="sm:col-span-2 md:col-span-2 lg:col-span-2 description">
                  {{ intervention.NumeroUniteExecution }} <br />{{ intervention.NomUniteExecution }}
                </span>
              </div>

              <div class="grid sm:grid-col-2 md:grid-cols-3">
                <span class="pl-2 sm:col-span-1 md:col-span-1 lg:col-span-1 titre">Ressource</span
                ><span class="sm:col-span-2 md:col-span-2 lg:col-span-2 description">
                  {{ intervention.MatriculeRessourceExecution }} <br />{{
                    intervention.NomRessourceExecution
                  }}
                </span>
              </div>
            </div>
            <div class="infos">
              <H3>QUAND ?</H3>
              <div class="grid sm:grid-col-2 md:grid-cols-2">
                <span class="pl-2 sm:col-span-1 md:col-span-1 lg:col-span-1 titre"
                  >Date Exécution</span
                ><span class="sm:col-span-2 md:col-span-2 lg:col-span-1 description">
                  {{ intervention.DateExecution }}
                  <fa-icon
                    (click)="detailsVisible = !detailsVisible"
                    class="info info-execution"
                    [icon]="faInfoCircle"
                    *ngIf="
                      intervention.CreneauxIntervention &&
                      intervention.CreneauxIntervention.length > 0
                    ">
                  </fa-icon>
                </span>
                <app-frame [title]="'Détails'" *ngIf="detailsVisible" class="w-52 details">
                  <i class="pi pi-times content-header" (click)="detailsVisible = false"></i>
                  <div class="content-frame p-2">
                    <div class="titre">
                      <span *ngIf="!(intervention.IndicateurHeureSup === '0')">Heures Sup</span>
                      <span *ngIf="!(intervention.IndicateurAstreinte === '0')">Astreinte</span>
                    </div>
                    <div *ngFor="let crenaux of intervention.CreneauxIntervention">
                      <div>
                        <p>
                          <span class="text-grey">Le </span>
                          <span class="titre">
                            {{ crenaux.DateDebutCreneau | dateUnixToDate }}
                          </span>
                          <span class="text-grey">de </span>
                          <span class="titre">
                            {{ crenaux.DateDebutCreneau | dateUnixToHours }}
                          </span>
                          <span class="text-grey">à </span>
                          <span class="titre">{{ crenaux.DateFinCreneau | dateUnixToHours }} </span>
                          <span class="text-grey">Durée Trajet </span>
                          <span class="titre">{{ crenaux.DureeTrajet }}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </app-frame>
                <ng-template [ngIf]="intervention.LibelleMotifInfructueux">
                  <span class="pl-2 sm:col-span-1 md:col-span-1 lg:col-span-1 titre">Infructueuse</span>
                  <span class="sm:col-span-2 md:col-span-2 lg:col-span-1 description">
                    {{intervention.LibelleMotifInfructueux}}
                  </span>
                </ng-template>
                
                <span class="pl-2 sm:col-span-1 md:col-span-1 lg:col-span-1 titre" 
                  *ngIf="!intervention.LibelleMotifInfructueux && intervention.IndicateurClotureeSansObjet">
                  Clôturée sans objet
                </span>
                
              </div>
            </div>
          </div>
          <div class="grid sm:grid-col-2 md:grid-cols-2 infos-commentaire">
            <div class="infos">
              <div
                class="grid sm:grid-col-2 md:grid-cols-2"
                *ngIf="intervention.ValeurIndexConsommation">
                <span class="pl-2 sm:col-span-1 md:col-span-1 lg:col-span-1 titre"
                  >Index mécanique </span
                ><span class="description">{{ intervention.ValeurIndexConsommation }}</span>
              </div>
              <div
                class="grid sm:grid-col-2 md:grid-cols-2"
                *ngIf="intervention.ValeurIndexTelereleve">
                <span class="pl-2 sm:col-span-1 md:col-span-1 lg:col-span-1 titre"
                  >Index relevé radio </span
                ><span class="description">{{ intervention.ValeurIndexTelereleve }}</span>
              </div>
              <div class="grid sm:grid-col-2 md:grid-cols-2" *ngIf="intervention.MotifDepose">
                <span class="pl-2 sm:col-span-1 md:col-span-1 lg:col-span-1 titre"
                  >Motif dépose</span
                >
                <span class="description">{{ intervention.MotifDepose }}</span>
              </div>
            </div>
            <div class="infos" *ngIf="intervention.NumeroCompteurPose">
              <div class="grid sm:grid-col-2 md:grid-cols-2">
                <span class="pl-2 sm:col-span-1 md:col-span-1 lg:col-span-1 titre"
                  >N° Compteur</span
                >
                <span class="description">{{ intervention.NumeroCompteurPose }}</span>
              </div>
            </div>
          </div>
          <div class="mt-4 infos-commentaire">
            <div class="mt-4 pl-2 sm:col-span-1 md:col-span-1 lg:col-span-1 titre">Commentaire</div>
            <div class="pl-2 description">
              {{ intervention.CommentaireExecution }}
            </div>
          </div>
          <div class="mt-4 flex flex-row-reverse">
            <button
              id="afficherPhotos"
              (click)="afficherPhotos(intervention.PhotoIds)"
              class="photoButton"
              *ngIf="intervention.PhotoIds.length > 0">
              <i class="pi pi-image"></i> Photos
            </button>
          </div>
        </div>
      </div>
    </div>
  </app-frame>
</div>
<app-photos-viewer
  [photosId]="photosId"
  *ngIf="galeriePhotosIsVisible"
  [isVisible]="galeriePhotosIsVisible"
  (modifyVisiblity)="galeriePhotosIsVisible = $event"></app-photos-viewer>
