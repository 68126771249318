import { ICentre } from '../../interface/autorisations/centre.interface';
import { IToTreeNode } from '../../interface/to-tree-node.interface';
import { WaterpTreeNode } from '../../interface/waterp-tree-node.interface';
import { ZoneAvecCentres } from './ZoneAvecCentre';

export class Centre implements IToTreeNode {
  private Numero: string;
  private Nom: string;

  constructor(data: ICentre) {
    this.Numero = data.Numero;
    this.Nom = data.Nom;
  }

  public getNumero(): string {
    return this.Numero;
  }

  public getNom(): string {
    return this.Nom;
  }

  public getType(): string {
    return 'Centre';
  }

  public copieCentre(): Centre {
    return new Centre({ Numero: this.Numero, Nom: this.Nom });
  }

  public toTreeNode(parent?: WaterpTreeNode): WaterpTreeNode {
    const centreNode: WaterpTreeNode = {
      key: `${this.Numero}`,
      label: `${this.Numero}~${this.Nom}`,
      type: 'N-2',
      expanded: false,
      leaf: true,
      children: [],
      data: this,
      parent: parent,
      selectable: true
    };
    centreNode.tooltip = {
      text: 'Sélectionner le centre',
      delay: 500,
      disabled: true,
      appendTo: 'body'
    };
    return centreNode;
  }

  public set(params: { this: Centre; in: ZoneAvecCentres; from?: any }): void {}

  public remove(params: { this: Centre; in?: ZoneAvecCentres; from?: any }): void {}

  public has(centre: Centre): boolean {
    return centre.Numero === this.Numero;
  }

  public sort(ascend: boolean = true): void {}

  public doWhenSelect(...args: any) {}
  public doWhenUnselect(...args: any) {}
}
