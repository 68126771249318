import { KeyValuePair } from 'src/app/shared/models/reference-data.model';

export class Option<T1, T2> implements KeyValuePair<T1, T2> {
  public key: T1;
  public value: T2;
  private disabled: boolean;

  constructor(key: T1, value: T2, disabled: boolean = false) {
    this.key = key;
    this.value = value;
    this.disabled = disabled;
  }

  public getKey(): T1 {
    return this.key;
  }

  public setKey(key: T1): void {
    this.key = key;
  }

  public getValue(): T2 {
    return this.value;
  }

  public setValue(value: T2): void {
    this.value = value;
  }

  public isDisabled(): boolean {
    return this.disabled;
  }

  public setDisabled(disabled: boolean): void {
    this.disabled = disabled;
  }

  public toKeyValuePair(): KeyValuePair<T1, T2> {
    return { key: this.key, value: this.value };
  }
}
