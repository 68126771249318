import { isUndefined } from 'is-what';
import { Operator } from 'src/app/api/models/enum/Operator.enum';
import { ColType } from '../../types/column/column-type.type';
import { Param } from '../../types/column/column-parameter.type';
import { PropertyCompared } from '../../types/property-compared.type';
import { Comparator } from '../../types/comparator.type';
import { IconsFormat } from '../../types/icons-format.type';
import { ColSuffix } from '../../types/column/column-suffix.type';

/**
 * Modèle interne de gestion des colonnes des tableaux 'Kendo grid'
 * @param T Représente le type de la colonne suivant les possibilités décrites dans le type ColType
 * @param C (Facultatif) Représente le type de la valeur à comparer
 */
export class Column<T extends ColType, C = any> {
  /** @var name Nom d'affichage de la colonne */
  private name: string;
  /** @var type Type d'affichage de la colonne */
  private type: string;
  /** @var parameters Définition du contenue de la colonne */
  private parameters: Param<T>;
  /** @var properties Référence des propriétés pour le contenu de la colonne */
  private properties: string[];
  /** @var propertyToCompare Référence de la propriété à comparer pour faire varier le contenu de la colonne */
  private propertyToCompare: PropertyCompared<C>;
  /** @var classStyle Class SCSS à utiliser pour le contenue de la colonne */
  private classStyle: string;
  /** @var filterable Indique si la colonne doit être filtrée */
  private filterable: boolean;
  /** @var width Largeur de la colonne */
  private width: number;
  /** @var separator Permet de spécifier un séparateur entre les propriétés */
  private separator: string;
  /** @var defaultValue Permet de spécifier une valeur par défaut lorsque la donnée n'existe pas */
  private defaultValue: string;

  /**
   * Constructeur de la colonne
   * @param name Nom d'affichage de la colonne
   * @param properties Propriétés de l'objet pour le contenu de la colonne
   * @param parameters Paramètres d'affichage de la colonne dépendant du type donné à la colonne
   * @param optionnals Paramètres optionnels de la colonne
   * @param optionnals.propertyToCompare Permet de définir le champ à comparer avec ses conditions pour adapter le contenue de la colonne
   * @param optionnals.classStyle Permet d'initialiser du style pour la colonne et/ou son contenue
   * @param optionnals.separator Permet d'initialiser un séparateur dans le cas où plusieurs propriétés serait à afficher
   * @param optionnals.filterable Permet de définir la colonne en filtrable
   * @param optionnals.width Permet de définir la largeur de la colonne
   * @param optionnals.defaultValue Permet de modifier la valeur par défaut qui sera affichée dans le cas où la propriété n'existe pas
   */
  constructor(
    name: string,
    type: string,
    parameters: Param<T>,
    optionnals?: {
      properties?: string[];
      propertyToCompare?: PropertyCompared<C>;
      classStyle?: string;
      separator?: string;
      width?: number;
      defaultValue?: string;
    }
  ) {
    this.name = name;
    //! NameOf Type https://github.com/dsherret/ts-nameof?tab=readme-ov-file#readme
    this.type = type;
    //!
    this.parameters = parameters;
    this.properties = optionnals?.properties ?? new Array<string>();
    this.propertyToCompare = optionnals?.propertyToCompare;
    this.classStyle = optionnals?.classStyle ?? '';
    this.width = optionnals?.width ?? 100;
    this.filterable = false;
    this.separator = optionnals?.separator ?? ' ';
    this.defaultValue = optionnals?.defaultValue ?? '';
  }

  //#region Accesseurs
  public getType(): string {
    return this.type;
  }

  public getName(): string {
    return this.name;
  }

  public setName(name: string): void {
    this.name = name;
  }

  public getParameters(): Param<T> {
    return this.parameters;
  }

  public getProperties(): string[] {
    return this.properties;
  }

  public setProperties(properties: string[]): void {
    this.properties = properties;
  }

  public getPropertyToCompare(): PropertyCompared<C> {
    return this.propertyToCompare;
  }

  public setPropertyToCompare(propertyToCompare: PropertyCompared<C>): void {
    this.propertyToCompare = propertyToCompare;
  }

  public getClassStyle(): string {
    return this.classStyle;
  }

  public setClassStyle(classStyle: string): void {
    this.classStyle = classStyle;
  }

  public getWidth(): number {
    return this.width;
  }

  public setWidth(width: number): void {
    this.width = width;
  }

  public isFilterable(): boolean {
    return this.filterable;
  }

  public setFilterable(filterable: boolean): void {
    this.filterable = filterable;
  }

  public getSeparator(): string {
    return this.separator;
  }

  public setSeparator(separator: string): void {
    this.separator = separator;
  }

  public getDefaultValue(): string {
    return this.defaultValue;
  }

  public setDefaultValue(defaultValue: string): void {
    this.defaultValue = defaultValue;
  }
  //#endregion

  /**
   * Permet de récupérer de manière chaîné la valeur indiqué par la propriété donnée en paramètre
   * @param item Au départ il correspond à l'objet à inspecter, puis ressort en tant que valeur à afficher
   * @param property Propriété à récupérer de l'objet (format attendu : 'propriété1.propriété2.propriété3' pour récupérer la propriété3 de notre objet)
   * @returns La valeur de la propriété qui a été retrouvée, sinon une chaîne de caractère vide
   */
  private getDeepValue(item: any, property: string): any {
    property.split('.').forEach((deepProperty: string, index: number) => {
      if (Object.keys(item).includes(deepProperty)) item = item[deepProperty];
      else if (index === property.split('.').length - 1) {
        item = undefined;
      }
    });
    return item;
  }

  /**
   * Permet d'adapter l'affichage du texte dans une colonne en fonction de la propriété à comparer donné en paramètre
   * @param item Objet à inspecter afin de récupérer la ou les valeurs des propriétés indiqué pour la colonne traitée
   * @param cellValue Valeur de base à afficher
   * @returns Une valeur à afficher
   */
  private getComparisonResult(item: any, cellValue: string): string {
    return this.propertyToCompare
      ? this.propertyToCompare.conditions
          .find((condition: Comparator<C>) => {
            let fieldValue: string = this.getDeepValue(item, this.propertyToCompare?.field ?? '');
            switch (condition.operator) {
              case Operator.CONTAIN:
                return fieldValue.includes(condition.value as string);
              case Operator.IS_EQUAL:
                return fieldValue == condition.value;
              case Operator.IS_STRICTLY_EQUAL:
                return fieldValue === condition.value;
              case Operator.IS_DIFF:
                return fieldValue != condition.value;
              case Operator.IS_STRICTLY_DIFF:
                return fieldValue !== condition.value;
              case Operator.PATTERN:
                return condition.value instanceof RegExp ? condition.value.test(fieldValue) : false;
            }
          })
          ?.result.replaceAll('%value%', cellValue) ?? ''
      : cellValue;
  }

  /**
   * Permet de mettre en forme la valeur à afficher dans la cellule de la colonne
   * @param item Objet à inspecter afin de récupérer la ou les valeurs des propriétés indiqué pour la colonne traitée
   * @returns La valeur à afficher dans la cellule de la colonne
   */
  public getCellValue(item: any): string {
    return this.getProperties()
      .map((property: string) =>
        this.getComparisonResult(item, this.getDeepValue(item, property) ?? this.defaultValue)
      )
      .join(this.getSeparator());
  }

  /**
   * Permet d'extraire un tableau dans la cellule de la colonne
   * @param item Objet à inspecter afin de récupérer la ou les valeurs des propriétés indiqué pour la colonne traitée
   * @returns Le tableau de données à afficher dans la cellule de la colonne
   */
  public getCellValues(item: any): any[] {
    return this.getProperties().length > 0
      ? this.getDeepValue(item, this.getProperties()[0] ?? '')
      : [];
  }

  /**
   * ? POUR LES PARAMÈTRES CONTENANT DES "ColSuffix" UNIQUEMENT
   * Permet d'obtenir le rendu propre pour les informations à afficher en suffixe du composant chip
   * @param item Objet à inspecter afin de récupérer la ou les valeurs des propriétés indiqué pour la colonne traitée
   * @param suffixes Tableau des éléments à afficher en suffixe, ceci peuvent être soit des valeurs de champ à ajouter soit du texte
   * @returns Les éléments à afficher en suffixe affichés proprement
   */
  public getContentFromSuffixes(item: any, suffixes: ColSuffix[]): string {
    return suffixes
      .map((suffix: ColSuffix) =>
        suffix.isField ? this.getDeepValue(item, suffix.text ?? '') : suffix.text
      )
      .join(this.getSeparator());
  }

  /**
   * Permet au tableau de se servir de la valeur de l'item pour afficher ou non l'icône dans la colonne
   * @param item Objet à inspecter afin de récupérer la ou les valeurs des propriétés indiqué pour la colonne traitée
   * @param icon Données de l'icône
   * @returns Vrai si l'icône doit s'afficher, Faux sinon
   */
  public showIcon(item: any, icon: IconsFormat): boolean {
    // On récupère la valeur de la ligne à afficher pour notre colonne
    let itemValue: any = this.getDeepValue(item, this.getProperties()[0] ?? '');

    // Cette valeur est-elle un booléen et définie ?
    let validValue: boolean = !isUndefined(itemValue) && typeof itemValue === 'boolean';

    // On établie le choix par défaut, si une condition est fournie alors on donne la valeur récupérée dans cette condition, s'il n'y a
    //pas de condition on donne Vrai pour permettre de laisser la valeur booléenne récupérée décider.
    let defaultChoice: boolean = !isUndefined(icon.condition)
      ? icon.condition(itemValue)
      : itemValue;

    // On établie le choix final, si la valeur est indéfinie et n'est pas une valeur booléenne alors on vérifie s'il y a une condition
    //cela permettra de se servir de cette valeur pour déterminer l'affichage ou non, sinon dans le cas où la valeur n'est pas validée
    //et qu'il n'y a pas de condition alors on retourne false.
    let finalChoice: boolean = !isUndefined(icon.condition) ? icon.condition(itemValue) : false;

    // Calcul final, si "validValue" est Vrai alors on se fie au choix par défaut, sinon on se fie au choix final
    return validValue ? defaultChoice : finalChoice;
  }
}
