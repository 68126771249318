import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DossierCompteState } from './dossier-compte.state';

export const selectDossierCompte = createFeatureSelector<DossierCompteState>('dossierCompte');

export const selectIsMensualisable = createSelector(
  selectDossierCompte,
  (state: DossierCompteState) => state.isMensualisable
);

export const selectIsFPA = createSelector(
  selectDossierCompte,
  (state: DossierCompteState) => state.isFPA
);

export const selectIsRemboursable = createSelector(
  selectDossierCompte,
  (state: DossierCompteState) => state.isRemboursable
);

export const selectIsFactureManuelle = createSelector(
  selectDossierCompte,
  (state: DossierCompteState) => state.isFactureManuelle
);

export const selectIsAutreFactureManuelle = createSelector(
  selectDossierCompte,
  (state: DossierCompteState) => state.isAutreFactureManuelle
);

export const selectHasEcheancierFPA = createSelector(
  selectDossierCompte,
  (state: DossierCompteState) => state.hasEcheancierFPA
);

export const selectHasEcheancierFPACB = createSelector(
  selectDossierCompte,
  (state: DossierCompteState) => state.hasEcheancierFPACB
);
