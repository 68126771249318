import { createFeatureSelector, createSelector } from '@ngrx/store';
import { HeaderState } from './header.state';

export const selectHeader = createFeatureSelector<HeaderState>('header');

export const selectTitle = createSelector(selectHeader, (state: HeaderState) => state.title);

export const selectShortcut = createSelector(selectHeader, (state: HeaderState) => state.shortcut);

export const selectIndicateurPaiementSur = createSelector(selectShortcut, shortcut =>
  shortcut ? shortcut.IndicateurPaiementSur : null
);

export const selectWebFunctions = createSelector(
  selectHeader,
  (state: HeaderState) => state.webFunctions
);

export const selectError = createSelector(selectHeader, (state: HeaderState) => state.errorSource);
export const selectErrorMessage = createSelector(
  selectHeader,
  (state: HeaderState) => state.message
);

export const selectPaiement = createSelector(
  selectHeader,
  (state: HeaderState) => state.activePaiement
);

export const selectIsExpandedMenu = createSelector(
  selectHeader,
  (state: HeaderState) => state.isExpandedMenu
);
