import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private isFirstNavigation: boolean = true;

  constructor(private router: Router) {
    // Écouter le premier événement de navigation réussi
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        take(1)
      )
      .subscribe(() => {
        this.isFirstNavigation = false;
      });
  }

  isDirectAccess(): boolean {
    return this.isFirstNavigation;
  }
}
