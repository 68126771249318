import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, map, tap } from 'rxjs';
import { IAutorisations } from 'src/app/api/models/interface/autorisations/autorisation-profil.interface';
import { GeneriqueResponse } from 'src/app/api/models/shared/generiqueResponse';
import { ProfilService } from 'src/app/api/services/profil.service';
import { ProfilManagementService } from 'src/app/views/organisation/profil-management/service/profil-management.service';

@Injectable({
  providedIn: 'root'
})
export class GetAutorisationsResolver implements Resolve<IAutorisations> {
  constructor(
    private profilService: ProfilService,
    private profilManagementService: ProfilManagementService,
    private router: Router
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<IAutorisations> {
    return this.profilService.getAutorisations(route.params['codeProfil']).pipe(
      tap((response: GeneriqueResponse) =>
        [112, 121].includes(response.Code) ? this.router.navigateByUrl('authentification') : null
      ),
      map((response: GeneriqueResponse) => {
        if (!route.params['codeProfil']) {
          this.profilManagementService.habilitations = response.Result;
        }
        return response.Result;
      })
    );
  }
}
