import { Operation } from 'src/app/core/models/operation.model';

export type OperationState = Operation;

export const initialState: OperationState = {
  showAvoirPartiel: false,
  showAvoirTotal: false,
  numeroFacture: -1,
  infosAvoir: {
    MontantTTC: -1,
    NumeroEdition: '',
    VolumeConsomme: -1,
    VolumeEau: -1,
    VolumeAssainissement: -1
  },
  paragraphes: {
    Message1: '',
    Message2: '',
    Message3: '',
    NumeroFacture: -1,
    VolumeEau: 1,
    VolumeAssainissement: 1,
    MontantTTC: 1
  }
};
