import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
  HttpUrlEncodingCodec
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiError } from 'src/app/api/error';
import { ParametersRequest, Parameter } from 'src/app/api/models/shared/parametersRequest';
import { AppInsightService } from './app-insight.service';
import { TokenService } from './token.service';
import { EMPTY, Observable, catchError, of, tap } from 'rxjs';
import { GeneriqueResponse } from 'src/app/api/models/shared/generiqueResponse';
import { Configuration } from 'src/app/api/configuration';
import { String } from 'typescript-string-operations';
import { Resource } from 'src/app/resources/resource';
import { AppResource } from 'src/app/app.resource';
import { Router } from '@angular/router';
import { removeItemLocalStorage } from '../utils/localStorageUtils';

@Injectable({
  providedIn: 'root'
})
export class WaterpHttpService {
  public token: string = '';
  public resource: Resource;
  public headers = new HttpHeaders();
  public configuration = new Configuration();

  path: string = '';
  properties: Map<string, any> = new Map();
  typeRequest: string = '';

  tapFunction: (response: GeneriqueResponse) => void = () => {};
  errorFunction: (error: HttpErrorResponse) => Observable<GeneriqueResponse> = () =>
    new Observable<GeneriqueResponse>();

  constructor(
    private httpClient: HttpClient,
    private resources: AppResource,
    private appInsightService: AppInsightService,
    private router: Router,
    private tokenService: TokenService
  ) {
    this.resource = this.resources['resource'];
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      this.resetHeaders();
    }

    this.tokenService.token$.subscribe(token => {
      this.token = token;
      this.headers = this.headers.set('Token', token);
    });

    this.tapFunction = (response: GeneriqueResponse) => {
      this.appInsightService.logTrace(
        String.format(this.resource.appInsight.Trace, this.typeRequest, this.path),
        this.properties
      );

      if ([112].includes(response.Code)) {
        removeItemLocalStorage('token');
        this.router.navigateByUrl('authentification');
      }
    };

    this.errorFunction = (error: HttpErrorResponse) => {
      this.appInsightService.logException(error, 1, this.properties);

      return ApiError.handleApiError(this.path, error);
    };
  }

  private prepareRequest(parametersRequest: ParametersRequest): HttpParams {
    let queryParameters = new HttpParams({ encoder: new HttpUrlEncodingCodec() });
    parametersRequest.parameters?.forEach((parameter: Parameter) => {
      if (parameter['value'] === null || parameter['value'] === undefined) {
        throw new Error(
          String.format(
            this.resource.httpService.RequiredParameter,
            parameter['name'],
            parametersRequest.url
          )
        );
      }

      if (parameter['value'] !== undefined && parameter['value'] !== null) {
        queryParameters = queryParameters.set(parameter['name'], <any>parameter['value']);
      }
    });
    return queryParameters;
  }

  get(path: string, parametersRequest?: ParametersRequest): Observable<GeneriqueResponse> {
    if (!parametersRequest) return EMPTY;

    let queryParameters = this.prepareRequest(parametersRequest);
    this.setProperties(path, 'Get', undefined, parametersRequest);

    return this.httpClient
      .get<GeneriqueResponse>(path, {
        params: queryParameters,
        headers: this.headers
      })
      .pipe(tap(this.tapFunction), catchError(this.errorFunction));
  }

  post(path: string, body?: any): Observable<GeneriqueResponse> {
    return this.makeRequest('post', path, body);
  }

  put(path: string, body?: any): Observable<GeneriqueResponse> {
    return this.makeRequest('put', path, body);
  }

  delete(parametersRequest?: ParametersRequest): Observable<GeneriqueResponse> {
    if (!parametersRequest) return EMPTY;

    let path: string = parametersRequest.url;
    let queryParameters = this.prepareRequest(parametersRequest);
    this.setProperties(path, 'Get', undefined, parametersRequest);

    return this.httpClient
      .get<GeneriqueResponse>(path, {
        params: queryParameters,
        headers: this.headers
      })
      .pipe(tap(this.tapFunction), catchError(this.errorFunction));
  }

  //#region Private
  private makeRequest(
    method: 'post' | 'put',
    path: string,
    body?: any
  ): Observable<GeneriqueResponse> {
    this.setProperties(path, method, body);

    return this.httpClient[method]<GeneriqueResponse>(path, body, {
      headers: this.headers
    }).pipe(tap(this.tapFunction), catchError(this.errorFunction));
  }

  private setProperties(
    path: string,
    method: string,
    body?: any,
    parametersRequest?: ParametersRequest
  ) {
    this.typeRequest = method;
    this.path = path;
    this.properties = new Map();
    this.properties.set('path', path);

    if (parametersRequest) this.properties.set('parametersRequest', parametersRequest);

    if (body)
      this.properties.set(
        'body',
        !path.includes('Authentifier') && !path.includes('MotDePasse') ? body : null
      );

    this.properties.set('headers', this.headers);
  }

  private resetHeaders() {
    this.headers = new HttpHeaders().set('Content-Type', 'application/json').set('Accept', '*/*');
  }
  //#endregion
}
