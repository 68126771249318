import { CanalAcquisitionSepa } from './../interface/CanalAcquisitionSepa';
import { formatDateUnixToNumber } from 'src/app/shared/utils/dateUnixUtils';

export class InformationsPaiementResponse {
  CodeIcsSociete: string;
  CodeModePaiement: string;
  HasHistoriquePaiement: boolean;
  IndicateurPaiementSur: boolean;
  JourDePrelevement: number;
  NomSociete: string;
  NumeroAbonnement: string;
  DateValiditeCB?: Date;
  ReferenceBancaire: {
    NumeroAbonnement: string;
    Titulaire: string;
    Domiciliation: string;
    Etablissement: string;
    Guichet: string;
    NumeroCompte: string;
    CleRib: string;
    CodeBic: string;
    CodeIban: string;
    NumeroSequenceRum: string;
    DateRum?: Date;
    CanalAcquisitionSepa?: {
      Code: string;
    };
  };

  constructor(numeroAbonnement: string, data?: Partial<InformationsPaiementResponse>) {
    this.CodeIcsSociete = data?.CodeIcsSociete ?? '';
    this.CodeModePaiement = data?.CodeModePaiement ?? '';
    this.HasHistoriquePaiement = data?.HasHistoriquePaiement ?? false;
    this.IndicateurPaiementSur = data?.IndicateurPaiementSur ?? false;
    this.JourDePrelevement = data?.JourDePrelevement ?? 0;
    this.NomSociete = data?.NomSociete ?? '';
    this.NumeroAbonnement = data?.NumeroAbonnement ?? numeroAbonnement;
    this.DateValiditeCB = this.formatDate(data?.DateValiditeCB);
    this.ReferenceBancaire = {
      NumeroAbonnement: numeroAbonnement,
      Titulaire: data?.ReferenceBancaire?.Titulaire ?? '',
      Domiciliation: data?.ReferenceBancaire?.Domiciliation ?? '',
      Etablissement: data?.ReferenceBancaire?.Etablissement ?? '',
      Guichet: data?.ReferenceBancaire?.Guichet ?? '',
      NumeroCompte: data?.ReferenceBancaire?.NumeroCompte ?? '',
      CleRib: data?.ReferenceBancaire?.CleRib ?? '',
      CodeBic: data?.ReferenceBancaire?.CodeBic ?? '',
      CodeIban: data?.ReferenceBancaire?.CodeIban ?? '',
      NumeroSequenceRum: data?.ReferenceBancaire?.NumeroSequenceRum ?? '',
      DateRum: this.formatDate(data?.ReferenceBancaire?.DateRum),
      CanalAcquisitionSepa: data?.ReferenceBancaire?.CanalAcquisitionSepa
    };
  }

  private formatDate(date: string | Date | undefined): Date | undefined {
    if (typeof date === 'string') {
      const timestamp = formatDateUnixToNumber(date);
      return isNaN(timestamp) ? undefined : new Date(timestamp);
    }
    return date instanceof Date ? date : undefined;
  }
}
