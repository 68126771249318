<kendo-grid
  class="waterp-grid"
  [kendoGridBinding]="table.getDatas()"
  [sortable]="table.isSortable()"
  [reorderable]="table.isReorderable()"
  [groupable]="table.isGroupable()"
  [resizable]="table.isResizable()"
  [filterable]="table.isFilterable()"
  [scrollable]="table.isScrollable()"
  [loading]="table.isLoading()"
  [pageable]="table.isPageable() ? table.getPagination() : false"
  [pageSize]="table.getPagination().getPageSize()"
  [selectable]="table.getSelectableConfig()"
  [kendoGridSelectBy]="table.getLinesSelectable()?.field ?? ''"
  (selectedKeysChange)="getDatasToEmit($event)"
  (excelExport)="table.getExportable() ? setExportTemplate($event) : null">
  <!-- #region Boutons additionnels -->
  <ng-template
    kendoGridToolbarTemplate
    *ngIf="table.getAdditionalButtons() && table.getExportable()">
    <div
      [class]="
        'w-full flex items-center justify-' +
        (table.getAdditionalButtons() && table.getExportable()
          ? 'between'
          : table.getExportable()?.position ?? 'start')
      ">
      <div *ngIf="table.getAdditionalButtons()" class="flex gap-2">
        <ng-container *ngFor="let button of table.getAdditionalButtons()">
          <button
            [class]="button.class"
            [disabled]="button.disabled()"
            [pTooltip]="button.tooltip?.text ?? ''"
            [tooltipPosition]="button.tooltip?.position ?? 'top'"
            [tooltipDisabled]="!button.tooltip"
            (click)="button.action()">
            <ng-container *ngIf="button.prefix">
              <fa-icon
                *ngIf="button.prefix.icon"
                [icon]="button.prefix.icon"
                [class]="button.prefix.class"></fa-icon>

              <span *ngIf="!button.prefix.icon" [class]="button.prefix.class"> </span>
            </ng-container>

            {{ button.text }}

            <ng-container *ngIf="button.suffix">
              <fa-icon
                *ngIf="button.suffix.icon"
                [icon]="button.suffix.icon"
                [class]="button.suffix.class"></fa-icon>

              <span *ngIf="!button.suffix.icon" [class]="button.suffix.class"> </span>
            </ng-container>
          </button>
        </ng-container>
      </div>
      <div *ngIf="table.isShowLineCount() || table.getExportable()" class="flex gap-2">
        <p-chip
          *ngIf="table.isShowLineCount()"
          [label]="
            table.getDatas().length +
            ' ' +
            table.getMessage(table.getDatas().length > 1 ? 'modelsName' : 'modelName')
          "></p-chip>
        <div *ngIf="table.getExportable()" class="kendo-grid-buttons">
          <button kendoGridExcelCommand class="kendo-toolbar-btn text-primary-600">
            <i class="icon waterpIcons-Export-Excel"></i>
            <span class="text-neutral-900 ml-2 my-auto">Excel</span>
          </button>
        </div>
      </div>
    </div>
  </ng-template>
  <!-- #endregion -->
  <!-- #region Colonne des checkbox -->
  <kendo-grid-checkbox-column
    *ngIf="table.getLinesSelectable()"
    [showSelectAll]="table.getSelectableConfig().mode === 'multiple'"
    [width]="table.getLinesSelectable()?.width ?? 5">
  </kendo-grid-checkbox-column>
  <!-- #endregion -->
  <!-- #region Colonnes et données -->
  <ng-container *ngFor="let column of table.getColumns()">
    <kendo-grid-column
      [field]="column.getProperties()[0]"
      [title]="column.getName()"
      [width]="column.getWidth()"
      [filterable]="column.isFilterable()">
      <!-- #region Entête de la colonne -->
      <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
        <div class="k-column-title" [pTooltip]="column.title" tooltipPosition="top">
          {{ column.title }}
        </div>
      </ng-template>
      <!-- #endregion -->
      <!-- #region Contenue de la colonne -->
      <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container [ngSwitch]="column.getType()">
          <!-- #region TEXT -->
          <ng-container *ngSwitchCase="'text'">
            <div *ngVar="convertService.toTextDecoration(column.getParameters()) as textDecoration">
              {{
                (textDecoration && textDecoration.prefix.endsWith(' ')
                  ? textDecoration.prefix
                  : textDecoration?.prefix + ' ') +
                  column.getCellValue(dataItem) +
                  (textDecoration && textDecoration.suffix.startsWith(' ')
                    ? textDecoration.suffix
                    : ' ' + textDecoration?.suffix)
              }}
            </div>
          </ng-container>
          <!-- #endregion -->
          <!-- #region LIST -->
          <ng-container *ngSwitchCase="'list'">
            <div *ngVar="convertService.toListConverter(column.getParameters()) as listConverter">
              <div *ngVar="listConverter.converter(column.getCellValues(dataItem)) as list">
                <div *ngIf="list.length > 0">
                  {{ list[0].value !== '' ? list[0].value : list[0].key }}
                </div>
                <i
                  *ngIf="list.length > 1"
                  class="pi pi-plus-circle"
                  (hover)="listed.toggle($event)"></i>
                <p-overlayPanel #listed>
                  <ng-container *ngFor="let item of list; let i = index">
                    {{
                      item.value !== '' ? item.value : item.key + (i < list.length - 1 ? ', ' : '')
                    }}
                  </ng-container>
                </p-overlayPanel>
              </div>
            </div>
          </ng-container>
          <!-- #endregion -->
          <!-- #region ROUTERLINK -->
          <ng-container *ngSwitchCase="'routerLink'">
            <ng-container *ngVar="convertService.toColumnLink(column.getParameters()) as link">
              <a
                class="link"
                [routerLink]="link.route + column.getCellValue(dataItem)"
                (click)="link.toTransmit ? link.toTransmit(column.getCellValue(dataItem)) : null">
                {{ column.getCellValue(dataItem) }}
              </a>
            </ng-container>
          </ng-container>
          <!-- #endregion -->
          <!-- #region CURRENCY -->
          <ng-container *ngSwitchCase="'money'">
            {{ column.getCellValue(dataItem) + ' ' + column.getParameters() }}
          </ng-container>
          <!-- #endregion -->
          <!-- #region ICONS -->
          <ng-container *ngSwitchCase="'icons'">
            <div
              *ngVar="convertService.toArray(column.getParameters()) as icons"
              [class]="'flex justify-center items-center gap-4'">
              <ng-container *ngFor="let iconObj of icons">
                <ng-container *ngVar="convertService.toIconsFormat(iconObj) as icon">
                  <div
                    *ngIf="column.showIcon(dataItem, icon)"
                    [pTooltip]="icon.tooltip?.text ?? ''"
                    [tooltipPosition]="icon.tooltip?.position ?? 'top'"
                    [tooltipDisabled]="!icon.tooltip"
                    (click)="
                      icon.actionTrigger === 'click' && icon.action ? icon.action(dataItem) : null
                    "
                    (focus)="
                      icon.actionTrigger === 'focus' && icon.action ? icon.action(dataItem) : null
                    "
                    (hover)="
                      icon.actionTrigger === 'hover' && icon.action ? icon.action(dataItem) : null
                    "
                    (active)="
                      icon.actionTrigger === 'active' && icon.action ? icon.action(dataItem) : null
                    ">
                    <ng-container *ngIf="icon.icon; else elseIconTemplate">
                      <fa-icon
                        [icon]="icon.icon"
                        [class]="
                          (icon.actionTrigger ? 'cursor-pointer' : '') +
                          (icon.class ? ' ' + icon.class : '')
                        ">
                        {{ column.getCellValue(dataItem) }}
                      </fa-icon>
                    </ng-container>
                    <ng-template #elseIconTemplate>
                      <i
                        *ngIf="icon.class"
                        [class]="icon.class + (icon.actionTrigger ? ' cursor-pointer' : '')">
                      </i>
                    </ng-template>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </ng-container>
          <!-- #endregion -->
          <!-- #region CHIP -->
          <ng-container *ngSwitchCase="'chip'">
            <div
              *ngVar="convertService.toColumnChip(column.getParameters()) as chip"
              class="flex justify-center items-center gap-2">
              <p-chip
                [styleClass]="chip.style(column.getCellValue(dataItem))"
                [icon]="chip.icon ? chip.icon(column.getCellValue(dataItem)) : ''"
                [label]="
                  chip.text
                    ? chip.text(column.getCellValue(dataItem))
                    : column.getCellValue(dataItem)
                "></p-chip>
              <ng-container *ngIf="chip.suffixes">
                {{ column.getContentFromSuffixes(dataItem, chip.suffixes) }}
              </ng-container>
            </div>
          </ng-container>
          <!-- #endregion -->
          <!-- #region CHECKBOX -->
          <ng-container *ngSwitchCase="'checkbox'">
            <div
              *ngVar="convertService.toArray(column.getParameters()) as suffixes"
              class="flex justify-center items-center gap-2">
              <p-checkbox
                *ngVar="convertService.toBool(column.getCellValue(dataItem)) as checked"
                [ngModel]="checked"
                [binary]="true"
                [readonly]="true"
                [styleClass]="'pointer-events-none'"></p-checkbox>
              <ng-container *ngIf="suffixes">
                {{ column.getContentFromSuffixes(dataItem, suffixes) }}
              </ng-container>
            </div>
          </ng-container>
          <!-- #endregion -->
          <!-- #region DATE, TIME OR DATETIME -->
          <ng-container *ngSwitchCase="'datetime'">
            <ng-container
              *ngVar="convertService.toDateTimeFormat(column.getParameters()) as format">
              {{ column.getCellValue(dataItem) | date : format }}
            </ng-container>
          </ng-container>
          <!-- #endregion -->
          <!-- #region UPDATABLE -->
          <ng-container *ngSwitchCase="'updatable'">
            <ng-container *ngVar="convertService.toUpdatable(column.getParameters()) as updatable">
              <ng-container [ngSwitch]="updatable.type">
                <ng-container *ngSwitchCase="'text'">
                  <input
                    type="text"
                    pInputText
                    [class]="updatable.class"
                    [ngModel]="column.getCellValue(dataItem)"
                    (ngModelChange)="updatable.action($event)"
                    [readOnly]="updatable.readonly" />
                </ng-container>
                <ng-container *ngSwitchDefault>
                  {{ column.getCellValue(dataItem) }}
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
          <!-- #endregion -->
          <ng-container *ngSwitchDefault>
            {{ column.getCellValue(dataItem) }}
          </ng-container>
        </ng-container>
      </ng-template>
      <!-- #endregion -->
    </kendo-grid-column>
  </ng-container>
  <!-- #endregion -->
  <!-- #region Messages
    ? https://www.telerik.com/kendo-angular-ui/components/grid/api/CustomMessagesComponent/
    -->
  <kendo-grid-messages
    [groupPanelEmpty]="table.getMessage('groupPanelEmpty')"
    [noRecords]="table.getMessage('noRecords')"
    [pagerLabel]="table.getPagination().getMessage('label')"
    [pagerItems]="table.getPagination().getMessage('items')"
    [pagerItemsPerPage]="table.getPagination().getMessage('itemsPerPage')"
    [pagerFirstPage]="table.getPagination().getMessage('first')"
    [pagerLastPage]="table.getPagination().getMessage('last')"
    [pagerPreviousPage]="table.getPagination().getMessage('previous')"
    [pagerNextPage]="table.getPagination().getMessage('next')"
    [pagerPage]="table.getPagination().getMessage('page')"
    [pagerOf]="table.getPagination().getMessage('of')">
  </kendo-grid-messages>
  <!-- #endregion -->
  <!-- #region Paramètres export Excel -->
  <kendo-grid-excel
    [fileName]="'list_' + table.getPagination().getMessage('items').replace(' ', '_') + '.xlsx'"
    [fetchData]="table.exportAllDatas">
  </kendo-grid-excel>
  <!-- #endregion -->
  <!-- #region Chargement du tableau -->
  <ng-template kendoGridLoadingTemplate>
    <div class="loader-primary">{{ table.getLoadingInfos() }}</div>
    <div class="loading-container">
      <kendo-loader type="converging-spinner" size="large"></kendo-loader>
    </div>
  </ng-template>
  <!-- #endregion -->
</kendo-grid>
