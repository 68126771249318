import { FormGroup } from '@angular/forms';
import { getUnixTime } from 'date-fns';
import { FormBankingReferencesService } from 'src/app/shared/components/financier/banking-references/form-banking-references.service';
import { BankReferencesDto } from '../interface/BankReferencesDto';
import { IbanControl } from 'src/app/shared/models/iban-control';
import { ReferenceBancaire } from 'src/app/core/models/wizard.model';

export class BankReferences implements BankReferencesDto {
  informationsPaiementDto: {
    CodeModePaiement: string;
    DateValiditeCB: string | null;
    JourDePrelevement: number | null;
    NumeroAbonnement: string;
    ReferenceBancaire: {
      CanalAcquisitionSepa: {
        Code: string;
      };
      CodeBic: string;
      CodeIban: string;
      DateRum: string | null;
      Domiciliation: string;
      NumeroRum: string;
      Titulaire: string;
    } | null;
  };
  origine: string;

  constructor(data?: BankReferencesDto) {
    this.informationsPaiementDto = data?.informationsPaiementDto || {
      CodeModePaiement: '',
      DateValiditeCB: null,
      JourDePrelevement: null,
      NumeroAbonnement: '',
      ReferenceBancaire: null
    };
    this.origine = data?.origine || 'AUT';
  }

  static fromFormGroup(
    formGroup: FormGroup,
    service: FormBankingReferencesService,
    numAbonnement: string
  ): BankReferences {
    const mode = formGroup.get('paymentMethod')?.value;
    const rumDateValue = formGroup.get('rumDate')?.value;
    return new BankReferences({
      informationsPaiementDto: {
        CodeModePaiement: mode,
        DateValiditeCB:
          service.isCreditCardModes(mode) && formGroup.get('endValidity')?.value
            ? '/Date(' + getUnixTime(formGroup.get('endValidity')?.value) + '000+0200)/'
            : null,
        JourDePrelevement: service.isMonthlyModes(mode) ? formGroup.get('collectDay')?.value : null,
        NumeroAbonnement: numAbonnement ?? '',
        ReferenceBancaire: service.isBasicModes(mode)
          ? {
              CanalAcquisitionSepa: {
                Code: formGroup.get('consentType')?.value
              },
              CodeBic: formGroup.get('bic')?.value?.toUpperCase(),
              CodeIban: (formGroup.get('iban') as IbanControl)?.getValue(),
              DateRum: rumDateValue ? `/Date(${getUnixTime(rumDateValue)}000+0200)/` : null,
              Domiciliation: formGroup.get('domiciliation')?.value,
              NumeroRum: formGroup.get('rumNumber')?.value,
              Titulaire: formGroup.get('owner')?.value
            }
          : null
      },
      origine: 'AUT'
    });
  }

  public toWizard(): ReferenceBancaire {
    return {
      modePaiement: this.informationsPaiementDto.CodeModePaiement,
      dateValiditeCB: this.informationsPaiementDto.DateValiditeCB,
      jourPrelevement: this.informationsPaiementDto.JourDePrelevement,
      acquisitionConsentement:
        this.informationsPaiementDto.ReferenceBancaire?.CanalAcquisitionSepa?.Code ?? null,
      titulaire: this.informationsPaiementDto.ReferenceBancaire?.Titulaire ?? '',
      iban: this.informationsPaiementDto.ReferenceBancaire?.CodeIban ?? '',
      domiciliation: this.informationsPaiementDto.ReferenceBancaire?.Domiciliation ?? '',
      bic: this.informationsPaiementDto.ReferenceBancaire?.CodeBic ?? '',
      numeroRUM: this.informationsPaiementDto.ReferenceBancaire?.NumeroRum ?? '',
      dateRUM: this.informationsPaiementDto.ReferenceBancaire?.DateRum ?? null
    };
  }
}
