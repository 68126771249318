import { isUndefined } from 'is-what';
import { IToTreeNode } from '../../interface/to-tree-node.interface';
import { WaterpTreeNode } from '../../interface/waterp-tree-node.interface';
import { IZoneAvecCentres } from '../../interface/autorisations/zoneAvecCentres.interface';
import { Centre } from './Centre';
import { ICentre } from '../../interface/autorisations/centre.interface';

export class ZoneAvecCentres implements IToTreeNode {
  private Code: string;
  private Libelle: string;
  private Centres?: Centre[];

  constructor(data?: IZoneAvecCentres, ZoneN2?: boolean) {
    this.Code = data?.Code ?? 'SANS_ZONE';
    this.Libelle = data?.Libelle ?? '';
    this.Centres = data?.Centres?.map((centre: ICentre) => new Centre(centre)) ?? [];
  }

  public getCode(): string {
    return this.Code;
  }

  public getLibelle(): string {
    return this.Libelle;
  }

  public getCentres(): Centre[] | undefined {
    return this.Centres;
  }

  public setCentres(centres: Centre[]): void {
    this.Centres = centres;
  }

  public getType(): string {
    return 'ZoneAvecCentres';
  }

  public copieZoneAvecCentres(): ZoneAvecCentres {
    return new ZoneAvecCentres({ Code: this.Code, Libelle: this.Libelle });
  }

  public toTreeNode(): WaterpTreeNode {
    const zoneNode: WaterpTreeNode = {
      key: `${this.Code}_N-1`,
      label: `${this.Code === 'SANS_ZONE' ? '' : this.Code + ' - '}${this.Libelle}`,
      type: 'N-1',
      expanded: false,
      leaf: false,
      children: [],
      data: this.copieZoneAvecCentres(),
      tooltip: { text: 'Sélectionner la zone' },
      selectable: true
    };
    if (this.Centres && this.Centres.length > 0) {
      this.Centres.forEach(centre => {
        const centreNode: WaterpTreeNode = centre.toTreeNode(zoneNode);
        zoneNode.children?.push(centreNode);
      });
    }
    return zoneNode;
  }

  public addCentre(centre: Centre): void {
    this.Centres?.push(centre!);
  }

  public set(params: { this: Centre; in: IZoneAvecCentres; from?: any }): void {
    this.addCentre(params.this);
  }

  public removeCentre(centreNumero: string): void {
    this.Centres = this.Centres?.filter((centre: Centre) => centre.getNumero() !== centreNumero);
  }

  public remove(params: { this: Centre; in?: ZoneAvecCentres; from?: any }): void {
    this.removeCentre(params.this.getNumero());
  }

  public has(item: ZoneAvecCentres | Centre): boolean {
    if (this.isZoneAvecCentres(item)) {
      if (item.Code !== this.Code) {
        return false;
      }
      if (item.getCentres()?.length !== this.Centres?.length) {
        return false;
      }
      return (
        this.Centres?.every((centre, index) => {
          let centres = item.getCentres();
          if (isUndefined(centres)) {
            return false;
          }
          return !isUndefined(centres) && centres[index] !== undefined;
        }) ?? false
      );
    } else {
      return this.Centres?.some(c => c.has(item)) ?? false;
    }
  }

  private isZoneAvecCentres(item: ZoneAvecCentres | Centre): item is ZoneAvecCentres {
    return item instanceof ZoneAvecCentres;
  }

  public sort(ascend: boolean = true): void {
    this.Centres?.sort((a, b) => {
      return ascend
        ? a.getNumero().localeCompare(b.getNumero())
        : b.getNumero().localeCompare(a.getNumero());
    });
  }

  public doWhenSelect(...args: any) {}
  public doWhenUnselect(...args: any) {}
}
