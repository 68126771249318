import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { ScrollMode, SelectableSettings } from '@progress/kendo-angular-grid';
import { SortDescriptor, process } from '@progress/kendo-data-query';
import { isUndefined } from 'is-what';
import { Button } from '../../types/button.type';
import { ColSelectable } from '../../types/column/column-selectable.type';
import { ColType } from '../../types/column/column-type.type';
import { ExportConfig } from '../../types/export-config.type';
import { pluralize } from '../../utils/string-pluralizer';
import { Column } from './columns';
import { PageSize, PageSizes, Pagination } from './pagination';

/**
 * Modèle interne de gestion des tableaux 'Kendo grid'
 * ? https://www.telerik.com/kendo-angular-ui/components/grid/
 * Pas toutes les propriétés gérées par le composant de Kendo sont présente, nous n'utilisons ici que les propriétés les plus utilisées
 */
export class Table<T> {
  /** @var datas Tableau contenant tous les éléments récupérés du serveur */
  private datas: T[];
  /** @var columns Colonnes du tableau */
  private columns: Column<ColType>[];
  /** @var primaryKey Clé primaire du type d'entité de la table */
  private primaryKey?: string;
  /** @var pageable Indique si la pagination est visible */
  private pageable: boolean;
  /** @var pagination Pagination du tableau */
  private pagination: Pagination;
  /** @var sortable Indique si les éléments sont triable par colonnes */
  private sortable: boolean;
  /** @var reorderable Indique si les colonnes sont réorganisables */
  private reorderable: boolean;
  /** @var groupable Indique si les colonnes sont groupable */
  private groupable: boolean;
  /** @var resizable Indique si la largeur des colonnes peut être changé par l'utilisateur */
  private resizable: boolean;
  /** @var filterable Indique si les éléments sont filtrable par colonnes */
  private filterable: boolean;
  /** @var scrollable Indique si le tableau sera scrollable s'il dépasse une certaine dimension */
  private scrollable: ScrollMode;
  /** @var selectableConfig Indique si les lignes sont sélectionnables */
  private selectableConfig: SelectableSettings;
  /** @var linesSelectable Indique si les lignes sont sélectionnables */
  private linesSelectable: ColSelectable;
  /** @var buttons Boutons additinnels en entête du tableau */
  private buttons: Button[];
  /** @var exportable Configuration de paramètrage de la partie export du tableau */
  private exportable: ExportConfig;
  /** @var loading État du chargement du tableau */
  private loading: boolean;
  /** @var loadingInfos Texte affiché lors du chargement du tableau */
  private loadingInfos: string;
  /** @var messages Textes d'affichage des éléments du tableau */
  private messages: {
    modelName: string;
    modelsName: string;
    groupPanelEmpty: string;
    noRecords: string;
    bottomToolbarLabel: string;
  };
  /** @var showLineCount Indique si le nombre de ligne est affiché dans le tableau */
  private showLineCount: boolean;

  /**
   * Constructeur de tableaux 'Kendo'
   * @param datas Tableau récupérant les éléments issue de l'appel serveur
   * @param columns Colonnes à afficher dans le tableau
   * @param optionnals.elementsType Nom du type des éléments à destination des messages
   * @param optionnals.primaryKey Indicateur d'affichage de la pagination du tableau
   * @param optionnals.pageable Indicateur d'affichage de la pagination du tableau
   * @param optionnals.pageSize Nombre d'éléments par page du tableau
   * @param optionnals.pageSizes Possibilités de redimensionnement de la sélection d'élément du tableau
   * @param optionnals.sortable Indicateur d'activation de l'option de tri du tableau
   * @param optionnals.reordable Indicateur d'activation de l'option de réorganisation du tableau
   * @param optionnals.groupable Indicateur d'activation de l'option de groupement des colonnes du tableau
   * @param optionnals.resizable Indicateur d'activation de l'option de changement des tailles des colonnes du tableau
   * @param optionnals.showLineCount Indicateur d'activation de l'option d'affichage du nombre d'éléments récupérés dans le tableau
   * @param optionnals.scrollable Mode du scroll du tableau
   * @param optionnals.linesSelectable Paramétrage de la colonne permettant de sélectionner les lignes du tableau
   * @param optionnals.exportable Paramétrage de l'option d'export Excel du tableau
   * @param optionnals.buttons Paramétrage des boutons additionnels du tableau
   */
  constructor(
    datas: T[],
    columns: Array<Column<ColType>>,
    optionnals?: {
      elementsType?: string;
      primaryKey?: string;
      pageable?: boolean;
      pageSize?: PageSize;
      pageSizes?: PageSizes;
      sortable?: boolean;
      reordable?: boolean;
      groupable?: boolean;
      resizable?: boolean;
      showLineCount?: boolean;
      scrollable?: ScrollMode;
      linesSelectable?: ColSelectable;
      exportable?: ExportConfig;
      buttons?: Button[];
    }
  ) {
    const typeName: string = optionnals?.elementsType ?? 'élément';
    this.datas = datas;
    this.columns = columns;
    this.messages = {
      modelName: typeName,
      modelsName: pluralize(typeName),
      groupPanelEmpty:
        'Faites glisser un en-tête de colonne et déposer ici pour grouper par cette colonne',
      noRecords: "Aucun élément n'a été trouvé",
      bottomToolbarLabel: "Barre d'outils du tableau"
    };
    this.primaryKey = optionnals?.primaryKey;
    this.pagination = new Pagination(
      typeName,
      optionnals?.pageSize ?? 10,
      optionnals?.pageSizes ?? [5, 10, 25, 50]
    );
    this.pageable = optionnals?.pageable ?? datas?.length > this.pagination.getPageSize();
    this.sortable = optionnals?.sortable ?? true;
    this.reorderable = optionnals?.reordable ?? true;
    this.groupable = optionnals?.groupable ?? false;
    this.resizable = optionnals?.resizable ?? true;
    this.filterable = false;
    this.scrollable = optionnals?.scrollable ?? 'scrollable';
    this.exportable = optionnals?.exportable;
    this.buttons = optionnals?.buttons ?? new Array<Button>();
    this.showLineCount = optionnals?.showLineCount ?? false;
    this.loading = false;
    this.loadingInfos = 'Chargement des ' + pluralize(typeName) + ' veuillez patienter...';
    this.linesSelectable = optionnals?.linesSelectable;
    this.selectableConfig = {
      enabled: !isUndefined(optionnals?.linesSelectable),
      mode: optionnals?.linesSelectable?.mode ?? 'multiple',
      drag: false,
      cell: false,
      checkboxOnly: true
    };
  }

  //#region Accesseurs
  public getDatas(): T[] {
    return this.datas;
  }

  public setDatas(datas: T[]): void {
    this.datas = datas;
    this.setPageable(datas.length > this.pagination.getPageSize());
  }

  public getColumns(): Column<ColType>[] {
    return this.columns;
  }

  //#region Messages
  public getMessage(
    property: 'modelName' | 'modelsName' | 'groupPanelEmpty' | 'noRecords' | 'bottomToolbarLabel'
  ): string {
    return this.messages[property];
  }

  public getMessages(): {
    modelName: string;
    modelsName: string;
    groupPanelEmpty: string;
    noRecords: string;
    bottomToolbarLabel: string;
  } {
    return this.messages;
  }

  public setMessage(
    property: 'modelName' | 'groupPanelEmpty' | 'noRecords' | 'bottomToolbarLabel',
    message: string
  ): void {
    if (property === 'modelName') {
      this.messages['modelsName'] = pluralize(message);
    }
    this.messages[property] = message;
  }

  public setMessages(messages: {
    modelName: string;
    modelsName: string;
    groupPanelEmpty: string;
    noRecords: string;
    bottomToolbarLabel: string;
  }): void {
    this.messages = messages;
  }
  //#endregion

  public getPrimaryKey(): string | undefined {
    return this.primaryKey;
  }

  public setPrimaryKey(primaryKey: string): void {
    this.primaryKey = primaryKey;
  }

  public isPageable(): boolean {
    return this.pageable;
  }

  public setPageable(pageable: boolean): void {
    this.pageable = pageable;
    if (pageable && !this.pagination) {
      this.setPagination(new Pagination(this.messages.modelName, 10, [5, 10, 25, 50]));
    }
  }

  public getPagination(): Pagination {
    return this.pagination;
  }

  public setPagination(pagination: Pagination): void {
    this.pagination = pagination;
  }

  public isSortable(): boolean {
    return this.sortable;
  }

  public setSortable(sortable: boolean): void {
    this.sortable = sortable;
  }

  public isReorderable(): boolean {
    return this.reorderable;
  }

  public setReorderable(reorderable: boolean): void {
    this.reorderable = reorderable;
  }

  public isGroupable(): boolean {
    return this.groupable;
  }

  public setGroupable(groupable: boolean): void {
    this.groupable = groupable;
  }

  public isResizable(): boolean {
    return this.resizable;
  }

  public setResizable(resizable: boolean): void {
    this.resizable = resizable;
  }

  public isFilterable(): boolean {
    return this.filterable;
  }

  public setFilterable(filterable: boolean): void {
    this.filterable = filterable;
  }

  public isScrollable(): ScrollMode {
    return this.scrollable;
  }

  public setScrollable(scrollable: ScrollMode): void {
    this.scrollable = scrollable;
  }

  public getSelectableConfig(): SelectableSettings {
    return this.selectableConfig;
  }

  public getLinesSelectable(): ColSelectable {
    return this.linesSelectable;
  }

  public setLinesSelectable(linesSelectable: ColSelectable): void {
    this.linesSelectable = linesSelectable;

    this.selectableConfig = {
      enabled: !isUndefined(linesSelectable),
      mode: linesSelectable?.mode ?? 'multiple',
      drag: false,
      cell: false,
      checkboxOnly: true
    };
  }

  public getExportable(): ExportConfig {
    return this.exportable;
  }

  public setExportable(exportable: ExportConfig): void {
    this.exportable = exportable;
  }

  public setExcelFormatedDatas(readyToExport: any[]): void {
    if (this.exportable) this.exportable.formatedDatas = readyToExport;
  }

  public isLoading(): boolean {
    return this.loading;
  }

  public setLoading(isLoading: boolean): void {
    this.loading = isLoading;
  }

  public getLoadingInfos(): string {
    return this.loadingInfos;
  }

  public setLoadingInfos(loadingInfos: string): void {
    this.loadingInfos = loadingInfos;
  }

  public getAdditionalButtons(): Button[] {
    return this.buttons;
  }

  public setAdditionalButtons(button: Button[]): void {
    this.buttons = button;
  }

  public isShowLineCount(): boolean {
    return this.showLineCount;
  }

  public setShowLineCount(showLineCount: boolean): void {
    this.showLineCount = showLineCount;
  }
  //#endregion

  //#region CRUD
  /**
   * Permet de supprimer une donnée de la table et retourne sa taille finale
   * @param data Donnée à supprimer
   */
  public splice(data: T): number {
    if ((this.datas?.length ?? 0) === 0) return 0;
    this.setDatas(
      this.datas.filter((stored: T) =>
        this.primaryKey
          ? (stored as any)[this.primaryKey] !== (data as any)[this.primaryKey]
          : JSON.stringify(stored) !== JSON.stringify(data)
      )
    );
    return this.datas.length;
  }
  //#endregion

  /**
   * Permet de récupérer la bonne intervale de colonne à fusionner pour l'entête du excel exporté
   * @returns L'intervale de colonne à fusionner
   */
  public getMergeRangeForExcelHeader(): string {
    let alphabet: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    return 'A1:' + alphabet.charAt(this.columns.length - 1) + '1';
  }

  /**
   * Permet de convertir les données du tableau dans un format exportable en version Excel
   * @returns Le résultat permettant d'avoir notre export Excel
   */
  public exportAllDatas: () => ExcelExportData = () => {
    return {
      data: process(this.exportable?.formatedDatas ?? this.datas, {
        sort: [{ field: this.columns[0].getProperties()[0], dir: 'asc' }]
      }).data
    };
  };
}
