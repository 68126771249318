<!-- #region ----- SERVICE ----- -->
<div
  class="h-full flex justify-center items-center"
  [ngClass]="{ 'cursor-not-allowed pointer-events-none opacity-50': disabled }">
  <div
    class="rounded-l-md w-3/4 h-full flex justify-center items-center service"
    [ngClass]="{
      attente: subscribed?.getEtatService()?.Libelle === 'EN ATTENTE',
      resilie: subscribed?.getEtatService()?.Libelle === 'RESILIE',
      retracte: subscribed?.getEtatService()?.Libelle === 'RETRACTE',
      annule: subscribed?.getEtatService()?.Libelle === 'ANNULE'
    }">
    <ng-container
      *ngTemplateOutlet="
        IconTemplate;
        context: { service: subscribed?.getTypeService() ?? available }
      "></ng-container>
  </div>
  <div
    class="cursor-pointer rounded-r-md bg-neutral-900 text-white w-1/4 h-full flex justify-center items-center"
    (click)="info.toggle($event)">
    <i class="pi pi-info-circle"></i>
  </div>
</div>
<!-- #endregion -->
<!-- #region ----- OVERLAY ----- -->
<p-overlayPanel #info styleClass="waterp-overlay-fit max-w-sm">
  <div *ngVar="subscribed?.getTypeService() ?? available as service">
    <!-- #region HEAD -->
    <div class="flex flex-col justify-center p-5 pb-0 text-center text-md gap-y-2">
      {{ service?.getLibelle() }}
      <div *ngIf="subscribed" class="italic">
        {{ subscribed.getEtatService()?.Libelle }} depuis le
        {{ subscribed.getDateEtat() | dateUnixToDate }}
      </div>
    </div>
    <!-- #endregion -->
    <p-divider></p-divider>
    <!-- #region CONTENT -->
    <div class="flex justify-center px-5 text-center font-bold">
      {{ service?.getLibelleArgumentaireBulle() }}
    </div>
    <!-- #endregion -->
    <p-divider styleClass="p-2"></p-divider>
    <!-- PDF -->
    <div
      class="flex gap-2 px-5 underline cursor-pointer hover:bg-primary-50 hover:rounded-sm"
      [ngClass]="disablePlaquette ? 'cursor-not-allowed pointer-events-none opacity-50' : ''"
      (click)="sendAndShowBrochure(service?.getCode() ?? '')">
      <fa-icon [icon]="faFilePdf"></fa-icon>
      {{ resource.GlobalTexts.Abonnement.Service.SendPlaquette }}
    </div>
    <p-divider styleClass="p-2"></p-divider>
    <!-- OPTION -->
    <div
      *ngIf="subscribed"
      class="flex gap-2 px-5 pt-0 mb-5 underline cursor-pointer hover:bg-primary-50 hover:rounded-sm"
      (click)="servicesService.changeState(subscribed)">
      <fa-icon [icon]="faShare"></fa-icon>
      <div
        *ngVar="resource.GlobalTexts.Abonnement.Service.SubsribedList.ChangeState as ChangeState">
        {{
          subscribed.getLibelle()
            ? "Passer à l'état '" + subscribed.getLibelle()?.toLowerCase() + "'"
            : subscribed.getEtatService()?.Libelle === 'RESILIE'
            ? ChangeState.Subscribe
            : ChangeState.Unsubscribe
        }}
      </div>
    </div>
    <div
      *ngIf="available"
      class="flex gap-2 px-5 pt-0 mb-5 underline cursor-pointer hover:bg-primary-50 hover:rounded-sm"
      (click)="servicesService.activate(available)">
      <fa-icon [icon]="faDownload"></fa-icon>
      {{ resource.GlobalTexts.Abonnement.Service.AvailableList.Activate }}
    </div>
  </div>
</p-overlayPanel>
<!-- #endregion -->

<!-- #region TEMPLATES -->
<ng-template #IconTemplate let-service="service">
  <i
    *ngIf="!isLoading"
    class="text-white text-[30px]"
    [ngClass]="{
      'waterpIcons-Aquamemo': ['04', '14'].includes(service?.getCode()),
      'waterpIcons-RadioReleve': service?.getCode() === '02',
      'waterpIcons-EcoReleve': service?.getCode() === '07',
      'waterpIcons-FactureElectronique': service?.getCode() === 'DM'
    }"></i>
  <i class="spinner-icon waterpIcons-Spinner mr-2" *ngIf="isLoading"></i>
</ng-template>
<!-- #endregion -->
