import { ServicesManagerComponent } from './../services/services-manager/services-manager.component';
import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { format } from 'date-fns';
import { MessageService } from 'primeng/api';
import { TabViewModule } from 'primeng/tabview';
import { lastValueFrom, take } from 'rxjs';
import { TabIsLoading } from 'src/app/api/models/interface/LazyLoading';
import { WebFonctions } from 'src/app/api/models/interface/WebFunction';
import { ContextResponse } from 'src/app/api/models/response/abonnement/ContextResponse';
import { InformationsIdentiteResponse } from 'src/app/api/models/response/abonnement/InformationsIdentiteResponse';
import { GeneriqueResponse } from 'src/app/api/models/shared/generiqueResponse';
import { AbonnementService } from 'src/app/api/services/abonnement.service';
import { TiersService } from 'src/app/api/services/tiers.service';
import { AppResource } from 'src/app/app.resource';
import { shortcut } from 'src/app/core/models/header.model';
import { Card } from 'src/app/core/models/historique.model';
import * as fromAbonnement from 'src/app/core/state/abonnement';
import { State } from 'src/app/core/state/core.state';
import * as fromEtat from 'src/app/core/state/etat';
import * as fromHeader from 'src/app/core/state/header';
import * as fromHistorique from 'src/app/core/state/historique';
import * as fromInfosTechniques from 'src/app/core/state/infos-techniques';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { FrameComponent } from 'src/app/shared/components/frame/frame.component';
import { AbonnementDataService } from 'src/app/shared/services/abonnement-data.service';
import { ApiResponseBodyUtils } from 'src/app/shared/utils/apiResponseBodyUtils';
import { formatDate } from 'src/app/shared/utils/dateUnixUtils';
import { cardDuplication } from 'src/app/shared/utils/historiqueUtils';
import { haveAutorisation } from 'src/app/shared/utils/webFonctionsUtils';
import { AutreFactureManuelleComponent } from '../compte/autre-facture-manuelle/autre-facture-manuelle/autre-facture-manuelle.component';
import { CreationRemboursementComponent } from '../compte/creation-remboursement/creation-remboursement.component';
import { CreerAvoirPartielComponent } from '../compte/creer-avoir-partiel/creer-avoir-partiel.component';
import { CreerAvoirTotalComponent } from '../compte/creer-avoir-total/creer-avoir-total.component';
import { EcheancierPaiementComponent } from '../compte/echeancier-paiement/echeancier-paiement.component';
import { FactureManuelleComponent } from '../compte/facture-manuelle/facture-manuelle/facture-manuelle.component';
import { PlanMensualisationComponent } from '../compte/plan-mensualisation/plan-mensualisation.component';
import { DevisContratComponent } from '../devis-contrat/devis-contrat.component';
import { InformationsFinanciersComponent } from '../informations-financiers/informations-financiers.component';
import { InformationsIdentiteComponent } from '../informations-identite/informations-identite.component';
import { InformationsTechniquesComponent } from '../informations-techniques/informations-techniques.component';
import { MiseEnServiceAbonnementComponent } from '../mise-en-service-abonnement/mise-en-service-abonnement.component';
import { ResilierAbonnementComponent } from '../resilier-abonnement/resilier-abonnement.component';
import { ListServicesComponent } from '../services/list-services/list-services.component';

@Component({
  selector: 'app-abonnement-synthese',
  templateUrl: './abonnement-synthese.component.html',
  styleUrls: ['./abonnement-synthese.component.scss'],
  imports: [
    CommonModule,
    RouterModule,
    ServicesManagerComponent,
    InformationsIdentiteComponent,
    InformationsFinanciersComponent,
    InformationsTechniquesComponent,
    ListServicesComponent,
    DevisContratComponent,
    PlanMensualisationComponent,
    CreationRemboursementComponent,
    EcheancierPaiementComponent,
    FactureManuelleComponent,
    AutreFactureManuelleComponent,
    CreerAvoirPartielComponent,
    CreerAvoirTotalComponent,
    ResilierAbonnementComponent,
    MiseEnServiceAbonnementComponent,
    TabViewModule,
    FrameComponent
  ],
  standalone: true
})
export class AbonnementSyntheseComponent extends BaseComponent implements OnInit {
  //#region Input Output
  // Ouvre un tab
  @Input() activeIndexTab: number = 0;
  // Affichage calcul devis
  @Input() devisContratIsVisible: boolean = false;
  // Affichage ajout conso
  @Input() ajoutConsoIsVisible: boolean = false;
  // refresh consommations aprés historique
  @Input() refreshConsommations: boolean = false;
  // refresh FPA aprés création echéancier
  @Input() refreshFPA: boolean = false;
  // refresh liste rembroursement aprés création remboursement
  @Input() refreshRemboursement: boolean = false;
  // refresh liste des mensualites aprés création mensualites
  @Input() refreshMensualites: boolean = false;
  // refresh liste des communication aprés création courriers
  @Input() refreshCommunications: boolean = false;
  @Input() ajoutInterventionResiliationIsVisible: boolean = false;
  // Affichage ajout intervention
  @Input() ajoutInterventionIsVisible: boolean = false;
  // Affichage modification intervention
  @Input() modifInterventionIsVisible: boolean = false;
  // Affichage consultation intervention
  @Input() consultInterventionIsVisible: boolean = false;
  // Affichage création plan de mensualisation
  @Input() creerPlanMensualisationIsVisible: boolean = false;
  // Affichage création plan de remboursement
  @Input() creerRemboursementIsVisible: boolean = false;
  // Affichage création échéancier de paiement
  @Input() creerEcheancierPaiementIsVisible: boolean = false;
  // Affichage création facture manuelle
  @Input() creerFactureManuelleIsVisible: boolean = false;
  // Affichage création autre facture manuelle
  @Input() creerAutreFactureManuelleIsVisible: boolean = false;
  // Affichage nouveau courrier
  @Input() creerNouveauCourrierIsVisible: boolean = false;
  // Affichage nouveau courrier à transférer
  @Input() creerNouveauCourrierATransfererIsVisible: boolean = false;
  // Affichage mutation
  @Input() mutationIsVisible: boolean = false;
  // Affichage Résilier abonnement
  @Input() resilierAbonnementIsVisible: boolean = false;
  // Affichage Mise En Service abonnement
  @Input() miseEnServiceAbonnementIsVisible: boolean = false;
  // Affichage création avoir partiel
  @Input() isVisibleAvoirPartiel: boolean = false;
  // Affichage création avoir total
  @Input() isVisibleAvoirTotal: boolean = false;
  // identifiant intervention
  @Input() numIntervention!: string;
  @Input() numModifIntervention!: string;
  isRadioReleve!: boolean;
  isTeleReleve!: boolean;
  //#endregion

  isMutable: boolean = false;
  isResiliable: boolean = false;
  // Afficher intervention mutation
  interMutation: boolean = false;

  res: number = 0;
  //#region Variables
  webFunctions!: WebFonctions;
  cards!: Card[];
  informationsIdentiteVM!: InformationsIdentiteResponse;

  numAbonnement!: string;
  numPointDesserte!: string;
  numAppareil!: string;
  numClient!: string;
  etatAbonnement!: string;
  shortcut!: shortcut;
  tabLoading: TabIsLoading[] = [];
  //#endregion

  //#region Component Init
  constructor(
    store: Store<State>,
    resources: AppResource,
    messageService: MessageService,
    private abonnementDataService: AbonnementDataService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private abonnementService: AbonnementService,
    private tiersService: TiersService,
    private title: Title
  ) {
    super(store, resources, messageService);

    for (let index = 0; index < 8; index++) {
      this.tabLoading.push({ tab: index, loading: index == 0 ? true : false });
    }
    this.store.select(fromHistorique.selectIndex).subscribe(res => {
      if (res) {
        this.res = res;
        this.activeIndexTab = res != 20 ? res : this.activeIndexTab;
      }
    });

    this.abonnementDataService.resilierAbonnementIsVisible$.subscribe(isVisible => {
      this.resilierAbonnementIsVisible = isVisible;
    });

    this.abonnementDataService.miseEnServiceAbonnementIsVisible$.subscribe(isVisible => {
      this.miseEnServiceAbonnementIsVisible = isVisible;
    });

    this.abonnementDataService.mutationIsVisible$.subscribe(isVisible => {
      this.mutationIsVisible = isVisible;
    });

    this.abonnementDataService.ajoutConsoIsVisible$.subscribe(isVisible => {
      this.ajoutConsoIsVisible = isVisible;
    });

    this.abonnementDataService.ajoutInterventionIsVisible$.subscribe(isVisible => {
      this.ajoutInterventionIsVisible = isVisible;
    });

    this.abonnementDataService.ajoutInterventionResiliationIsVisible$.subscribe(isVisible => {
      this.ajoutInterventionResiliationIsVisible = isVisible;
    });

    this.abonnementDataService.consultInterventionIsVisible$.subscribe(isVisible => {
      this.consultInterventionIsVisible = isVisible;
    });

    this.abonnementDataService.modifInterventionIsVisible$.subscribe(isVisible => {
      this.modifInterventionIsVisible = isVisible;
    });

    this.abonnementDataService.creerNouveauCourrierIsVisible$.subscribe(isVisible => {
      this.creerNouveauCourrierIsVisible = isVisible;
    });

    this.abonnementDataService.creerNouveauCourrierATransfererIsVisible$.subscribe(isVisible => {
      this.creerNouveauCourrierATransfererIsVisible = isVisible;
    });

    this.abonnementDataService.creerPlanMensualisationIsVisible$.subscribe(isVisible => {
      this.creerPlanMensualisationIsVisible = isVisible;
    });

    this.abonnementDataService.creerRemboursementIsVisible$.subscribe(isVisible => {
      this.creerRemboursementIsVisible = isVisible;
    });

    this.abonnementDataService.creerEcheancierPaiementIsVisible$.subscribe(isVisible => {
      this.creerEcheancierPaiementIsVisible = isVisible;
    });

    this.abonnementDataService.creerFactureManuelleIsVisible$.subscribe(isVisible => {
      this.creerFactureManuelleIsVisible = isVisible;
    });

    this.abonnementDataService.creerAutreFactureManuelleIsVisible$.subscribe(isVisible => {
      this.creerAutreFactureManuelleIsVisible = isVisible;
    });

    this.abonnementDataService.isVisibleAvoirPartiel$.subscribe(isVisible => {
      this.isVisibleAvoirPartiel = isVisible;
    });

    this.abonnementDataService.isVisibleAvoirTotal$.subscribe(isVisible => {
      this.isVisibleAvoirTotal = isVisible;
    });

    this.abonnementDataService.devisContratIsVisible$.subscribe(isVisible => {
      this.devisContratIsVisible = isVisible;
    });

    this.abonnementDataService.tabIndex$.subscribe(tabIndex => {
      this.activeIndexTab = tabIndex;
    });
  }

  ngOnDestroy() {
    this.abonnementDataService.resetPanelsVisibility();
  }
  override ngOnInit() {
    this.abonnementDataService.setIsShortcut(false);

    this.store.select(fromInfosTechniques.selectInfosTechniques).subscribe(res => {
      this.isRadioReleve = res.isRadioReleve;
      this.isTeleReleve = res.isTeleReleve;
    });

    this.initDataShortcut();

    this.store
      .select(fromHeader.selectWebFunctions)
      .pipe(take(1))
      .subscribe(webFunctionsTrans => (this.webFunctions = webFunctionsTrans));

    this.store
      .select(fromAbonnement.selectNumeroAppareil)
      .pipe(take(1))
      .subscribe(numAppareilExists =>
        numAppareilExists ? (this.numAppareil = numAppareilExists) : null
      );

    /**
     * Au (re)chargement de la page :
     * fermer avoir total + avoir partiel
     * fermer le PDF et réinitialiser les données d'affichage
     */
    this.abonnementDataService.setShowBouncingFrame('AvoirPartiel', false);
    this.abonnementDataService.setShowBouncingFrame('AvoirTotal', false);

    this.initDataInformationIdentite();
  }
  //#endregion

  //#region Init Data
  initDataInformationIdentite() {
    this.abonnementService.getInformationsIdentite(this.numAbonnement).subscribe({
      next: async res => {
        if (res.Code == 100) {
          const informationsIdentite =
            ApiResponseBodyUtils.ExtractResponseBody<InformationsIdentiteResponse>(res);
          this.informationsIdentiteVM = informationsIdentite;
          this.abonnementDataService.setInformationsIdentiteVM(informationsIdentite);
          if (informationsIdentite.DateAbonnement)
            this.informationsIdentiteVM.DateAbonnement = formatDate(
              informationsIdentite.DateAbonnement
            );

          this.numClient = informationsIdentite.ClientNumero;
          this.numPointDesserte = informationsIdentite.NumeroPointDesserte;

          this.abonnementDataService.setNumAbonnement(this.numAbonnement);
          this.abonnementDataService.setNumClient(this.numClient);
          this.abonnementDataService.setNumPointDesserte(this.numPointDesserte);
          this.abonnementDataService.setClientRaisonSociale(
            this.informationsIdentiteVM.ClientRaisonSociale
          );
          this.abonnementDataService.setIdentifiantTiers(
            this.informationsIdentiteVM.IdentifiantTiers
          );

          this.etatAbonnement = informationsIdentite.EtatAbonnementLibelle;

          if (this.shortcut) {
            let title =
              'Abonnement n°' + this.numAbonnement + ' ' + this.shortcut.LettreCleAbonnement;
            this.title.setTitle(title + ' - Wat.erp Web');
            this.store.dispatch(fromHeader.updateTitle({ title }));
          }

          this.store.dispatch(
            fromAbonnement.patchAbonnement({
              payload: {
                numeroAbonnement: this.numAbonnement,
                numeroClient: this.numClient,
                numeroPointDesserte: this.numPointDesserte,
                etatAbonnement: this.etatAbonnement,
                numeroAppareil: this.numAppareil
              }
            })
          );
          let cardTrans = await lastValueFrom(
            this.store.select(fromHistorique.selectCards).pipe(take(1))
          );

          let card: Card = {
            numAbonne: this.numAbonnement,
            numClient: this.numClient,
            numIris: this.informationsIdentiteVM.IdentifiantTiers
              ? this.informationsIdentiteVM.IdentifiantTiers.toString()
              : null,
            time: format(new Date(), 'HH:mm:ss'),
            name: this.informationsIdentiteVM.ClientRaisonSociale,
            type: 'AB'
          };
          this.cards = cardDuplication('AB', cardTrans ? cardTrans : [], card);
          this.store.dispatch(fromHistorique.updateHistoriqueCard({ cards: this.cards }));
        }
        if (res.Code == 112)
          BaseComponent.redirectToAuthentification(
            this.store,
            this.router,
            '/abonnement/synthese/' + this.numAbonnement
          );
        if (res.Code == 102) {
          this.store.dispatch(fromHeader.updateError({ errorSource: 'abonnement', message: null }));
          this.router.navigate(['erreur']);
        }
        if (res.Code == 1158) {
          this.store.dispatch(
            fromHeader.updateError({ errorSource: 'abonnement', message: res.Description })
          );
          this.router.navigate(['erreur']);
        }
      },
      error: res => {
        console.error(res);
      }
    });
  }

  // ! PASSABLE EN RESOLVER
  initDataShortcut() {
    let resultRoute = this.activatedRoute.snapshot;
    while (resultRoute.firstChild) {
      resultRoute = resultRoute.firstChild;
    }

    this.activatedRoute.paramMap.subscribe(params => {
      this.numAbonnement = params.get('numAbonnement')!;
    });

    // get context
    this.abonnementService.getContextAbonnement(this.numAbonnement).subscribe({
      next: async (res: GeneriqueResponse) => {
        if (res.Code == 100) {
          const contextAbonnement = ApiResponseBodyUtils.ExtractResponseBody<ContextResponse>(res);
          const shortcutRes: shortcut = {
            CanManageServices: contextAbonnement.CanManageServices,
            HasEmail: contextAbonnement.HasEmail,
            HasFacturationReleveEnCours: contextAbonnement.HasFacturationReleveEnCours,
            HasModeDistributionCompteur: contextAbonnement.HasModeDistributionCompteur,
            IndicateurDematBToC: contextAbonnement.IndicateurDematBToC,
            IndicateurPaiementSur: contextAbonnement.IndicateurPaiementSur,
            IndicateurPliNonDistribue: contextAbonnement.IndicateurPliNonDistribue,
            IndicateurSensible: contextAbonnement.IndicateurSensible,
            IndicateurTeleReleve: contextAbonnement.IndicateurTeleReleve,
            IsCessionInterne: contextAbonnement.IsCessionInterne,
            IsAbonnementIris: contextAbonnement.IsAbonnementIris,
            IsTechniquementResiliable: contextAbonnement.IsTechniquementResiliable,
            NumeroAbonnement: contextAbonnement.NumeroAbonnement,
            IndicateurChorus: contextAbonnement.IndicateurChorus,
            IndicateurGrandCompte: contextAbonnement.IndicateurGrandCompte,
            LettreCleAbonnement: contextAbonnement.LettreCleAbonnement,
            IndicateurConnexionAEL: contextAbonnement.IndicateurConnexionAEL,
            IdentifiantTiers: contextAbonnement.IdentifiantTiers
          };

          let actionProcessExist = resultRoute.paramMap.get('actionByProcess');
          let isMutable = contextAbonnement.IsMutable ?? false;
          let isResiliable = contextAbonnement.IsResiliable ?? false;

          if (actionProcessExist === 'Mutation') {
            let idTiersAboExist = resultRoute.paramMap.get('idTiersAbo');
            let idTiersDestExist = resultRoute.paramMap.get('idTiersDest');

            if (!idTiersAboExist) {
              this.messageServiceUtils.warning(this.resource.toast.errorIdTiers);
            } else {
              this.abonnementDataService.setIdTiersAbo(idTiersAboExist);
              this.tiersService
                .get(idTiersAboExist)
                .subscribe((result: GeneriqueResponse) =>
                  result.Code === 1012
                    ? this.messageServiceUtils.warning(this.resource.toast.errorIdTiers)
                    : null
                );
            }

            if (!idTiersDestExist) {
              this.messageServiceUtils.warning(this.resource.toast.errorIdTiers);
            } else {
              this.abonnementDataService.setIdTiersDest(idTiersDestExist);
              this.tiersService
                .get(idTiersDestExist)
                .subscribe((result: GeneriqueResponse) =>
                  result.Code == 1012
                    ? this.messageServiceUtils.warning(this.resource.toast.errorIdTiers)
                    : null
                );
            }

            if (isMutable) {
              this.abonnementDataService.setShowBouncingFrame('mutation', true);
            } else if (isResiliable) {
              this.abonnementDataService.setShowBouncingFrame('resilierAbonnement', true);
            } else {
              this.messageServiceUtils.warning(this.resource.toast.errorNotMutable);
            }
          } else if (actionProcessExist === 'NI') {
            this.abonnementDataService.setShowBouncingFrame('ajoutIntervention', true);
            this.abonnementDataService.setInterMutation(true);
          }

          let numeroAppareil = contextAbonnement.NumeroAppareil;
          this.store.dispatch(fromAbonnement.updateNumeroAppareil({ numeroAppareil }));
          this.numAppareil = numeroAppareil;

          this.abonnementDataService.setNumAppareil(this.numAppareil);

          let title = 'Abonnement n°' + this.numAbonnement + ' ' + shortcutRes.LettreCleAbonnement;
          this.title.setTitle(title + ' - Wat.erp Web');
          this.store.dispatch(fromHeader.updateTitle({ title }));

          this.store.dispatch(fromHeader.updateShortcut({ payload: shortcutRes }));

          this.store.dispatch(
            fromEtat.patchEtat({
              payload: {
                isMiseEnService: contextAbonnement.IsMiseEnServicePossible ?? false,
                isResiliable: contextAbonnement.IsResiliable ?? false,
                isEchangeable: false,
                isAnnulable: contextAbonnement.IsAbonnementAnnulable ?? false
              }
            })
          );
        } else if (res.Code === 123) {
          this.messageServiceUtils.error(
            this.resource.ErrorMessages.Client.NotFound_FromExisiting_Abonnement
          );
          this.router.navigate(['erreur']);
        } else {
          this.messageServiceUtils.warning(res.Description ?? this.resource.toast.errorDescription);
        }
      },
      error: () => {
        this.messageServiceUtils.error();
      }
    });
  }
  //#endregion

  //#region Public Function
  //renvoie titre solde selon valeur solde
  public getTexteSolde(solde: number) {
    let text;
    if (solde > 0) {
      text = 'Solde débiteur';
    } else if (solde < 0) {
      text = 'Solde créditeur';
    } else {
      text = 'Solde';
    }
    return text;
  }

  public getLoading(tabIndex: number) {
    if (tabIndex == this.activeIndexTab) {
      this.tabLoading[tabIndex].loading = true;
      if (this.activeIndexTab != 5 && this.activeIndexTab != 7 && this.res != 20)
        this.store.dispatch(fromHistorique.updateHistoriqueIndex({ index: 20 }));
      return true;
    } else return false;
  }
  //#endregion
  haveAutorisations(code: string, webfonctions: WebFonctions) {
    return haveAutorisation(code, webfonctions);
  }
}
