import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReferenceHistorique } from 'src/app/api/models/response/referenceBancaire/ReferenceBancaireHistoriqueResponse';
import { LoaderComponent } from '../../../loader/loader.component';
import { TableComponent } from '../../../table/table.component';
import { Table } from 'src/app/shared/models/tables/tables';
import { Column } from 'src/app/shared/models/tables/columns';
import { ColType } from 'src/app/shared/types/column/column-type.type';
import { ColumnType } from 'src/app/api/models/enum/column-types.enum';
import { Store } from '@ngrx/store';
import { State } from 'src/app/core/state/core.state';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Resource } from 'src/app/resources/resource';
import { AppResource } from 'src/app/app.resource';
import { MessageServiceUtils } from 'src/app/shared/utils/messageServiceUtils';
import { GeneriqueResponse } from 'src/app/api/models/shared/generiqueResponse';
import { map } from 'rxjs';
import { formatDateUnixToNumber } from 'src/app/shared/utils/dateUnixUtils';
import { format } from 'date-fns';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { WaterpSwal } from 'src/app/shared/models/waterp-swal';
import { EnumCanalAcquisition } from 'src/app/api/models/enum/EnumCanalAcquisition.enum';

@Component({
  selector: 'waterp-bank-historic',
  templateUrl: './bank-historic.component.html',
  styleUrls: ['./bank-historic.component.scss'],
  standalone: true,
  imports: [CommonModule, TableComponent, LoaderComponent],
  providers: [DialogService]
})
export class BankHistoricComponent implements OnInit, OnDestroy {
  private _resource: Resource;
  private _messageServiceUtils: MessageServiceUtils;

  table: Table<ReferenceHistorique>;

  constructor(
    public store: Store<State>,
    public resources: AppResource,
    public messageService: MessageService,
    private _dialogService: DialogService,
    private _dialogRef: DynamicDialogRef,
    private _dialogConfig: DynamicDialogConfig
  ) {
    this._resource = resources['resource'];
    this._messageServiceUtils = new MessageServiceUtils(this.messageService, this.resources);

    this.table = new Table<ReferenceHistorique>([], this.getColumns(), {
      elementsType: 'référence bancaire',
      linesSelectable: { mode: 'single' }
    });
  }

  /**
   * Initialisation du composant initialisation de l'historique
   * des références bancaires.
   */
  public ngOnInit(): void {
    this._dialogConfig.data.historic
      .pipe(map((response: GeneriqueResponse) => response.Result))
      .subscribe({
        next: (historicReferences: ReferenceHistorique[]) => {
          historicReferences = historicReferences
            .sort(
              (a: ReferenceHistorique, b: ReferenceHistorique) =>
                formatDateUnixToNumber(b.DateModification) -
                formatDateUnixToNumber(a.DateModification)
            )
            .map((reference: ReferenceHistorique) => {
              reference.DateModification = reference.DateModification
                ? new Date(formatDateUnixToNumber(reference.DateModification))
                : '';
              reference.DateRum = reference.DateRum
                ? new Date(formatDateUnixToNumber(reference.DateRum))
                : '';
              reference.LibelleCanalAcquisitionSepa ??= EnumCanalAcquisition.TELEPHONIE;
              return reference;
            });
          this.table.setDatas(historicReferences);
          this.table.setLoading(false);
          this._dialogConfig.closable = !this.table.isLoading();
        },
        error: () => {
          this.table.setLoading(false);
          this._dialogConfig.closable = !this.table.isLoading();
        }
      });
  }

  /**
   * Evenement Angular lors de la fin de vie du composant
   */
  public ngOnDestroy(): void {}

  /**
   * Permet de récupérer une référence bancaire ayant précédemment été configurée sur cette abonnement
   * @param historicReference Données de la ligne sélectionnée dans le tableau
   */
  public onRecoverHistoricReferences(historicReference: ReferenceHistorique): void {
    Swal.fire(
      new WaterpSwal(this._resource, 'warning', {
        text: this._resource.GlobalTexts.Abonnement.BankingReferences.OnChangeFromHistoric
      })
    ).then((choice: SweetAlertResult) => {
      if (choice.isConfirmed && historicReference) {
        this._dialogRef.close(historicReference);
      }
    });
  }

  //#region Définition des colonnes
  private getColumns(): Column<ColType>[] {
    return [
      new Column<ColumnType.DATETIME>('Modification', ColumnType.DATETIME, 'dd/MM/yyyy', {
        properties: ['DateModification'],
        width: 10
      }),
      new Column<ColumnType.TEXT>('Mode de paiement', ColumnType.TEXT, undefined, {
        properties: ['LibelleModePaiement'],
        width: 10
      }),
      new Column<ColumnType.TEXT>('Origine', ColumnType.TEXT, undefined, {
        properties: ['OrigineModification'],
        width: 5
      }),
      new Column<ColumnType.TEXT>('Ressource', ColumnType.TEXT, undefined, {
        properties: ['NomRessource'],
        width: 15
      }),
      new Column<ColumnType.TEXT>('BIC', ColumnType.TEXT, undefined, {
        properties: ['CodeBic'],
        width: 10
      }),
      new Column<ColumnType.TEXT>('IBAN', ColumnType.TEXT, undefined, {
        properties: ['CodeIban'],
        width: 20
      }),
      new Column<ColumnType.TEXT>('RUM', ColumnType.TEXT, undefined, {
        properties: ['NumeroRum'],
        width: 10
      }),
      new Column<ColumnType.DATETIME>('Date RUM', ColumnType.DATETIME, 'dd/MM/yyyy', {
        properties: ['DateRum'],
        width: 10
      }),
      new Column<ColumnType.TEXT>("Canal d'acquisition", ColumnType.TEXT, undefined, {
        properties: ['LibelleCanalAcquisitionSepa'],
        width: 10
      })
    ];
  }
  //#endregion
}
