import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[autoTab]',
  standalone: true
})
export class AutoTabDirective {
  @Input('autoTab') autoTabTrigger: number = 0;

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event'])
  onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.value.length >= this.autoTabTrigger) {
      this.moveToInput('next');
    } else if (input.value.length < 1) {
      this.moveToInput('previous');
    }
  }

  @HostListener('keydown.backspace', ['$event'])
  onKeydown(event: KeyboardEvent): void {
    if ((event.target as HTMLInputElement).value.length < 1) {
      this.moveToInput('previous');
    }
  }

  /**
   * Traverse le DOM pour trouver l'élément input suivant ou précédent et lui donne le focus.
   * Si aucun élément input suivant ou précédent n'est trouvé dans le même conteneur, recherche globalement dans le document.
   */
  private moveToInput(direction: 'next' | 'previous'): void {
    const currentElement = this.el.nativeElement;
    let targetElement =
      direction === 'next'
        ? currentElement.nextElementSibling
        : currentElement.previousElementSibling;

    // Traverse le DOM pour trouver le prochain input
    while (targetElement && targetElement.tagName !== 'INPUT') {
      targetElement =
        direction === 'next'
          ? targetElement.nextElementSibling
          : targetElement.previousElementSibling;
    }

    // S'il n'y a pas d'input dans le spectre de la recherche précédente, recherche globalement
    if (!targetElement) {
      const allInputs = Array.from(document.querySelectorAll('input'));
      const currentIndex = allInputs.indexOf(currentElement);
      targetElement = allInputs[currentIndex + (direction === 'next' ? 1 : -1)];
    }

    if (targetElement) {
      (targetElement as HTMLElement).focus();
    }
  }
}
