import { Store } from '@ngrx/store';
import { State } from 'src/app/core/state/core.state';
import * as fromHeader from 'src/app/core/state/header';
import { haveAutorisation } from 'src/app/shared/utils/webFonctionsUtils';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { map, Observable, tap } from 'rxjs';
import { WebFonctions } from 'src/app/api/models/interface/WebFunction';

@Injectable({
  providedIn: 'root'
})
export class InstallationsGuard implements CanActivate {
  constructor(private store: Store<State>, private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.store.select(fromHeader.selectWebFunctions).pipe(
      map((webFunctions: WebFonctions) => {
        let isAuthorized = haveAutorisation('INSTALLATION_CREATION', webFunctions);
        return isAuthorized !== undefined && isAuthorized !== false;
      }),
      tap((isAuthorized: boolean) => (!isAuthorized ? this.router.navigate(['accueil-csc']) : null))
    );
  }
}

