// ANGULAR
import { CurrencyPipe, DatePipe, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {
  APP_INITIALIZER,
  DEFAULT_CURRENCY_CODE,
  ErrorHandler,
  LOCALE_ID,
  NgModule
} from '@angular/core';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
registerLocaleData(localeFr);

// Load all required data for the bg locale
import '@progress/kendo-angular-intl/locales/fr/all';
// Load the required calendar data for the fr locale
import '@progress/kendo-angular-intl/locales/fr/calendar';

// MODULES
import { SharedModule } from 'src/app/shared/modules/shared.module';
import { AppRoutingModule } from './shared/modules/app-routing.module';
// COMPONENTS
import { AppComponent } from 'src/app/app.component';

// SERVICES
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AppResource } from 'src/app/app.resource';
import { metaReducers, reducers } from 'src/app/core/state/core.reducer';
import { HydrationEffects } from 'src/app/core/state/hydration/hydration.effects';
import { AuthGuard } from 'src/app/shared/guards/auth.guard';
import { extModules } from 'src/build-specifics/app.import';
import { DataSearchService } from './api/services/dataSearch.service';
import { AppConfig } from './app.config';
import { AppInsightService } from './shared/services/app-insight.service';

// GUARDS
import { OrganisationGuard } from './shared/guards/organisation.guard';
import { InstallationsGuard } from './shared/guards/installations/installations.guard';

export function initResource(resource: AppResource) {
  return () => resource.load();
}

export function initConfig(config: AppConfig) {
  return () => config.load();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    SharedModule,
    StoreModule.forRoot(reducers, {
      metaReducers
    }),
    EffectsModule.forRoot([HydrationEffects]),
    ...extModules
  ],
  providers: [
    AppResource,
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: initResource,
      deps: [AppResource],
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: ApplicationinsightsAngularpluginErrorService
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      deps: [AppConfig],
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: 'fr-FR'
    },
    DataSearchService,
    {
      provide: APP_INITIALIZER,
      useFactory: (values: DataSearchService) => () => values.getValues(),
      deps: [DataSearchService],
      multi: true
    },
    AuthGuard,
    OrganisationGuard,
    InstallationsGuard,
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'EUR'
    },
    AppInsightService,
    DatePipe,
    CurrencyPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
