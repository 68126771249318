import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { lastValueFrom, take } from 'rxjs';
import { DropDownList } from 'src/app/api/models/interface/KeyValue';
import { ParametrageGeneriqueService } from 'src/app/api/services/parametrageGenerique.service';
import { AppResource } from 'src/app/app.resource';
import { Proprietaire } from 'src/app/core/models/wizard.model';
import { State } from 'src/app/core/state/core.state';
import * as fromWizard from 'src/app/core/state/wizard';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { getDataFromLocaleStorage } from 'src/app/shared/utils/localStorageUtils';
import { RegexBank } from 'src/app/shared/utils/regex-bank';
import { KeyFilterModule } from 'primeng/keyfilter';

@Component({
  selector: 'app-proprietaire',
  templateUrl: './proprietaire.component.html',
  styleUrls: ['./proprietaire.component.scss'],
  imports: [CommonModule, ReactiveFormsModule, DropdownModule, InputTextModule, KeyFilterModule],
  standalone: true
})
export class ProprietaireComponent extends BaseComponent implements OnInit {
  //#region Input/Output
  @Output() validForm: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() previousForm: EventEmitter<boolean> = new EventEmitter<boolean>();
  //#endregion

  //#region Variables
  civilities: DropDownList[] = [];
  proprietaire!: Proprietaire;
  //#endregion

  //#region FormGroup
  proprietaireForm!: FormGroup;
  //#endregion

  //#region GetFormControls
  get civilite() {
    return this.proprietaireForm.get('civilite');
  }

  get proprietaireGerant() {
    return this.proprietaireForm.get('proprietaireGerant');
  }

  get adressePostale() {
    return this.proprietaireForm.get('adressePostale');
  }

  get codePostal() {
    return this.proprietaireForm.get('codePostal');
  }

  get ville() {
    return this.proprietaireForm.get('ville');
  }

  get numeroTelephone() {
    return this.proprietaireForm.get('numeroTelephone');
  }

  get email() {
    return this.proprietaireForm.get('email');
  }
  //#endregion

  constructor(
    store: Store<State>,
    resources: AppResource,
    messageService: MessageService,
    private parametrageGeneriqueService: ParametrageGeneriqueService
  ) {
    super(store, resources, messageService);

    //Initialisation des formulaires
    this.initForm();
  }

  override async ngOnInit() {
    super.ngOnInit();

    //Initialisation du refDataManager
    await this.initDataManager();

    this.proprietaire = await lastValueFrom(
      this.store.select(fromWizard.selectProprietaireData).pipe(take(1))
    );

    this.initDataForm(this.proprietaire);
  }

  //#region Initialisation DataManager
  async initDataManager() {
    this.civilities = await lastValueFrom(
      getDataFromLocaleStorage(
        this.resource.refDataManager.civilite,
        this.parametrageGeneriqueService
      )
    );

    this.civilities.sort((a, b) => {
      return a.Value.toLocaleLowerCase().localeCompare(b.Value.toLocaleLowerCase());
    });
  }
  //#endregion

  initForm() {
    this.proprietaireForm = new FormGroup(
      {
        civilite: new FormControl<DropDownList | null>(null, null),
        proprietaireGerant: new FormControl<string | null>(null, Validators.maxLength(35)),
        adressePostale: new FormControl<string | null>(null, Validators.maxLength(35)),
        codePostal: new FormControl<string | null>(null, Validators.maxLength(5)),
        ville: new FormControl<string | null>(null, Validators.maxLength(35)),
        numeroTelephone: new FormControl<string | null>(null, Validators.pattern(RegexBank.phone)),
        email: new FormControl<string | null>(null, Validators.pattern(RegexBank.email))
      },
      {
        updateOn: 'change'
      }
    );

    this.proprietaireForm.valueChanges.subscribe(() => {
      this.automaticSave();
    });
  }

  initDataForm(proprietaire: Proprietaire) {
    this.civilite?.setValue(
      this.civilities.find((res: DropDownList) => {
        return res.Key === proprietaire.civilite;
      })
    );
    this.proprietaireGerant?.setValue(proprietaire.proprietaireGerant);
    this.adressePostale?.setValue(proprietaire.adressePostale);
    this.codePostal?.setValue(proprietaire.codePostal);
    this.ville?.setValue(proprietaire.ville);
    this.numeroTelephone?.setValue(proprietaire.numeroTelephone);
    this.email?.setValue(proprietaire.email);
  }

  returnPreviousForm() {
    this.previousForm.emit(true);
  }

  submitForm() {
    if (this.proprietaireForm.valid) {
      this.validForm.emit(true);
    } else {
      this.proprietaireForm.markAllAsTouched();
    }
  }

  cancelForm() {
    this.initDataForm(this.proprietaire);
  }

  automaticSave() {
    this.store.dispatch(
      fromWizard.updateProprietaire({
        payload: {
          civilite: this.civilite?.value?.Key,
          proprietaireGerant: this.proprietaireGerant?.value,
          adressePostale: this.adressePostale?.value,
          codePostal: this.codePostal?.value,
          ville: this.ville?.value,
          numeroTelephone: this.numeroTelephone?.value,
          email: this.email?.value
        }
      })
    );
  }
}
