import { RessourceService } from 'src/app/api/services/ressource.service';
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, map } from 'rxjs';
import { GeneriqueResponse } from 'src/app/api/models/shared/generiqueResponse';
import { Option } from '../../models/option';
import { Unite } from 'src/app/api/models/class/unite';

@Injectable({
  providedIn: 'root'
})
export class GetUnitiesResolver implements Resolve<Option<string, string>[]> {
  constructor(private ressourceService: RessourceService) {}

  resolve(): Observable<Option<string, string>[]> {
    let notLimited: boolean = false;

    return this.ressourceService
      .getUnities(notLimited)
      .pipe(
        map((response: GeneriqueResponse) =>
          response.Result.map((unity: Unite) => new Unite(unity).toOption())
        )
      );
  }
}
