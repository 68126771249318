export enum StatutInterventions {
  Aplanifier = 'C',
  Aconfirmer = 'F',
  Planifie = 'P',
  Annule = 'A',
  Realise = 'R',
  Lancee = 'L',
  NonDefini = ''
}

export enum LibelleEtatIntervention {
  Aplanifier = 'A PLANIFIER',
  Aconfirmer = 'A CONFIRMER',
  Planifie = 'PLANIFIÉE',
  Annule = 'ANNULÉE',
  Realise = 'CLOTURÉE',
  Lancee = 'LANCÉE',
  NonDefini = 'NON DEFINI'
}

export enum TypeIntervention {
  Technique = 'T',
  Administrative = 'A',
  NonDefini = ''
}

export enum LibelleTypeIntervention {
  Technique = 'Technique',
  Administrative = 'Administrative',
  NonDefini = ''
}
export enum NomEcranMobilita {
  RemplacementCompteur = 'RPL',
  PoseCompteur = 'POS',
  DeposeCompteur = 'DPO'
}

export enum CodeMotif {
  OuvertureAbonnement = '001',
  Resiliation = '002',
  Technique = '003',
  Facturation = '004',
  Branchement = '005',
  QualiteEau = '006',
  Impayes = '007',
  Administrative = '010',
  ReseauEauPotable = '011',
  ReseauAstEtPluvial = '012',
  AssainissementNonCollectif = '013',
  TeleReleve = '015'
}
export enum CodeAction {
  ReleverIndex = '00001',
  OuvrirBranchement = '00002',
  PoserCompteur = '00003',
  FermerAlimentation = '00013',
  Administrative = '00202',
  EnqueteNotaire = '00140',
  InterventionNonFaite = '00200'
}
export enum CodeEtatAppareil {
  PoseAncienCompteur = '10',
  PoseCompteurNeuf = '15',
  DeposeVendu = '20',
  DeposeADetruire = '25',
  DeposeEnAttente = '30',
  DeposeUtilisable = '35',
  DisponibleEnStock = '40'
}
export enum CodeEtatBranchement {
  Ouvert = 'M',
  FermeNonPaiement = 'P',
  Ferme = 'F'
}
export enum CodeTypeSemaine {
  Remplacement = 'R'
}
export enum IndicateurPariteSemaine {
  Impair = 'I',
  Pair = 'P'
}
export enum TypeDemandeCanope {
  Deplacement = 'D',
  RendezVous = 'R'
}
export enum CodeApplicationMobilite {
  Intervention = 'ITA',
  Releve = 'RLV'
}
export enum CodeEtatInterventionOrigine {
  Lancement = 'L',
  Execution = 'E'
}
export enum CodeEtatMobilite {
  Mobilite = '1',
  NonMobilite = '0',
  AnnuleDesannulable = 'A'
}
export enum IndicateurCreationModificationInstallation {
  Creation = 'C',
  Modification = 'M'
}
export enum CodePrioriteRessourceIntervention {
  Default = 1
}
export enum CodeReseauTravo {
  Eau = 'E',
  Assainissement = 'A'
}
export enum CodeTypeBranchement {
  BanchementEau = '1',
  BanchementAssainissement = '2',
  BanchementEauAssainissement = '3'
}
export enum CodeTypeBranchementTravO {
  Eau = 'E',
  Assainissement = 'A'
}
export enum CommentaireIntervention {
  Resiliation = 'ANNULATION INTERVENTION SUITE A RESILIATION',
  ClotureMiseEnService = 'CLOTURE SUITE A MISE EN SERVICE',
  ResilitationInterventionAdministrative = 'RESILIATION SUR CONTRAT DE TYPE ',
  MAX_LENGHT_COMMENTAIRE = 1500
}
export enum CodeEtatIntervention {
  NonDefini = '',
  Realise = 'R',
  Planifie = 'P',
  Annule = 'A',
  APlanifier = 'C',
  AConfirmer = 'F'
}
export enum IndicateurAnnulationInterResiliation {
  True = 1,
  False = 0
}
export enum IndicateurInterventionOrigineComplementaire {
  Oui = '1'
}
export enum IndicateurRessourceGroupe {
  Oui = '1'
}
export enum IndicateurPhotoExistante {
  Oui = '1',
  Non = '0'
}
export enum IndicateurEngagementDeService {
  Oui = '1',
  Non = '0'
}
export enum IndicateurFraisIntervention {
  Oui = 'O'
}
export enum IndicateurPlanifiee {
  Oui = '1',
  Non = '0'
}
export enum IndicateurDequalificationUrgence {
  Oui = '1',
  Non = '0'
}
export enum IndicateurFrais {
  Frais = 'F'
}
export enum IndicateurClotureMiseEnService {
  Oui = '1'
}
export enum IndicateurClotureSansObjet {
  Oui = '1'
}
export enum IndicateurAstreinte {
  Oui = '1',
  Non = '0'
}
export enum IndicateurHeureSup {
  Oui = '1',
  Non = '0'
}
export enum IndicateurCreationInterventionSor {
  Oui = '1'
}
export enum IndicateurAConfirmer {
  Oui = '1',
  Non = '0'
}
export enum IndicateurPotInterventions {
  IndicateurPotAffecteeTempsReel = '2',
  IndicateurPotNonAffectee = '0'
}
export enum IndicateurRessourceAssistante {
  Oui = '1',
  Non = 'null'
}
export enum IndicateurLancementMobile {
  Oui = '1',
  Non = 'null'
}
export enum IndicateurRendezVousWeb {
  Oui = 'O'
}
export enum FlagActionWeb {
  Valide = 'V'
}
export enum IndicateurCelluleIntervention {
  Oui = '1'
}
export enum NombreJourMinPourRdv {
  AEL = 3
}
export enum NombreJoursProfondeur {
  Defaut = 28
}
export enum NombreRessource {
  Defaut = 1
}
export enum DureeTrajetCreneauIntervention {
  Defaut = '0'
}
export enum CodeStatutIntervention {
  NonDefini = '',
  Realise = 'R',
  Planifie = 'P',
  Annule = 'A',
  APlanifier = 'C'
}
export enum OrigineDemandeIntervention {
  Moveo = 'MOVEO'
}
export enum CodePeriodiciteActiviteRecurrente {
  Quotidienne = 0,
  Hebdomadaire = 1,
  Mensuelle = 2
}
export enum LibellePeriodiciteActiviteRecurrente {
  Quotidienne = 'Quotidienne',
  Hebdomadaire = 'Hebdomadaire',
  Mensuelle = 'Mensuelle'
}
export enum CodePeriodiciteJour {
  Lundi = 0,
  Mardi = 1,
  Mercredi = 2,
  Jeudi = 3,
  Vendredi = 4
}
export enum LibellePeriodiciteJour {
  Lundi = 'L',
  Mardi = 'M',
  Mercredi = 'Me',
  Jeudi = 'J',
  Vendredi = 'V'
}
export enum CodeTypeAction {
  Technique = 'T',
  Administrative = 'A'
}
export enum LibelleTypeAction {
  Technique = 'Technique',
  Administrative = 'Administrative'
}
export enum TypeActivite {
  Administrative = 'Administrative',
  Recurrente = 'Recurrente'
}
export enum CodeTypeIntervention {
  Technique = 'T',
  Administrative = 'A',
  GammeATA = 'G'
}
export enum CodeTypeLocalisation {
  LieuExploitation = 0,
  Abonne = 1,
  Rue = 2
}
export enum LibelleTypeLocalisation {
  LieuExploitation = "Lieu d'exploitation",
  Abonne = 'Abonné',
  Rue = 'Rue'
}
export enum CodeTypeTrancheHoraire {
  Journee = 'J',
  Matin = 'M',
  ApresMidi = 'A'
}
