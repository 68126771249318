import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AbonnementValidationService } from '../../api/services/abonnement-validation.service';

@Injectable({
  providedIn: 'root'
})
export class AbonnementExistGuard implements CanActivate {
  constructor(private abonnementValidationService: AbonnementValidationService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const numAbonnement = route.paramMap.get('numAbonnement') ?? '';
    return this.abonnementValidationService.validateAbonnement(numAbonnement);
  }
}
