import { CommonModule, DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { KeyFilterModule } from 'primeng/keyfilter';
import { lastValueFrom, take } from 'rxjs';
import { DropDownList } from 'src/app/api/models/interface/KeyValue';
import { KeyValueResponse } from 'src/app/api/models/response/key-value/keyValueResponse';
import { ParametrageGeneriqueService } from 'src/app/api/services/parametrageGenerique.service';
import { AppResource } from 'src/app/app.resource';
import { Client } from 'src/app/core/models/wizard.model';
import { State } from 'src/app/core/state/core.state';
import * as fromWizard from 'src/app/core/state/wizard';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { ApiResponseBodyUtils } from 'src/app/shared/utils/apiResponseBodyUtils';
import { getDataFromLocaleStorage } from 'src/app/shared/utils/localStorageUtils';
import { RegexBank } from 'src/app/shared/utils/regex-bank';

@Component({
  selector: 'app-information-client',
  templateUrl: './information-client.component.html',
  styleUrls: ['./information-client.component.scss'],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DropdownModule,
    CalendarModule,
    FontAwesomeModule,
    InputTextModule,
    KeyFilterModule
  ],
  standalone: true
})
export class InformationClientComponent extends BaseComponent implements OnInit {
  //#region Input/Output
  @Input() edit!: boolean;
  @Output() validForm: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() previousForm: EventEmitter<boolean> = new EventEmitter<boolean>();
  //#endregion

  //#region Variables
  client!: Client;

  civilites!: DropDownList[];
  civilitesClient!: DropDownList[];

  clientForm!: FormGroup;
  //#endregion

  faImage = faImage;
  prenomRequis: boolean = false;

  //#region getFormControl
  get civilite() {
    return this.clientForm.get('civilite');
  }

  get nom() {
    return this.clientForm.get('nom');
  }

  get numeroDansLaRue() {
    return this.clientForm.get('numeroDansLaRue');
  }

  get cpl() {
    return this.clientForm.get('cpl');
  }

  get etage() {
    return this.clientForm.get('etage');
  }

  get telephone1() {
    return this.clientForm.get('telephone1');
  }

  get telephone2() {
    return this.clientForm.get('telephone2');
  }

  get email() {
    return this.clientForm.get('email');
  }

  get dateNaissance() {
    return this.clientForm.get('dateNaissance');
  }

  get numeroSIRET() {
    return this.clientForm.get('numeroSIRET');
  }

  get codeAPE() {
    return this.clientForm.get('codeAPE');
  }

  get civiliteClient() {
    return this.clientForm.get('civiliteClient');
  }

  get raisonSociale() {
    return this.clientForm.get('raisonSociale');
  }

  get prenomClient() {
    return this.clientForm.get('prenomClient');
  }

  get adresse() {
    return this.clientForm.get('adresse');
  }

  get codePostal() {
    return this.clientForm.get('codePostal');
  }

  get ville() {
    return this.clientForm.get('ville');
  }

  get bureauDistributeur() {
    return this.clientForm.get('bureauDistributeur');
  }
  //#endregion

  constructor(
    store: Store<State>,
    resources: AppResource,
    messageService: MessageService,
    private parametrageGeneriqueService: ParametrageGeneriqueService,
    private datePipe: DatePipe
  ) {
    super(store, resources, messageService);

    this.initForm();
  }

  override async ngOnInit() {
    super.ngOnInit();

    this.civilites = await lastValueFrom(
      getDataFromLocaleStorage(
        this.resource.refDataManager.civilite,
        this.parametrageGeneriqueService
      )
    );

    this.civilites.push(
      ...(await lastValueFrom(
        getDataFromLocaleStorage(
          this.resource.refDataManager.qualiteClient,
          this.parametrageGeneriqueService
        )
      ))
    );

    await this.initData();

    this.civiliteClient?.valueChanges.subscribe(async (res: DropDownList) => {
      this.prenomRequis = false;
      var civilite = ApiResponseBodyUtils.ExtractResponseBody<KeyValueResponse>(
        await lastValueFrom(
          this.parametrageGeneriqueService.get(this.resource.refDataManager.civilite, res.Key)
        )
      );

      if (civilite && civilite['IndicateurPrenomRequis'] == 'O') this.prenomRequis = true;
    });

    this.clientForm.valueChanges.subscribe(() => {
      this.automaticSave();
    });
  }

  initForm() {
    this.clientForm = new FormGroup({
      civilite: new FormControl<DropDownList | null>({ value: null, disabled: !this.edit }),
      nom: new FormControl<string | null>(null, Validators.maxLength(35)),
      numeroDansLaRue: new FormControl<number | null>(null, Validators.maxLength(4)),
      cpl: new FormControl<string | null>(null, Validators.maxLength(1)),
      etage: new FormControl<string | null>(null, Validators.maxLength(3)),
      telephone1: new FormControl<string | null>(null, Validators.pattern(RegexBank.phone)),
      telephone2: new FormControl<string | null>(null, Validators.pattern(RegexBank.phone)),
      email: new FormControl<string | null>(null, [
        Validators.pattern(RegexBank.email),
        Validators.maxLength(100)
      ]),
      dateNaissance: new FormControl<string | null>({ value: null, disabled: !this.edit }),
      numeroSIRET: new FormControl<string | null>(null, Validators.maxLength(14)),
      codeAPE: new FormControl<string | null>(null, Validators.maxLength(5)),
      civiliteClient: new FormControl<DropDownList | null>({ value: null, disabled: !this.edit }),
      raisonSociale: new FormControl<string | null>(null, Validators.maxLength(35)),
      prenomClient: new FormControl<string | null>(null, Validators.maxLength(35)),
      adresse: new FormControl<string | null>(null, Validators.maxLength(35)),
      codePostal: new FormControl<string | null>(null, Validators.maxLength(5)),
      ville: new FormControl<string | null>(null, Validators.maxLength(35)),
      bureauDistributeur: new FormControl<string | null>(null, Validators.maxLength(35))
    });
  }

  async initData() {
    this.client = await lastValueFrom(this.store.select(fromWizard.selectClientData).pipe(take(1)));

    this.civilite?.setValue(
      this.civilites.find((res: DropDownList) => res.Key === this.client.codeCivilite),
      { emitEvent: false }
    );

    this.nom?.setValue(this.client.nom, { emitEvent: false });
    this.numeroDansLaRue?.setValue(this.client.numeroDansLaRue, { emitEvent: false });
    this.cpl?.setValue(this.client.cpl, { emitEvent: false });
    this.etage?.setValue(this.client.etage, { emitEvent: false });
    this.telephone1?.setValue(this.client.telephone1, { emitEvent: false });
    this.telephone2?.setValue(this.client.telephone2, { emitEvent: false });
    this.email?.setValue(this.client.email, { emitEvent: false });
    this.dateNaissance?.setValue(this.client.dateNaissance, { emitEvent: false });
    this.numeroSIRET?.setValue(this.client.numeroSIRET, { emitEvent: false });
    this.codeAPE?.setValue(this.client.codeAPE, { emitEvent: false });
    this.civiliteClient?.setValue(
      this.civilites.find((res: DropDownList) => res.Key === this.client.civiliteClient),
      { emitEvent: false }
    );
    this.raisonSociale?.setValue(this.client.raisonSociale, { emitEvent: false });
    this.prenomClient?.setValue(this.client.prenomClient, { emitEvent: false });
    this.adresse?.setValue(this.client.adresse, { emitEvent: false });
    this.codePostal?.setValue(this.client.codePostal, { emitEvent: false });
    this.ville?.setValue(this.client.ville, { emitEvent: false });
    this.bureauDistributeur?.setValue(this.client.bureauDistributeur, { emitEvent: false });

    // if (!this.edit) {
    //   this.civilite?.disable();
    //   this.dateNaissance?.disable();
    //   this.civiliteClient?.disable();
    // }
  }

  returnPreviousForm() {
    this.previousForm.emit(true);
  }

  submitForm() {
    this.validForm.emit(true);
  }

  automaticSave() {
    if (this.edit) {
      this.store.dispatch(
        fromWizard.updateClient({
          payload: {
            identifiantTiersAbo: null,
            identifiantTiersDest: null,
            codeCivilite: this.civilite?.value?.Key,
            nom: this.nom?.value,
            numeroRue: this.client.numeroRue,
            numeroDansLaRue: this.numeroDansLaRue?.value,
            cpl: this.cpl?.value,
            etage: this.etage?.value,
            telephone1: this.telephone1?.value,
            telephone2: this.telephone2?.value,
            email: this.email?.value,
            dateNaissance: this.datePipe.transform(this.dateNaissance?.value, 'dd/MM/yyyy'),
            numeroSIRET: this.numeroSIRET?.value,
            codeAPE: this.codeAPE?.value,
            civiliteClient: this.civiliteClient?.value?.Key,
            raisonSociale: this.raisonSociale?.value,
            prenomClient: this.prenomClient?.value,
            adresse: this.client.adresse,
            codePostal: this.client.codePostal,
            bureauDistributeur: this.client.bureauDistributeur,
            ville: this.client.ville,
            etatVerificationAdresse: this.client.etatVerificationAdresse
          }
        })
      );
    }
  }
}
