import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ListAutorisations } from 'src/app/api/models/class/autorisation/list-autorisations';
import { Profil } from 'src/app/api/models/class/profil';
import { IAutorisations } from 'src/app/api/models/interface/autorisations/autorisation-profil.interface';
import { IRight } from 'src/app/api/models/interface/autorisations/right.interface';
import { ITemplateHabilitation } from 'src/app/api/models/interface/autorisations/template-habilitation.interface';

@Injectable({
  providedIn: 'root'
})
export class ProfilManagementService {
  habilitations: IAutorisations = {
    AutorisationWeb: {
      FonctionsWeb: new Array<IRight>(),
      InformationsSensiblesWeb: new Array<IRight>()
    },
    AutorisationClientLourd: {
      TemplateHabilitations: new Array<ITemplateHabilitation>()
    }
  };
  profilForm: FormGroup = new FormGroup({});

  constructor() {}

  public initForm(
    profil: Profil = new Profil(),
    autorisations: ListAutorisations = new ListAutorisations()
  ): void {
    Object.entries(profil).map((field: [string, any]) => {
      if (this.profilForm.get(field[0])) {
        this.profilForm.get(field[0])?.setValue(field[1] === '' ? null : field[1]);
      } else {
        this.profilForm.addControl(field[0], new FormControl(field[1] === '' ? null : field[1]));
      }
    });
    if (this.profilForm.get('Autorisations')) {
      this.profilForm.get('Autorisations')?.setValue(autorisations);
    } else {
      this.profilForm.addControl('Autorisations', new FormControl(autorisations));
    }
  }

  public submitProfil(): void {}
}
