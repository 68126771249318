import { isUndefined } from 'is-what';
import { IToTreeNode } from '../../interface/to-tree-node.interface';
import { WaterpTreeNode } from '../../interface/waterp-tree-node.interface';
import { INiveauHabilitation } from '../../interface/autorisations/niveauHabilitation.interface';
import { IRight } from '../../interface/autorisations/right.interface';

export class Autorisation implements IToTreeNode {
  private name: string;
  private autorisationList?: Map<string, Autorisation[]>;
  private tooltip?: string;
  private leaf: boolean;
  private selectable: boolean = true;
  private code: string;
  private equivalent?: { Key: string; Value: string[] }[];

  /**
   * Permet d'instancier une autorisation issue du serveur en une version
   * compréhensible par l'interface
   * @param options Suite des paramètres à fournir pour la construction de l'objet
   * @param options.autorisation L'objet issue du serveur dont nous nous serviront pour créer l'objet
   * @param options.habilitationName Nom de remplacement de la combinaison Code + Libellé de l'autorisation
   * @param options.list Nom de la liste où l'autorisation doit être assignée
   * @param options.disabled Si nous souhaitons la prendre en compte mais sans la rendre sélectionnable
   */
  constructor(
    options?: {
      autorisation: IRight;
      habilitationName?: string;
      list?: string;
      code?: string;
    },
    niveauHabilitationRessource?: INiveauHabilitation
  ) {
    if (options) {
      if (niveauHabilitationRessource && options?.autorisation?.NiveauHabilitation) {
        const codeRessource = niveauHabilitationRessource.Code ?? 0;
        const codeHabilitation = options?.autorisation?.NiveauHabilitation.Code ?? 0;
        this.selectable = codeRessource >= codeHabilitation;
      }
      let libelleIHM: string =
        Object.entries(options.autorisation).find(
          (property: [string, any]) => property[0] === 'IHM'
        )?.[1].Libelle ?? '';

      this.name =
        options.habilitationName ??
        options.autorisation.Code +
          (options.autorisation.Libelle ? '~' + options.autorisation.Libelle : '') +
          (libelleIHM ? '~' + libelleIHM : '');
      this.code = options.code ?? options.autorisation.Code;
      this.tooltip = `${
        options.autorisation.Description ??
        options.autorisation.Commentaire ??
        options.autorisation.Libelle ??
        ''
      }${
        options.autorisation?.NiveauHabilitation
          ? ` - Niveau habilitation ${options.autorisation?.NiveauHabilitation.Code ?? '0'}`
          : ''
      }`;
      this.leaf = !options.habilitationName && !options.list;
      this.equivalent = options.autorisation.Equivalent;
      if (options.list) {
        this.autorisationList = new Map<string, Autorisation[]>().set(
          options.list,
          new Array<Autorisation>(
            new Autorisation({ autorisation: options.autorisation }, niveauHabilitationRessource)
          )
        );
      }
    } else {
      this.name = '';
      this.autorisationList = undefined;
      this.tooltip = undefined;
      this.leaf = false;
      this.selectable = true;
      this.code = '';
    }
  }

  //#region Accesseurs
  public getName(): string {
    return this.name;
  }

  public setName(name: string): void {
    this.name = name;
  }

  public getAutorisationList(): Map<string, Autorisation[]> | undefined {
    return this.autorisationList;
  }

  public setAutorisationList(autorisationList: Map<string, Autorisation[]>): void {
    this.autorisationList = autorisationList;
  }

  public getTooltip(): string {
    return this.tooltip ?? '';
  }

  public setTooltip(tooltip: string): void {
    this.tooltip = tooltip;
  }

  public isLeaf(): boolean {
    return this.leaf;
  }

  public setLeaf(isLeaf: boolean): void {
    this.leaf = isLeaf;
  }

  public isSelectable(): boolean {
    return this.selectable;
  }

  public setSelectable(selectable: boolean): void {
    this.selectable = selectable;
  }

  public getCode(): string {
    return this.code;
  }

  public setCode(code: string): void {
    this.code = code;
  }

  public getEquivalent(): { Key: string; Value: string[] }[] | undefined {
    return this.equivalent;
  }
  //#endregion

  /**
   * Permet d'obtenir la version de cet objet sans sa liste d'autorisation, permettant
   * d'assigner l'objet uniquement pour sa référence, sinon le tree node se retrouve
   * faussé dans sa sélection d'enfant unitaire
   * @returns "This" sans ses enfants
   */
  public withoutList(): Autorisation {
    let onlyThis: Autorisation = new Autorisation();
    onlyThis.name = this.name;
    onlyThis.tooltip = this.tooltip;
    onlyThis.leaf = this.leaf;
    onlyThis.code = this.code;
    onlyThis.equivalent = this.equivalent;
    onlyThis.selectable = this.selectable;
    return onlyThis;
  }

  /**
   * Permet de convertir cet objet en TreeNode
   * @returns La version TreeNode de cet objet
   */
  public toTreeNode(
    keyTable: number[],
    parent: WaterpTreeNode<Autorisation>,
    parentLevel: number
  ): WaterpTreeNode<Autorisation> {
    let branch: WaterpTreeNode<Autorisation> = {
      key: keyTable.join('-'),
      label: this.name,
      type: 'N-' + parentLevel,
      expanded: false,
      leaf: this.leaf,
      parent: parent,
      data: this.withoutList(),
      selectable: this.isSelectable()
    };

    if (this.tooltip) {
      branch.tooltip = {
        text: this.tooltip,
        delay: 500,
        disabled: isUndefined(this.tooltip),
        appendTo: 'body'
      };
    }

    if (!isUndefined(this.autorisationList) && this.autorisationList.size > 0) {
      branch.expanded = true;
      let level: number = parentLevel + 1;
      let childLevel: number = level + 1;

      this.autorisationList.forEach((values: Autorisation[], key: string) => {
        if (keyTable[level] >= 0) {
          keyTable[level]++;
        } else {
          keyTable.push(0);
        }
        keyTable.splice(childLevel);

        let keyTree: WaterpTreeNode<Autorisation> = {
          key: keyTable.join('-'),
          label: key,
          parent: branch,
          expanded: true,
          leaf: false,
          type: 'N-' + level,
          selectable: true
        };
        keyTree.children = values.map((autorisation: Autorisation, index: number) => {
          keyTable[childLevel] = index;
          return autorisation.toTreeNode(keyTable, keyTree, childLevel);
        });

        branch.children
          ? branch.children.push(keyTree)
          : (branch.children = new Array<WaterpTreeNode<Autorisation>>(keyTree));
      });
    }

    return branch;
  }

  /**
   * Permet d'ajouter un droit dans la liste d'autorisations de cet objet indexé par la liste fournie
   * @param params Formulation du droit et de la liste où le trouver
   */
  public set(params: { this: Autorisation; in: string }): void {
    if (this.autorisationList) {
      this.autorisationList?.has(params.in)
        ? this.autorisationList
            .get(params.in)
            ?.find((toAdd: Autorisation) => toAdd.getName() === params.this.getName()) ??
          this.autorisationList.get(params.in)?.push(params.this)
        : this.autorisationList.set(params.in, new Array<Autorisation>(params.this));
    } else {
      this.autorisationList = new Map<string, Autorisation[]>();
      this.autorisationList.set(params.in, new Array<Autorisation>(params.this));
    }
  }

  /**
   * Permet de supprimer un droit contenue dans la liste d'autorisations de cet objet
   * @param params Formulation du droit et de la liste où le trouver
   */
  public remove(params: { this: Autorisation; in: string }): void {
    if (this.has(params.this)) {
      if (this.autorisationList?.has(params.in)) {
        let length: number = this.autorisationList.get(params.in)?.length ?? 0;
        let index: number =
          this.autorisationList
            .get(params.in)
            ?.findIndex((toRemove: Autorisation) => toRemove.getName() === params.this.getName()) ??
          -1;

        if (index > -1) {
          length > 1
            ? this.autorisationList.get(params.in)?.splice(index, 1)
            : this.autorisationList.delete(params.in);
        }

        this.leaf = this.autorisationList.size === 0;
      }
    }
  }

  /**
   * Permet de vérifier si l'autorisation à examiner est dans l'une de nos listes de droits
   * ? Ne remonte pas dans quelle liste il a été trouvé
   * @param right Autorisation à examiner
   * @returns Vrai si c'est le cas, Faux sinon
   */
  public has(right: Autorisation): boolean {
    let hasRight: boolean = false;
    if (this.autorisationList) {
      this.autorisationList.forEach(
        (values: Autorisation[]) =>
          (hasRight =
            hasRight ||
            !isUndefined(
              values.find(
                (subAutorisation: Autorisation) => subAutorisation.getName() === right.getName()
              )
            ))
      );
    }
    return hasRight;
  }

  /**
   * Permet de trier les listes contenues dans l'ordre souhaité
   * @param ascend Trie dans l'ordre croissant/alphabétique si Vrai
   */
  public sort(ascend: boolean = true): void {
    this.autorisationList?.forEach((values: Autorisation[]) =>
      values.sort((a: Autorisation, b: Autorisation) =>
        ascend ? a.getName().localeCompare(b.getName()) : b.getName().localeCompare(a.getName())
      )
    );
  }

  /**
   * Lors de la sélection d'un Node on récupère les équivalents web de l'autorisation client lourd
   * @returns Les équivalents web
   */
  public doWhenSelect(): { Key: string; Value: string[] }[] {
    return this.equivalent ?? [];
  }

  /**
   * Lors de la désélection d'un Node on récupère les équivalents web de l'autorisation client lourd
   * @returns Les équivalents web
   */
  public doWhenUnselect(): { Key: string; Value: string[] }[] {
    return this.equivalent ?? [];
  }
}
