import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { InterventionByIdentifiantResponse } from 'src/app/api/models/response/intervention/InterventionByIdentifiantResponse';
import { InterventionService } from 'src/app/api/services/intervention.service';
import { AbonnementDataService } from './abonnement-data.service';
import {
  LibelleEtatIntervention,
  StatutInterventions
} from 'src/app/api/models/enum/Interventions.enum';
import { ApiResponseBodyUtils } from '../utils/apiResponseBodyUtils';
import { InterventionDataService } from './intervention-data.service';

@Injectable({
  providedIn: 'root'
})
export class InterventionNavigationService {
  constructor(
    private readonly abonnementDataService: AbonnementDataService,
    private readonly interventionService: InterventionService,
    private readonly interventionDataService: InterventionDataService
  ) {}

  public getActionInterventionWithLoad(identifiant: string, isFromRoute: boolean) {
    this.interventionDataService.setIsFromRoute(isFromRoute);
    this.interventionService.getInterventionByIdentifiant(identifiant).subscribe(r => {
      const intervention =
        ApiResponseBodyUtils.ExtractResponseBody<InterventionByIdentifiantResponse>(r);
      this.interventionDataService.setIntervention(intervention);
      this.getActionIntervention(intervention.Statut, identifiant);
    });
  }

  // get action selon état intervention
  public getActionIntervention(etat: string, identifiant: string) {
    switch (etat) {
      case StatutInterventions.Realise:
      case StatutInterventions.Annule:
        this.consulterIntervention(identifiant);
        return;
      case StatutInterventions.Aplanifier:
      case StatutInterventions.Aconfirmer:
      case StatutInterventions.Planifie:
        this.modifierIntervention(identifiant);
        return;
      default:
        return LibelleEtatIntervention.NonDefini;
    }
  }
  // Consulter Intervention
  private consulterIntervention(numIntervention: string) {
    // envoi id intervention a la consultation intervention
    this.abonnementDataService.setNumIntervention(numIntervention);
    // si la fenètre est déjà ouverte on la ferme
    this.openConsulter(false);
    this.openModifier(false);
    // ouvrir la fenètre
    this.openConsulter(true);
  }
  // Modifier Intervention
  private modifierIntervention(numIntervention: string) {
    //si on ouvre modif on force la fermeture de consult (1 seul à la fois c'est le même composant d'un point de vue utilisateur)
    this.openConsulter(false);
    this.openModifier(true);
    // envoi id intervention a la modification intervention
    this.abonnementDataService.setNumModifIntervention(numIntervention);
  }

  // Afficher modification intervention
  private openModifier(value: boolean) {
    this.abonnementDataService.setShowBouncingFrame('modifIntervention', value);
  }
  // Afficher consultation intervention
  private openConsulter(value: boolean) {
    this.abonnementDataService.setShowBouncingFrame('consultIntervention', value);
  }
}
