import { createFeatureSelector, createSelector } from '@ngrx/store';
import { InfosFinancieresState } from './infos-financieres.state';

export const selectInfosFinancieres =
  createFeatureSelector<InfosFinancieresState>('infosFinancieres');

export const selectNombreNonValeur = createSelector(
  selectInfosFinancieres,
  (state: InfosFinancieresState) => state.nombreNonValeur
);
export const selectNombreFacturesManuelles = createSelector(
  selectInfosFinancieres,
  (state: InfosFinancieresState) => state.nombreFacturesManuelles
);
