export enum ColumnType {
  TEXT = 'text',
  LIST = 'list',
  ROUTERLINK = 'routerLink',
  MONEY = 'money',
  CHIP = 'chip',
  CHECKBOX = 'checkbox',
  DATETIME = 'datetime',
  UPDATABLE = 'updatable',
  ICONS = 'icons'
}
