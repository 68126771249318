import { PagerPosition, PagerSettings } from '@progress/kendo-angular-grid';
import { pluralize } from '../../utils/string-pluralizer';

export type PageSize = number | null | undefined;
export type PageSizes = boolean | number[] | undefined;

/**
 * Modèle interne de la définition des paginations 'Kendo'
 * ? https://www.telerik.com/kendo-angular-ui/components/grid/api/PagerSettings/
 */
export class Pagination implements PagerSettings {
  /** @var alwaysVisible Permet d'indiquer si la pagination doit s'afficher en dépend du contenu ou si elle doit tout le temps apparaître */
  private alwaysVisible: boolean;
  /** @var numeric Indique si les numéros de page doivent s'afficher */
  private numeric: boolean;
  /** @var pageSize Indique le nombre d'éléments par page */
  private pageSize: PageSize;
  /** @var refresh Indique si le bouton permettant de rafraîchir la sélection doit s'afficher */
  private refresh: boolean;
  /** @var messages Groupe de messages d'informations de la pagination */
  private messages: {
    page: string;
    of: string;
    label: string;
    items: string;
    itemsPerPage: string;
    first: string;
    last: string;
    next: string;
    previous: string;
  };

  /** @var buttonCount Indique le nombre de lien de pages qui s'afficheront (ex: si la valeur est à 10 mais qu'il y a 20 page, les pages 11 à 20 seront remplacées par '...' c'est-à-dire 'Plus de pages...') */
  public buttonCount: number;
  /** @var info Affiche ou non les informations permettant d'indiquer la sélection que l'utilisateur est en train de consulter (sur la quantité total d'éléments) */
  public info: boolean;
  /** @var input Indique si le champ permettant de se rendre sur une page spécifique doit s'afficher */
  public input: boolean;
  /** @var pageSizes Indique les différentes largeur de sélection possibles */
  public pageSizes: PageSizes;
  /** @var position Indique si la pagination se situe au dessus ou en dessous de la sélection */
  public position: PagerPosition;
  /** @var previousNext Indique si les flèches permettant de naviguer dans les pages doivent s'afficher */
  public previousNext: boolean;
  /** @var responsive Indique si le tableau doit adopter un comportement responsif ou non */
  public responsive: boolean;

  /**
   * Constructeur de pagination 'Kendo'
   * @param typeName Nom du type des éléments à destination des messages
   * @param pageSize Taille actuelle de la sélection à afficher
   * @param pageSizes Différentes tailles disponible
   * @param position Permet d'afficher la pagination en dessous (par défaut) ou au dessus de la sélection à afficher
   * @param buttonCount Nombre de pages affichable avant d'utiliser l'ellipsis
   * @param alwaysVisible Permet de tout le temps afficher la sélection, ou de laisser le composant gérer son affichage (par défaut)
   */
  constructor(
    typeName: string = 'élément',
    pageSize: PageSize = 10,
    pageSizes: PageSizes = [5, 10, 25, 50],
    position: PagerPosition = 'bottom',
    buttonCount: number = 10,
    alwaysVisible: boolean = false
  ) {
    this.pageSize = pageSize;
    this.pageSizes = pageSizes;
    this.buttonCount = buttonCount;
    this.alwaysVisible = alwaysVisible;
    this.messages = {
      page: 'Page',
      of: 'sur',
      label: 'Page {currentPage} sur {totalPages} ' + pluralize(typeName),
      items: pluralize(typeName),
      itemsPerPage: pluralize(typeName[0].toUpperCase() + typeName.substring(1)) + ' par pages',
      first: 'Aller à la première page',
      last: 'Aller à la dernière page',
      next: 'Prochaine page',
      previous: 'Page précédente'
    };
    this.position = position;
    this.info = true;
    this.input = true;
    this.numeric = true;
    this.previousNext = true;
    this.refresh = false;
    this.responsive = true;
  }

  //#region Accesseurs
  public isAlwaysVisible(): boolean {
    return this.alwaysVisible;
  }

  public setAlwaysVisible(alwaysVisible: boolean): void {
    this.alwaysVisible = alwaysVisible;
  }

  public isNumeric(): boolean {
    return this.numeric;
  }

  public setNumeric(numeric: boolean): void {
    this.numeric = numeric;
  }

  public getPageSize(): number {
    return (this.pageSize as number) ?? 0;
  }

  public setPageSize(pageSize: PageSize): void {
    this.pageSize = pageSize;
  }

  public isRefresh(): boolean {
    return this.refresh;
  }

  public setRefresh(refresh: boolean): void {
    this.refresh = refresh;
  }

  public getMessage(
    property:
      | 'page'
      | 'of'
      | 'label'
      | 'items'
      | 'itemsPerPage'
      | 'first'
      | 'last'
      | 'next'
      | 'previous'
  ): string {
    return this.messages[property];
  }

  public getMessages(): {
    page: string;
    of: string;
    label: string;
    items: string;
    itemsPerPage: string;
    first: string;
    last: string;
    next: string;
    previous: string;
  } {
    return this.messages;
  }

  public setMessage(
    property:
      | 'page'
      | 'of'
      | 'label'
      | 'items'
      | 'itemsPerPage'
      | 'first'
      | 'last'
      | 'next'
      | 'previous',
    message: string
  ): void {
    this.messages[property] = message;
  }
  //#endregion
}
