//#region Import
import { CommonModule } from '@angular/common';
import { ExcelExportEvent, ExcelModule, GridModule } from '@progress/kendo-angular-grid';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { TooltipModule } from 'primeng/tooltip';
//#endregion

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { format } from 'date-fns';
import { isUndefined } from 'is-what';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { NgVarDirective } from '../../directives/ng-var.directive';
import { Table } from '../../models/tables/tables';
import { ConvertService } from '../../services/convert.service';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'waterp-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  imports: [
    CommonModule,
    FormsModule,
    GridModule,
    ExcelModule,
    IndicatorsModule,
    TooltipModule,
    ChipModule,
    CheckboxModule,
    FontAwesomeModule,
    OverlayPanelModule,
    NgVarDirective,
    RouterLink
  ],
  standalone: true
})
/**
 * ! COMPOSANT ENFANT
 *  Permet de réunir les caractéristiques par défauts utilisés
 * dans les différents développements de tableau de la SOMEI
 */
export class TableComponent {
  /** @var table Tableau créé dans le composant parent appelant ce composant */
  @Input() table!: Table<any>;
  /** @var rowSelected Émetteur des lignes sélectionnées */
  @Output() rowsSelected: EventEmitter<any> = new EventEmitter<any>();

  constructor(public convertService: ConvertService) {}

  /**
   * Récupère les éléments sélectionnés et émet ces derniers au composant parent
   * @param fieldIndexes Éléments sélectionnés
   */
  public getDatasToEmit(indexesSelected: any[]): void {
    this.rowsSelected.emit(
      isUndefined(this.table.getLinesSelectable()?.field)
        ? this.table
            .getDatas()
            .filter((data: any, index: number) => indexesSelected.includes(index))
        : indexesSelected
    );
  }

  /**
   * Permet de configurer l'entête du excel exporté
   * @param exportEvent Événement d'émission de l'export excel
   */
  public setExportTemplate(exportEvent: ExcelExportEvent): void {
    const sheet = exportEvent?.workbook?.sheets[0];
    sheet.frozenRows = 2;
    sheet.mergedCells = [this.table.getMergeRangeForExcelHeader()]; // Centrage sur plusieurs cellules
    sheet.name = this.table.getExportable()?.customHeader; // Nom du sheet
    let entete = [
      {
        value:
          format(new Date(), 'dd/MM/yyyy HH:mm') +
          (this.table.getExportable()?.customHeader &&
          this.table.getExportable()?.customHeader !== ''
            ? ' - ' + this.table.getExportable()?.customHeader
            : ''),
        textAlign: 'center',
        background: '#23B7E5',
        color: '#ffffff',
        fontSize: 20
      }
    ];
    sheet.rows.splice(0, 0, { cells: entete, type: 'header', height: 30 });
  }
}
