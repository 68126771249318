import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { EnumCodeModePaiement } from 'src/app/api/models/enum/CodeModePaiement.enum';
import { InformationsPaiementDto } from 'src/app/api/models/interface/InformationsForProcessDto';
import { ParametrageGeneriqueService } from 'src/app/api/services/parametrageGenerique.service';
import { AppResource } from 'src/app/app.resource';
import { Resource } from 'src/app/resources/resource';
import { Data } from 'src/app/shared/models/reference-data.model';
import { FormConfiguration } from 'src/app/shared/types/form-configuration.type';
import { getDataFromLocaleStorage } from 'src/app/shared/utils/localStorageUtils';
import { FormGroup } from '@angular/forms';
import { ReferenceBancaireService } from 'src/app/api/services/referenceBancaire.service';
import { GeneriqueResponse } from 'src/app/api/models/shared/generiqueResponse';
import { formatDateUnixToNumber } from 'src/app/shared/utils/dateUnixUtils';

@Injectable({
  providedIn: 'root'
})
export class FormBankingReferencesService {
  private _resource: Resource;

  private _basicModes: EnumCodeModePaiement[] = [
    EnumCodeModePaiement.Mensualisation,
    EnumCodeModePaiement.Prelevement,
    EnumCodeModePaiement.Tip
  ];
  private _automaticModes: EnumCodeModePaiement[] = [
    EnumCodeModePaiement.Mensualisation,
    EnumCodeModePaiement.Prelevement
  ];
  private _creditCardModes: EnumCodeModePaiement[] = [
    EnumCodeModePaiement.MensualisationCB,
    EnumCodeModePaiement.PrelevementCB
  ];
  private _monthlyModes: EnumCodeModePaiement[] = [
    EnumCodeModePaiement.Mensualisation,
    EnumCodeModePaiement.MensualisationCB
  ];
  private _tipModes: EnumCodeModePaiement[] = [
    EnumCodeModePaiement.Tip,
    EnumCodeModePaiement.TipSansRib
  ];

  private _requiredFields: FormConfiguration<EnumCodeModePaiement> = {};
  private _newAvaiableRUMNumber!: string;
  private _newAvaiableRUMDate!: Date;

  constructor(
    _resources: AppResource,
    _referenceBancaireService: ReferenceBancaireService,
    private _parametrageGeneriqueService: ParametrageGeneriqueService
  ) {
    this._resource = _resources['resource'];
    _referenceBancaireService.getRum().subscribe((response: GeneriqueResponse) => {
      this._newAvaiableRUMNumber = response.Result?.NumeroRum;
      this._newAvaiableRUMDate = new Date();
    });
  }

  /**
   * Permet de récupérer les modes de paiement de la BDD
   * @returns Les modes de paiement existants
   */
  public getPaymentModes(): Observable<Data[]> {
    return getDataFromLocaleStorage(
      this._resource.refDataManager.modePaiement,
      this._parametrageGeneriqueService
    ).pipe(
      map((values: Data[]) =>
        values
          .map(modePaiement => {
            modePaiement.disabled = this.isCreditCardModes(modePaiement.Key);
            return modePaiement;
          })
          .sort((a: Data, b: Data) => a.Value.localeCompare(b.Value))
      )
    );
  }

  /**
   * Permet de récupérer les canaux d'acquisition de la BDD
   * @returns Les canaux d'acquisition existants
   */
  public getAcquisitionChannel(): Observable<Data[]> {
    return getDataFromLocaleStorage(
      this._resource.refDataManager.canalAcquisitionSepa,
      this._parametrageGeneriqueService
    );
  }

  /**
   * Récupère la configuration des champs du formulaire des références bancaires
   * @returns La configuration du formulaire
   */
  public getRequiredFields(): FormConfiguration<EnumCodeModePaiement> {
    return this._requiredFields;
  }

  /**
   * Définie la configuration des différents champs relatifs au formulaire des
   * Références Bancaires.
   * Pour chacun on viendra déterminer :
   *  - Leur valeur par défaut
   *  - Leur condition s'ils sont requis ou non (et affichés)
   * @param mode Mode de paiement récupéré de la BDD
   * @param paiement informations concernant le mode de paiement de l'abonnement
   */
  public setRequiredFields(mode: EnumCodeModePaiement, paiement?: InformationsPaiementDto): void {
    this._requiredFields = {
      paymentMethod: {
        defaultValue: mode ?? EnumCodeModePaiement.Mensualisation,
        required: () => true
      },
      endValidity: {
        defaultValue: paiement?.DateValiditeCB as Date,
        required: this.isCreditCardModes.bind(this)
      },
      collectDay: {
        defaultValue: paiement?.JourDePrelevement,
        required: this.isMonthlyModes.bind(this)
      },
      consentType: {
        defaultValue: paiement?.ReferenceBancaire?.CanalAcquisitionSepa?.Code ?? 'T',
        required: this.isBasicModes.bind(this)
      },
      radioBtnInternet: {
        defaultValue: null,
        required: this.isBasicModes.bind(this)
      },
      owner: {
        defaultValue: paiement?.ReferenceBancaire?.Titulaire ?? '',
        required: this.isBasicModes.bind(this)
      },
      iban: {
        defaultValue: paiement?.ReferenceBancaire?.CodeIban ?? '',
        required: this.isBasicModes.bind(this)
      },
      domiciliation: {
        defaultValue: paiement?.ReferenceBancaire?.Domiciliation,
        required: this.isBasicModes.bind(this)
      },
      bic: {
        defaultValue: paiement?.ReferenceBancaire?.CodeBic,
        required: this.isBasicModes.bind(this)
      },
      rumNumber: {
        defaultValue: paiement?.ReferenceBancaire?.NumeroRum,
        required: this.isAutomaticModes.bind(this)
      },
      rumDate: {
        defaultValue: paiement?.ReferenceBancaire?.DateRum,
        required: this.isAutomaticModes.bind(this)
      }
    };
  }

  /**
   * Permet de vérifier si le paramètre est un des type de mode de paiment de ce groupe
   * @Types
   * * Mensualisation
   * * Prelevement
   * * Tip
   * @param value Valeur à tester
   * @returns Vrai si c'est une valeur de ce groupe, faux sinon
   */
  public isBasicModes(value: string): boolean {
    return this._basicModes.includes(value as EnumCodeModePaiement);
  }

  /**
   * Permet de vérifier si le paramètre est un des type de mode de paiment de ce groupe
   * @Types
   * * Mensualisation
   * * Prelevement
   * @param value Valeur à tester
   * @returns Vrai si c'est une valeur de ce groupe, faux sinon
   */
  public isAutomaticModes(value: string): boolean {
    return this._automaticModes.includes(value as EnumCodeModePaiement);
  }

  /**
   * Permet de vérifier si le paramètre est un des type de mode de paiment de ce groupe
   * @Types
   * * MensualisationCB
   * * PrelevementCB
   * @param value Valeur à tester
   * @returns Vrai si c'est une valeur de ce groupe, faux sinon
   */
  public isCreditCardModes(value: string): boolean {
    return this._creditCardModes.includes(value as EnumCodeModePaiement);
  }

  /**
   * Permet de vérifier si le paramètre est un des type de mode de paiment de ce groupe
   * @Types
   * * Mensualisation
   * * MensualisationCB
   * @param value Valeur à tester
   * @returns Vrai si c'est une valeur de ce groupe, faux sinon
   */
  public isMonthlyModes(value: string): boolean {
    return this._monthlyModes.includes(value as EnumCodeModePaiement);
  }

  /**
   * Permet de vérifier si le paramètre est un des type de mode de paiment de ce groupe
   * @Types
   * * Tip
   * * TipSansRib
   * @param value Valeur à tester
   * @returns Vrai si c'est une valeur de ce groupe, faux sinon
   */
  public isTipModes(value: string): boolean {
    return this._tipModes.includes(value as EnumCodeModePaiement);
  }

  /**
   * Assigne un nouveau numéro RUM avec condition.
   * @param form Le formulaire des références bancaires assujetie au changement.
   * @param informationsBancaires Correspond aux données assignées dans le formulaire avant les potentielles modifications.
   * @returns Un tableau de deux booléens :
   *          - Le premier indique si un nouveau numéro RUM a été assigné.
   *          - Le second indique si un numéro RUM est nécessaire.
   */
  public assignNewRUMNumberWithCondition(
    form: FormGroup,
    informationsBancaires: InformationsPaiementDto
  ): void {
    let assigneNewRUM: boolean = false;
    let needRUMNumber: boolean = false;
    // si on est en mode M ou P, RUM is needed
    if ((needRUMNumber = this.isAutomaticModes(form.get('paymentMethod')?.value.Key))) {
      // Si on change d'un mode qui n'est pas Mensualisation ou Prelevement vers M ou P, on assigne un nouveau code RUM
      assigneNewRUM = !this.isAutomaticModes(informationsBancaires?.CodeModePaiement ?? '');
    }

    // Si le titulaire change, on assigne un nouveau RUM
    assigneNewRUM =
      form.get('owner')?.value !== informationsBancaires?.ReferenceBancaire?.Titulaire;

    if (needRUMNumber && !assigneNewRUM) {
      const existingRUM = informationsBancaires?.ReferenceBancaire?.NumeroRum;
      if (existingRUM) {
        form.patchValue({
          rumNumber: existingRUM,
          rumDate: informationsBancaires?.ReferenceBancaire?.DateRum
        });
      } else {
        form.patchValue({
          rumNumber: this._newAvaiableRUMNumber,
          rumDate: this._newAvaiableRUMDate
        });
      }
    } else if (assigneNewRUM) {
      form.patchValue({
        rumNumber: this._newAvaiableRUMNumber,
        rumDate: this._newAvaiableRUMDate
      });
    }
  }
}
