<div [ngClass]="{ 'w-full flex justify-center items-center': isAStep() }">
  <app-loader *ngIf="isFormLoading"></app-loader>
  <form
    *ngIf="bankReferencesForm && !isFormLoading"
    [ngClass]="{ 'w-1/2': isAStep() }"
    [formGroup]="bankReferencesForm"
    (ngSubmit)="submit()">
    <div class="grid grid-cols-12 gap-y-2 p-4">
      <!-- #region HEAD -->
      <div class="col-span-full grid grid-cols-6 gap-5">
        <!-- #region MODE DE PAIEMENT -->
        <div class="flex flex-col col-span-3">
          <label for="paymentModes">{{
            getResource().GlobalTexts.Abonnement.BankingReferences.Interface.Fields.paymentMode
          }}</label>
          <p-dropdown
            inputId="paymentModes"
            appendTo="body"
            [options]="paymentModes"
            optionLabel="Value"
            optionValue="Key"
            optionDisabled="disabled"
            formControlName="paymentMethod"></p-dropdown>
          <div class="h-4">
            <small
              class="p-error"
              *ngIf="(getField('form', 'paymentMethod')?.dirty || getField('form', 'paymentMethod')?.touched) && getField('form', 'paymentMethod')?.invalid && getField('form', 'paymentMethod')?.errors?.['required']">
              {{ getResource().message.errorMandatory }}
            </small>
          </div>
        </div>
        <!-- #endregion -->
        <!-- #region FIN DE VALIDITÉ -->
        <div>
          <div
            class="flex flex-col"
            *ngIf="
              getField('service', 'endValidity').required(getField('form', 'paymentMethod')?.value)
            ">
            <label for="endValidity">{{
              getResource().GlobalTexts.Abonnement.BankingReferences.Interface.Fields.endValidity
            }}</label>
            <p-calendar inputId="endValidity" formControlName="endValidity"></p-calendar>
            <div class="h-4">
              <small
                class="p-error"
                *ngIf="(getField('form', 'endValidity')?.dirty || getField('form', 'endValidity')?.touched) && getField('form', 'endValidity')?.invalid && getField('form', 'endValidity')?.errors?.['required']">
                {{ getResource().message.errorMandatory }}
              </small>
            </div>
          </div>
        </div>
        <!-- #endregion -->
        <!-- #region Jour de prélévement -->
        <div>
          <div
            class="flex flex-col"
            *ngIf="
              getField('service', 'collectDay').required(getField('form', 'paymentMethod')?.value)
            ">
            <label for="collectDay">{{
              getResource().GlobalTexts.Abonnement.BankingReferences.Interface.Fields.collectDay
            }}</label>
            <p-inputNumber
              inputId="collectDay"
              [placeholder]="
                getResource().GlobalTexts.Abonnement.BankingReferences.Interface.Fields
                  .collectDayPlaceHolder
              "
              class="waterp-inputnumber"
              [min]="1"
              [max]="28"
              formControlName="collectDay"></p-inputNumber>
            <div class="h-4">
              <small
                class="p-error"
                *ngIf="(getField('form', 'collectDay')?.dirty || getField('form', 'collectDay')?.touched) && getField('form', 'collectDay')?.invalid && getField('form', 'collectDay')?.errors?.['required']">
                {{ getResource().message.errorMandatory }}
              </small>
            </div>
          </div>
        </div>
        <!-- #endregion -->
        <div *ngIf="!isAStep()" class="flex justify-end items-center">
          <button type="button" class="rounded px-3 py-2 bg-primary-700" (click)="showHistoric()">
            <div class="flex justify-around">
              <fa-icon [icon]="faHistory"></fa-icon>
              <span>{{
                getResource().GlobalTexts.Abonnement.BankingReferences.Interface.Buttons.historic
              }}</span>
            </div>
          </button>
        </div>
      </div>
      <!-- #endregion -->
      <div
        *ngIf="
          getField('service', 'consentType').required(getField('form', 'paymentMethod')?.value) ||
          getField('service', 'owner').required(getField('form', 'paymentMethod')?.value)
        "
        class="col-span-full grid grid-cols-2 gap-5">
        <!-- #region CANAL D'ACQUISITION -->
        <div
          *ngIf="
            getField('service', 'consentType').required(getField('form', 'paymentMethod')?.value)
          "
          class="flex flex-col">
          <label for="acquisition">{{
            getResource().GlobalTexts.Abonnement.BankingReferences.Interface.Fields.consentType
          }}</label>
          <div class="field-checkbox flex justify-start items-center gap-5">
            <ng-container *ngFor="let channel of acquistionChannel">
              <p-radioButton
                *ngVar="
                  channel.Key ===
                  getResource().Constants.Financier.CanalAcquisitionSepa.INTERNET as isInternet
                "
                inputId="acquisition"
                [label]="channel.Value.toUpperCase()"
                [value]="channel.Key"
                [formControlName]="isInternet ? 'radioBtnInternet' : 'consentType'"></p-radioButton>
            </ng-container>
          </div>
          <div class="h-4">
            <small
              class="p-error"
              *ngIf="(getField('form', 'consentType')?.dirty || getField('form', 'consentType')?.touched) && getField('form', 'consentType')?.invalid && getField('form', 'consentType')?.errors?.['required']">
              {{ getResource().message.errorMandatory }}
            </small>
          </div>
        </div>
        <!-- #endregion -->
        <!-- #region PROPRIÉTAIRE -->
        <div
          *ngIf="getField('service', 'owner').required(getField('form', 'paymentMethod')?.value)"
          class="flex flex-col">
          <label for="owner">{{
            getResource().GlobalTexts.Abonnement.BankingReferences.Interface.Fields.owner
          }}</label>
          <input
            inputId="owner"
            type="text"
            pInputText
            pKeyFilter="'alpha'"
            formControlName="owner" />
          <div class="h-4">
            <small
              class="p-error"
              *ngIf="(getField('form', 'owner')?.dirty || getField('form', 'owner')?.touched) && getField('form', 'owner')?.invalid && getField('form', 'owner')?.errors?.['required']">
              {{ getResource().message.errorMandatory }}
            </small>
          </div>
        </div>
        <!-- #endregion -->
      </div>
      <!-- #region IBAN -->
      <div
        *ngIf="getField('service', 'iban').required(getField('form', 'paymentMethod')?.value)"
        class="col-span-11 flex justify-between items-center">
        <waterp-iban-control [ibanControl]="iban"></waterp-iban-control>
      </div>
      <div class="w-full flex justify-center items-center">
        <ng-container *ngVar="ibanIndicator.getIcon() as icon">
          <fa-icon
            *ngIf="ibanIndicator.runCondition() && icon !== undefined"
            [icon]="icon"
            [class]="ibanIndicator.getClass()"
            [spin]="ibanIndicator.isLoading()"
            size="2xl">
          </fa-icon>
        </ng-container>
      </div>
      <!-- #endregion -->
      <div
        *ngIf="
          getField('service', 'domiciliation').required(getField('form', 'paymentMethod')?.value) ||
          getField('service', 'bic').required(getField('form', 'paymentMethod')?.value)
        "
        class="col-span-11 grid grid-cols-3 gap-5">
        <!-- #region DOMICILIATION -->
        <div
          *ngIf="
            getField('service', 'domiciliation').required(getField('form', 'paymentMethod')?.value)
          "
          class="flex flex-col col-span-2">
          <label for="domiciliation">{{
            getResource().GlobalTexts.Abonnement.BankingReferences.Interface.Fields.Address
          }}</label>
          <input inputId="domiciliation" type="text" pInputText formControlName="domiciliation" />
          <div class="h-4">
            <small
              class="p-error"
              *ngIf="(getField('form', 'domiciliation')?.dirty || getField('form', 'domiciliation')?.touched) && getField('form', 'domiciliation')?.invalid && getField('form', 'domiciliation')?.errors?.['required']">
              {{ getResource().message.errorMandatory }}
            </small>
          </div>
        </div>
        <!-- #endregion -->
        <!-- #region BIC -->
        <div
          *ngIf="getField('service', 'bic').required(getField('form', 'paymentMethod')?.value)"
          class="flex flex-col">
          <label for="bic">{{
            getResource().GlobalTexts.Abonnement.BankingReferences.Interface.Fields.bic
          }}</label>
          <div class="flex items-center gap-5">
            <input inputId="bic" type="text" pInputText formControlName="bic" />
          </div>
          <div class="h-4">
            <small
              class="p-error"
              *ngIf="(getField('form', 'bic')?.dirty || getField('form', 'bic')?.touched) && getField('form', 'bic')?.invalid && getField('form', 'bic')?.errors?.['required']">
              {{ getResource().message.errorMandatory }}
            </small>
          </div>
        </div>
        <!-- #endregion -->
      </div>
      <div class="w-full flex justify-center items-center">
        <ng-container *ngVar="bicIndicator.getIcon() as icon">
          <fa-icon
            *ngIf="bicIndicator.runCondition() && icon !== undefined"
            [icon]="icon"
            [class]="bicIndicator.getClass()"
            [spin]="bicIndicator.isLoading()"
            size="2xl">
          </fa-icon>
        </ng-container>
      </div>
      <div
        *ngIf="
          getField('service', 'rumNumber').required(getField('form', 'paymentMethod')?.value) ||
          getField('service', 'rumDate').required(getField('form', 'paymentMethod')?.value)
        "
        class="col-span-full grid grid-cols-4 pb-2 gap-5">
        <!-- #region RUM -->
        <div
          *ngIf="
            getField('service', 'rumNumber').required(getField('form', 'paymentMethod')?.value)
          "
          class="flex flex-col">
          <label for="rumNumber">{{
            getResource().GlobalTexts.Abonnement.BankingReferences.Interface.Fields.rumNumber
          }}</label>
          <input inputId="rumNumber" type="text" pInputText formControlName="rumNumber" />
          <div class="h-4">
            <small
              class="p-error"
              *ngIf="(getField('form', 'rumNumber')?.dirty || getField('form', 'rumNumber')?.touched) && getField('form', 'rumNumber')?.invalid && getField('form', 'rumNumber')?.errors?.['required']">
              {{ getResource().message.errorMandatory }}
            </small>
          </div>
        </div>
        <div
          *ngIf="getField('service', 'rumDate').required(getField('form', 'paymentMethod')?.value)"
          class="flex flex-col">
          <label for="rumDate">{{
            getResource().GlobalTexts.Abonnement.BankingReferences.Interface.Fields.rumDate
          }}</label>
          <p-calendar inputId="rumDate" formControlName="rumDate"></p-calendar>
          <div class="h-4">
            <small
              class="p-error"
              *ngIf="(getField('form', 'rumDate')?.dirty || getField('form', 'rumDate')?.touched) && getField('form', 'rumDate')?.invalid && getField('form', 'rumDate')?.errors?.['required']">
              {{ getResource().message.errorMandatory }}
            </small>
          </div>
        </div>
        <!-- #endregion -->
      </div>
      <!-- #region FLUSHER -->
      <div
        *ngIf="getFormService().isBasicModes(getField('form', 'paymentMethod')?.value)"
        class="col-span-full flex justify-start items-center">
        <button type="reset" class="rounded px-3 py-2 bg-primary-700" (click)="onFlushingFields()">
          <div class="flex gap-2">
            <i class="pi pi-trash"></i>
            <span>{{
              getResource().GlobalTexts.Abonnement.BankingReferences.Interface.Buttons.flusher
            }}</span>
          </div>
        </button>
      </div>
      <!-- #endregion -->
    </div>
    <div
      class="flex justify-end rounded-b-md p-2 gap-2"
      [ngClass]="{ 'bg-primary-50': !isAStep() }"
      *ngIf="!isFormLoading">
      <button type="button" class="button-cancel" (click)="onFlushingFields(true)">
        {{ getResource().GlobalTexts.Generic.Cancel }}
      </button>
      <ng-container *ngTemplateOutlet="validationButton"></ng-container>
    </div>
  </form>
</div>

<ng-template #validationButton>
  <div *ngIf="isAStep()" class="flex justify-center items-center">
    <button type="button" class="button-cancel flex" (click)="returnPreviousForm()">
      <img class="py-0.5 pr-1" [src]="getResource().svgPath.west" alt="westSVG" />
      {{ getResource().GlobalTexts.Generic.Previous }}
    </button>
    <button type="button" class="button-submit flex" (click)="submit()">
      {{ getResource().GlobalTexts.Generic.Next }}
      <img class="py-0.5 pl-1" [src]="getResource().svgPath.east" alt="eastSvg" />
    </button>
  </div>
  <div *ngIf="!isAStep()" class="flex justify-center items-center">
    <button
      type="submit"
      class="button-submit"
      [disabled]="isLoadingButton"
      [pTooltip]="getResource().GlobalTexts.Auth.Function_Need_Auth"
      [tooltipDisabled]="!isUserAllowed()"
      tooltipPosition="bottom">
      <fa-icon [icon]="faSave" *ngIf="!isLoadingButton"></fa-icon>
      <i class="spinner-icon waterpIcons-Spinner mt-1" *ngIf="isLoadingButton"></i>
      {{ getResource().GlobalTexts.Generic.Save }}
    </button>
  </div>
</ng-template>
