import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromRoot from 'src/app/core/state/core.state';
import * as fromHeader from 'src/app/core/state/header';
import { Observable, tap } from 'rxjs';
import { AbonnementService } from 'src/app/api/services/abonnement.service';
import { InformationsPaiementResponse } from 'src/app/api/models/class/informationsPaiementResponse';

@Injectable({
  providedIn: 'root'
})
export class GetPaymentInformationsResolver implements Resolve<InformationsPaiementResponse> {
  constructor(private store: Store<fromRoot.State>, private abonnementService: AbonnementService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<InformationsPaiementResponse> {
    return this.abonnementService
      .getFormattedInformationsPaiement(route.params['numAbonnement'])
      .pipe(
        tap((paymentInformations: InformationsPaiementResponse) => {
          this.store.dispatch(
            fromHeader.updateIndicateurPaiementSur({
              updatedIndicateurPaiementSur: paymentInformations.IndicateurPaiementSur ?? false
            })
          );
        })
      );
  }
}
