import { ApiResponseBodyUtils } from './apiResponseBodyUtils';
import { Data } from '../models/reference-data.model';
import { lastValueFrom, map, Observable, of } from 'rxjs';
import { ParametrageGeneriqueService } from 'src/app/api/services/parametrageGenerique.service';
import { KeyValueResponse } from 'src/app/api/models/response/key-value/keyValueResponse';
import { RefDataManagerService } from 'src/app/api/services/refDataManager.service';
import { GeneriqueResponse } from 'src/app/api/models/shared/generiqueResponse';
import { Flag } from 'src/app/api/models/enum/flag.enum';

//#region LocalStorage
export function getItemLocalStorage(key: string) {
  return localStorage.getItem(key);
}

export function getItemLocalStorageJson(key: string) {
  return JSON.parse(localStorage.getItem(key)!);
}

export function setItemLocalStorage(key: string, value: string) {
  localStorage.setItem(key, value);
}

export function setItemLocalStorageJson(key: string, value: any) {
  localStorage.setItem(key, JSON.stringify(value));
}

export function removeItemLocalStorage(key: string) {
  localStorage.removeItem(key);
}
//#endregion

export function getDataFromLocaleStorage(
  key: string,
  parametrageGeneriqueService: ParametrageGeneriqueService,
  rewrite: boolean = false
): Observable<Data[]> {
  let data: Data[] = getItemLocalStorageJson(key);

  if ((!data && !rewrite) || rewrite) {
    return parametrageGeneriqueService
      .getAllList(key)
      .pipe(
        map((response: GeneriqueResponse) =>
          ApiResponseBodyUtils.ExtractArrayResponseBody<KeyValueResponse>(response)
        )
      )
      .pipe(
        map((datas: KeyValueResponse[]) => {
          data =
            datas
              ?.filter((data: KeyValueResponse) => data['IsActive'] !== Flag.INACTIVE)
              .map((data: KeyValueResponse) => ({
                Key: data.Code ?? data['Identifiant'],
                Value: data.Libelle ?? data['LibelleTypeHabitation']
              }))
              .sort((a: Data, b: Data) => a.Value.localeCompare(b.Value)) ?? new Array<Data>();
          setItemLocalStorageJson(key, data);
          return data;
        })
      );
  }

  return of(data);
}

//Les chargements se font au fil de l'eau, l'appel à cette fonction
//est déprécié parce que ça DDOS le serveur
export async function loadRefDataManager(
  parametrageGeneriqueService: ParametrageGeneriqueService,
  refDataManagerService: RefDataManagerService
) {
  const refData = refDataManagerService.getData();

  refData.forEach(res => {
    getDataFromLocaleStorage(res, parametrageGeneriqueService, true);
  });
}
