import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Option } from './option';
import { KeyValuePair } from './reference-data.model';
import { RegexBank } from '../utils/regex-bank';
import { map } from 'rxjs';

/**
 * ! DÉJÀ DÉCLARÉ PAR LE COMPOSANT IbanControlComponent, utiliser l'Output mis à disposition pour fournir votre formulaire
 * Modèle de gestion du composant IbanControl
 */
export class IbanControl extends FormGroup {
  private _countriesOptions?: Option<string, number>[];
  private _shortestIbanConfig: number;
  private _selectedIbanConfig?: KeyValuePair<string, number>;
  private _valid: boolean;
  private _disabled: boolean;
  public onChangeCountry: (country?: string) => any = () => null;

  /**
   * ? https://www.iban.fr/structure.html
   *
   * Constructeur du champ Iban
   * @param value Permet de potentiellement déclarer une valeur au champ Iban lors de sa construction
   */
  constructor(
    countriesOptions: Option<string, number>[],
    value?: string,
    disabled: boolean = false
  ) {
    super({
      country: new FormControl<string>(
        {
          value:
            countriesOptions
              .find((option: Option<string, number>) => option.getKey() === 'FR')
              ?.getKey() ?? '',
          disabled: disabled
        },
        !disabled ? Validators.required : null
      ),
      key: new FormControl<string>(
        { value: '76', disabled: disabled },
        !disabled ? [Validators.required, Validators.min(2)] : null
      )
    });
    this._disabled = disabled;
    this._valid = false;
    this._countriesOptions = countriesOptions ?? [];
    this._shortestIbanConfig = Math.min(
      ...this._countriesOptions?.map((config: Option<string, number>) => config.getValue())
    );

    this.set(value);
    this.updateValueAndValidity({ emitEvent: false });
  }

  //#region Overriden
  /**
   * Traitement permettant de traiter le lot de control comme 1 seul
   * @param value Valeur globale de l'IBAN
   * @param options options permettant de gérer le champ
   */
  public set(
    value?: string,
    options?: { key?: string; onlySelf?: boolean; emitEvent?: boolean }
  ): void {
    value ??= '';
    if (value.length >= this._shortestIbanConfig) {
      this.assign('set', value, options);
    } else {
      let country: string = this.get('country')?.value;
      this.initAndGetIbanConfig(country);
    }
  }

  /**
   * Permet d'assigner une valeur dans un champ spécifique de l'Iban
   * @param value Valeur à insérer
   * @param options Options d'intégration de la valeur
   */
  public patch(
    value: string,
    options: { key: string; onlySelf?: boolean; emitEvent?: boolean }
  ): void {
    this.get(options.key)?.setValue(value, {
      onlySelf: options.onlySelf ?? true,
      emitEvent: options.emitEvent ?? false
    });
  }

  /**
   * Permet de remettre à l'état d'origine les champs contenue dans l'Iban
   * @param value Valeur acquise après la remise à 0
   * @param options Options d'intégration de la valeur
   */
  override reset(
    value?: string,
    options?: { key?: string; onlySelf?: boolean; emitEvent?: boolean }
  ): void {
    if (options?.key) {
      this.get(options.key)?.reset(value, options);
      return;
    }

    if (value && value.length >= this._shortestIbanConfig) {
      this.assign('reset', value, options);
      return;
    }

    this.get('country')?.reset('FR', options);
    this.getControls().forEach((property: [string, AbstractControl<any, any>]) => {
      if (!['country'].includes(property[0])) {
        property[1]?.reset(undefined, options);
      }
    });
  }

  /**
   * Assigne ou réinitialise les valeurs des contrôles en fonction du mode spécifié.
   *
   * @template T - Le type de mode, soit 'set' pour assigner les valeurs, soit 'reset' pour réinitialiser les valeurs.
   * @param {T} mode - Le mode d'opération, soit 'set' pour assigner les valeurs, soit 'reset' pour réinitialiser les valeurs.
   * @param {string} value - La chaîne de caractères contenant les valeurs à assigner ou à réinitialiser.
   * @param {Object} [options] - Options supplémentaires pour l'opération.
   * @param {string} [options.key] - La clé optionnelle à utiliser.
   * @param {boolean} [options.onlySelf] - Si vrai, n'affecte que le contrôle actuel.
   * @param {boolean} [options.emitEvent] - Si vrai, émet un événement après l'opération.
   */
  private assign<T extends 'set' | 'reset'>(
    mode: T,
    value: string,
    options?: { onlySelf?: boolean; emitEvent?: boolean }
  ): void {
    let values: string[] = RegexBank.extractMacthes(value, /.{1,4}/gs);
    values.splice(0, 1, ...RegexBank.extractMacthes(values[0], /.{2}/g));
    values[0] = this.initAndGetIbanConfig(values[0])?.key ?? '';

    if (this._shortestIbanConfig < value.length && this._selectedIbanConfig) {
      this.getControls().forEach((control: [string, AbstractControl<any, any>], index: number) => {
        let val: string | undefined =
          values && values.length > 0 ? values[index].toUpperCase() : undefined;
        mode === 'set' ? control[1]?.setValue(val, options) : null;
        mode === 'reset' ? control[1]?.reset(val, options) : null;
      });
    }
  }

  /**
   * @override
   * Efface les validateurs de tous les contrôles de ce groupe de contrôles.
   * Parcourt chaque contrôle et appelle la méthode `clearValidators` sur chacun d'eux.
   */
  override clearValidators(): void {
    this.getControls().forEach((control: [string, AbstractControl<any, any>]) =>
      control[1].clearValidators()
    );
  }
  //#endregion

  //#region Accesseurs
  /**
   * Retourne la valeur concaténée de tous les contrôles sous forme de chaîne en majuscules.
   * @returns {string} La valeur concaténée de tous les contrôles en majuscules.
   */
  public getValue(): string {
    return Object.values(this.controls)
      .map((control: AbstractControl<any, any>) => control.value)
      .join('')
      .toUpperCase();
  }

  /**
   * @summary Cette méthode retourne les contrôles du formulaire.
   * @returns { { [key: string]: AbstractControl<any, any> } } Un objet contenant les contrôles du formulaire.
   */
  public getControl(): { [key: string]: AbstractControl<any, any> } {
    return this.controls;
  }

  /**
   * Retourne les contrôles sous forme de tableau de paires clé-valeur.
   * Chaque paire contient le nom du contrôle et l'objet AbstractControl correspondant.
   * @returns {Array<[string, AbstractControl<any, any>]>} Un tableau de paires clé-valeur représentant les contrôles.
   */
  public getControls(): [string, AbstractControl<any, any>][] {
    return Object.entries(this.controls);
  }

  /**
   * Retourne un tableau contenant les noms des contrôles.
   * @returns {string[]} Un tableau de chaînes de caractères représentant les noms des contrôles.
   */
  public getControlsName(): string[] {
    return Object.keys(this.controls);
  }

  /**
   * Retourne les options de pays disponibles.
   * @returns {Option<string, number>[]} Un tableau d'options de pays.
   */
  public getCountriesOptions(): Option<string, number>[] {
    return this._countriesOptions ?? new Array<Option<string, number>>();
  }

  /**
   * Renvoie le nombre d'options de pays disponibles.
   * @returns {number} Le nombre d'options de pays, ou 0 si aucune option n'est disponible.
   */
  public getCountriesOptionsCount(): number {
    return this._countriesOptions?.length ?? 0;
  }

  /**
   * Initialise et retourne la configuration IBAN pour une clé donnée.
   * @param keyConfig - La clé de configuration pour laquelle obtenir la configuration IBAN.
   * @returns La paire clé-valeur de la configuration IBAN si trouvée, sinon undefined.
   */
  public initAndGetIbanConfig(keyConfig: string): KeyValuePair<string, number> | undefined {
    let config: Option<string, number> | undefined = this._countriesOptions?.find(
      (keyValue: Option<string, number>) => keyValue.getKey() === keyConfig
    );

    if (config && this._selectedIbanConfig !== config.toKeyValuePair()) {
      this._selectedIbanConfig = config.toKeyValuePair();
      this.setFormControlsOnLength();
    }
    return this._selectedIbanConfig;
  }

  /**
   * Retourne la configuration IBAN sélectionnée sous forme de paire clé-valeur.
   * Si aucune configuration n'est sélectionnée, retourne une paire avec une clé vide
   * et la valeur de la configuration IBAN la plus courte.
   * @returns {KeyValuePair<string, number>} La configuration IBAN sélectionnée ou la configuration par défaut.
   */
  public getSelectedIbanConfig(): KeyValuePair<string, number> {
    return this._selectedIbanConfig ?? { key: '', value: this._shortestIbanConfig };
  }

  /**
   * Retourne le résultat du modulo 4 de la valeur de la configuration IBAN sélectionnée.
   * Si aucune configuration IBAN n'est sélectionnée, retourne 0.
   * @returns {number} Le résultat du modulo 4 de la valeur de la configuration IBAN sélectionnée, ou 0 si aucune configuration n'est sélectionnée.
   */
  public getConfigModulo(): number {
    return this._selectedIbanConfig ? (this._selectedIbanConfig.value ?? 0) % 4 : 0;
  }

  /**
   * Vérifie si l'IBAN est valide.
   * @returns {boolean} Retourne `true` si l'IBAN est valide, sinon `false`.
   */
  public isValid(): boolean {
    return this._valid;
  }

  /**
   * Définit la validité de l'IBAN.
   * @param valid - Un booléen indiquant si l'IBAN est valide.
   */
  public setValid(valid: boolean): void {
    this._valid = valid;
  }

  /**
   * Définit la configuration IBAN sélectionnée.
   * @param selectedIbanConfig - La paire clé-valeur représentant la configuration IBAN sélectionnée.
   */
  public setSelectedIbanConfig(selectedIbanConfig: KeyValuePair<string, number>): void {
    this._selectedIbanConfig = selectedIbanConfig;
  }
  //#endregion

  /**
   * @private
   * Gère l'événement de collage pour initialiser une valeur.
   *
   * @param name - Le nom du champ à mettre à jour.
   * @param value - La valeur collée.
   *
   * Si la valeur est vide, la méthode retourne immédiatement.
   * La limite de caractères est déterminée en fonction du nom du champ :
   * - 'key' : 2 caractères
   * - 'modulo' : valeur retournée par la méthode getConfigModulo()
   * - autre : 4 caractères
   *
   * Si la longueur de la valeur est supérieure ou égale à _shortestIbanConfig,
   * la méthode set est appelée avec la valeur complète.
   * Sinon, la méthode setValue est appelée avec une sous-chaîne de la valeur,
   * limitée au nombre de caractères déterminé.
   */
  private handlePasteToInit(name: string, value: string): void {
    if (!value) {
      return;
    }

    const limit = name === 'key' ? 2 : name === 'modulo' ? this.getConfigModulo() : 4;

    (value?.length ?? 0) >= this._shortestIbanConfig
      ? this.set(value)
      : this.get(name)?.setValue(value?.substring(0, limit), { emitEvent: false });
  }

  /**
   * @summary Cette méthode configure les abonnements aux changements de valeur pour les contrôles de formulaire.
   *
   * @remarks
   * - Abonne aux changements de valeur du contrôle 'country' pour initialiser la configuration IBAN et gérer le changement de pays.
   * - Pour chaque autre contrôle, abonne aux changements de valeur pour convertir la valeur en majuscules et gérer les événements de collage.
   */
  public setValueChanges(): void {
    this.get('country')!.valueChanges.subscribe((country: string) => {
      this.initAndGetIbanConfig(country);
      this.onChangeCountry(country);
    });
    this.getControls().forEach((control: [string, AbstractControl<any, any>]) => {
      if (!['country'].includes(control[0])) {
        control[1]?.valueChanges
          .pipe(map((key: string) => key?.toUpperCase()))
          .subscribe((key: string) => this.handlePasteToInit(control[0], key));
      }
    });
  }

  /**
   * @summary Définit les contrôles de formulaire en fonction de la longueur de l'IBAN sélectionné.
   *
   * @description
   * Cette méthode ajuste dynamiquement les contrôles de formulaire en fonction de la configuration de l'IBAN sélectionné.
   * Si la valeur de la configuration de l'IBAN sélectionné est supérieure ou égale à la configuration de l'IBAN la plus courte,
   * elle calcule le nombre de contrôles nécessaires et les ajoute ou les supprime en conséquence.
   */
  public setFormControlsOnLength(): void {
    if (!this._selectedIbanConfig) {
      return;
    }

    if (this._selectedIbanConfig.value >= this._shortestIbanConfig) {
      let formCounter: number = Math.floor((this._selectedIbanConfig.value - 4) / 4);
      let loopMax: number = this.getConfigModulo() > 0 ? formCounter + 1 : formCounter;

      this.getControls().forEach((control: [string, AbstractControl<any, any>]) => {
        if (control[0] !== 'country') {
          control[0] === 'key'
            ? control[1]?.reset(undefined, { emitEvent: false })
            : this.removeControl(control[0]);
        }
      });

      for (let i = 0; i < loopMax; i++) {
        let controlName: string =
          i === loopMax - 1 && this.getConfigModulo() > 0 ? 'modulo' : 'BBAN' + (i + 1);

        this.addControl(
          controlName,
          new FormControl<string | null>(
            { value: null, disabled: this._disabled },
            !this._disabled
              ? [
                  Validators.required,
                  Validators.minLength(controlName === 'modulo' ? this.getConfigModulo() : 4)
                ]
              : null
          ),
          { emitEvent: false }
        );
        this.get(controlName)
          ?.valueChanges.pipe(map((key: string) => key?.toUpperCase()))
          .subscribe((key: string) => this.handlePasteToInit(controlName, key));
      }
    }
  }
}
