import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { map, Observable } from 'rxjs';
import { ZonesAvecCentres } from 'src/app/api/models/class/ZoneAvecCentres/ZonesAvecCentres';
import { GeneriqueResponse } from 'src/app/api/models/shared/generiqueResponse';
import { RessourceService } from 'src/app/api/services/ressource.service';

@Injectable({
  providedIn: 'root'
})
export class GetZonesAvecCentresResolver implements Resolve<ZonesAvecCentres> {
  constructor(private ressourceService: RessourceService) {}

  resolve(): Observable<ZonesAvecCentres> {
    return this.ressourceService
      .recupererZonesAvecCentres()
      .pipe(map((response: GeneriqueResponse) => new ZonesAvecCentres(response.Result)));
  }
}

