import { ReferenceBancaire } from 'src/app/core/models/wizard.model';
import { NEVER, debounceTime, map, switchMap, tap } from 'rxjs';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from 'src/app/core/state/core.state';
import * as fromHeader from 'src/app/core/state/header';
import * as fromWizard from 'src/app/core/state/wizard';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { EnumCodeModePaiement } from 'src/app/api/models/enum/CodeModePaiement.enum';
import { Data, KeyValuePair } from '../../../../models/reference-data.model';
import { AppResource } from 'src/app/app.resource';
import { MessageService } from 'primeng/api';
import { IbanControl } from 'src/app/shared/models/iban-control';
import { IconDefinition, faHistory, faSave } from '@fortawesome/free-solid-svg-icons';
import { ReferenceBancaireHistoriqueService } from 'src/app/api/services/referenceBancaireHistorique.service';
import { ReferenceHistorique } from 'src/app/api/models/response/referenceBancaire/ReferenceBancaireHistoriqueResponse';
import { GeneriqueResponse } from 'src/app/api/models/shared/generiqueResponse';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ReferenceBancaireService } from 'src/app/api/services/referenceBancaire.service';
import { InfosBicDomiciliationRumResponse } from 'src/app/api/models/response/referenceBancaire/InfosBicDomiciliationRumResponse';
import { IbanControlComponent } from '../iban-control/iban-control.component';
import { CommonModule } from '@angular/common';
import { InputNumberModule } from 'primeng/inputnumber';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TooltipModule } from 'primeng/tooltip';
import { LoaderComponent } from '../../../loader/loader.component';
import { PipeModule } from 'src/app/shared/modules/pipe.module';
import { InputTextModule } from 'primeng/inputtext';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BankHistoricComponent } from '../bank-historic/bank-historic.component';
import { FormBankingReferencesService } from '../form-banking-references.service';
import { Option } from 'src/app/shared/models/option';
import { BankReferences } from 'src/app/api/models/class/bankReferences';
import { AbonnementService } from 'src/app/api/services/abonnement.service';
import { Resource } from 'src/app/resources/resource';
import { haveAutorisation } from 'src/app/shared/utils/webFonctionsUtils';
import { WebFonctions } from 'src/app/api/models/interface/WebFunction';
import { MessageServiceUtils } from 'src/app/shared/utils/messageServiceUtils';
import { NgVarDirective } from 'src/app/shared/directives/ng-var.directive';
import { FieldConfiguration } from 'src/app/shared/types/field-configuration.type';
import { Icons } from 'src/app/shared/models/icons';
import { EnumCanalAcquisition } from 'src/app/api/models/enum/EnumCanalAcquisition.enum';
import { InformationsPaiementResponse } from 'src/app/api/models/response/abonnement/InformationsPaiementResponse';

@Component({
  selector: 'waterp-form-bank-references',
  templateUrl: './form-bank-references.component.html',
  styleUrls: ['./form-bank-references.component.scss'],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DropdownModule,
    CalendarModule,
    FontAwesomeModule,
    RadioButtonModule,
    IbanControlComponent,
    InputNumberModule,
    TooltipModule,
    LoaderComponent,
    PipeModule,
    OverlayPanelModule,
    InputTextModule,
    NgVarDirective
  ],
  standalone: true
})
export class FormBankReferencesComponent implements OnInit, OnDestroy {
  @Output() tipForm: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() previousForm: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() validForm: EventEmitter<boolean> = new EventEmitter<boolean>();

  private _resource: Resource;
  private _messageServiceUtils: MessageServiceUtils;
  private _userContextIsAllowed: boolean = false;

  faHistory: IconDefinition = faHistory;
  faSave: IconDefinition = faSave;

  bankReferencesForm: FormGroup;
  tooltipEnregistrerCoordonneesBancaires!: string;
  isFormLoading: boolean = true;
  isLoadingButton: boolean = false;

  paymentModes!: Data[];
  acquistionChannel!: Data[];
  ibanIndicator!: Icons;
  bicIndicator!: Icons;

  infosPaiement?: InformationsPaiementResponse;

  constructor(
    public store: Store<State>,
    public resources: AppResource,
    public messageService: MessageService,
    private _route: ActivatedRoute,
    private _dialogService: DialogService,
    private _dialogRef: DynamicDialogRef,
    private _dialogConfig: DynamicDialogConfig,
    private _formBankingReferencesService: FormBankingReferencesService,
    private _abonnementService: AbonnementService,
    private _referenceBancaireService: ReferenceBancaireService,
    private _referenceBancaireHistoriqueService: ReferenceBancaireHistoriqueService
  ) {
    this._resource = resources['resource'];
    this._messageServiceUtils = new MessageServiceUtils(this.messageService, this.resources);
    this.bankReferencesForm = new FormGroup({});

    store
      .select(fromHeader.selectWebFunctions)
      .pipe(
        map(
          (webFunctions: WebFonctions) =>
            (this._userContextIsAllowed = Boolean(
              haveAutorisation('ABO_MODE_PAIEMENT_MODIF', webFunctions)
            ))
        )
      )
      .subscribe();

    this.initDataManager();
  }

  public getResource(): Resource {
    return this._resource;
  }

  public getFormService(): FormBankingReferencesService {
    return this._formBankingReferencesService;
  }

  public isUserAllowed(): boolean {
    return this._userContextIsAllowed;
  }

  public isAStep(): boolean {
    return this._dialogConfig.data?.componentAsStep ?? true;
  }

  get iban(): IbanControl {
    return this.bankReferencesForm.get('iban') as IbanControl;
  }

  public ngOnInit(): void {
    if (this.isAStep()) {
      this.store
        .select(fromWizard.selectReferenceBancaireData)
        .subscribe((references: ReferenceBancaire) =>
          this._formBankingReferencesService.setRequiredFields(
            references.modePaiement as EnumCodeModePaiement,
            {
              IndicateurPaiementSur: null,
              CodeModePaiement: references.modePaiement,
              DateValiditeCB: references.dateValiditeCB,
              JourDePrelevement: references.jourPrelevement,
              HasHistoriquePaiement: false,
              NomSociete: null,
              CodeIcsSociete: null,
              ReferenceBancaire: {
                CanalAcquisitionSepa: { Code: references.acquisitionConsentement },
                CodeBic: references.bic,
                CodeIban: references.iban,
                DateRum: references.dateRUM,
                Domiciliation: references.domiciliation,
                NumeroAbonnement: null,
                NumeroRum: references.numeroRUM,
                Titulaire: references.titulaire
              }
            }
          )
        );
    }

    this._abonnementService
      .getInformationsChampIban()
      .pipe(
        map(
          (response: GeneriqueResponse) =>
            response.Result?.map(
              (countryConfig: KeyValuePair<string, number>) =>
                new Option(countryConfig.key, countryConfig.value)
            ) ?? new Array<Option<string, number>>()
        )
      )
      .subscribe((countriesOptions: Option<string, number>[]) => {
        this.setForm(countriesOptions);

        this.setPaymentMethodObservables();
        this.setIbanObservables();
        this.setBicObservables();
        this.setOwnerObservables();

        Object.keys(this.bankReferencesForm.controls).forEach((key: string) =>
          this.bankReferencesForm.get(key)?.updateValueAndValidity({ emitEvent: false })
        );

        this._dialogConfig.closable = true;
        this.isFormLoading = false;
      });
  }

  public ngOnDestroy(): void {
    this.store.dispatch(fromHeader.updatePaiement({ activePaiement: false }));
  }

  //#region Initialisation des données
  private initDataManager(): void {
    this.infosPaiement = this._dialogConfig.data?.infosPaiement;
    this._formBankingReferencesService
      .getAcquisitionChannel()
      .subscribe((canaux: Data[]) => (this.acquistionChannel = canaux));

    this._formBankingReferencesService.getPaymentModes().subscribe((modesPaiement: Data[]) => {
      this.paymentModes = modesPaiement;

      let mode: EnumCodeModePaiement =
        (this.paymentModes.find(
          (mode: Data) =>
            this._route.snapshot.data['bankingInformations']?.LibelleModePaiement === mode.Value
        )?.Key as EnumCodeModePaiement) ?? EnumCodeModePaiement.Mensualisation;

      this._formBankingReferencesService.setRequiredFields(mode, this.infosPaiement);
    });

    this.generateIcons();
  }
  //#endregion

  /**
   * Génère les icons d'état de validation des champs IBAN et BIC
   */
  private generateIcons(): void {
    this.ibanIndicator = new Icons({
      condition: () => this.bankReferencesForm.get('iban')?.touched ?? false
    });
    this.bicIndicator = new Icons({
      condition: () => this.bankReferencesForm.get('bic')?.touched ?? false
    });
  }

  //#region Gestion du formulaire et de ses observables
  /**
   * Synthétise la récupération d'un champ selon son origine
   * @param from Origine du champ
   * @param name Nom du champ
   * @returns Un champ selon son origine
   */
  public getField<T extends 'service' | 'form'>(
    from: T,
    name: string
  ): T extends 'service' ? FieldConfiguration<EnumCodeModePaiement> : AbstractControl | null {
    if (from === 'service')
      return this._formBankingReferencesService.getRequiredFields()[name] as any;
    if (from === 'form') return this.bankReferencesForm.get(name) as any;
    return null as any;
  }

  /**
   * Initialisation du formulaire
   * @param countriesOptions
   */
  private setForm(countriesOptions: Option<string, number>[]): void {
    Object.entries(this._formBankingReferencesService.getRequiredFields()).forEach(
      (property: [string, FieldConfiguration<EnumCodeModePaiement>]) => {
        this.bankReferencesForm.addControl(
          property[0],
          property[0] === 'iban'
            ? new IbanControl(
                countriesOptions,
                property[1].defaultValue,
                !this._userContextIsAllowed ||
                  !property[1].required(this.bankReferencesForm.get('paymentMethod')?.value)
              )
            : new FormControl(
                {
                  value: property[1].defaultValue,
                  disabled:
                    !this._userContextIsAllowed ||
                    ['radioBtnInternet', 'rumNumber', 'rumDate'].includes(property[0])
                },
                property[1].required(this.bankReferencesForm.get('paymentMethod')?.value)
                  ? Validators.required
                  : null
              ),
          { emitEvent: false }
        );
      }
    );
  }

  /**
   * Permet de mettre à jour le formulaire avec les données reçues en paramètre
   * @param reference Données à intégrer dans le formulaire
   */
  private patchForm(reference: ReferenceHistorique): void {
    let mode: Data | undefined = this.paymentModes.find(
      (method: Data) => method.Value === reference.LibelleModePaiement
    );
    let channel: Data | undefined = this.acquistionChannel.find(
      (channel: Data) => channel.Value === reference.LibelleCanalAcquisitionSepa
    );

    if (!mode || !channel) {
      return;
    }

    this.bankReferencesForm.patchValue({
      paymentMethod: mode.Key
    });

    this.bankReferencesForm.patchValue(
      {
        consentType: channel.Key,
        owner: reference.Titulaire,
        iban: reference.CodeIban,
        domiciliation: reference.Domiciliation,
        bic: reference.CodeBic,
        rumDate: reference.DateRum,
        rumNumber: reference.NumeroRum
      },
      { emitEvent: false }
    );
  }

  /**
   * Permet de configurer les actions à faire lors du changement
   * de la valeur du champ "Mode de Paiement"
   */
  private setPaymentMethodObservables(): void {
    this.bankReferencesForm
      .get('paymentMethod')
      ?.valueChanges.pipe(map((method: string) => method as EnumCodeModePaiement))
      .subscribe((method: EnumCodeModePaiement) => {
        if (this.isAStep()) {
          this.tipForm.emit(!this._formBankingReferencesService.isAutomaticModes(method));
        }
        this._formBankingReferencesService.setRequiredFields(method);

        Object.keys(this.bankReferencesForm.controls).forEach((key: string) => {
          if (key !== 'paymentMethod') {
            this.paymentMethodChangeControls(key, method);
          }
        });
      });
  }

  /**
   * Permet de configurer les actions à faire lors du changement
   * de la valeur du champ "Titulaire"
   */
  private setOwnerObservables(): void {
    this.bankReferencesForm
      .get('owner')
      ?.valueChanges.pipe(debounceTime(1000))
      .subscribe((owner: string) => {
        if (!owner) {
          return;
        }
        this._formBankingReferencesService.assignNewRUMNumberWithCondition(
          this.bankReferencesForm,
          this._route.snapshot.data['paymentInformations']
        );
      });
  }

  /**
   * Permet de configurer les actions à faire lors du changement
   * de la valeur du champ "IBAN"
   */
  private setIbanObservables(): void {
    this.iban.onChangeCountry = () => {
      this.bankReferencesForm.get('domiciliation')?.reset();
      this.bankReferencesForm.get('bic')?.reset();
      this.bankReferencesForm.get('rumNumber')?.reset();
      this.bankReferencesForm.get('rumDate')?.reset();
    };

    this.iban?.setValueChanges();

    this.iban.valueChanges
      .pipe(
        tap(() => this.ibanIndicator.unsetIcon()),
        debounceTime(1000),
        map(() => (this.iban.status === 'VALID' ? this.iban.getValue() : undefined)),
        tap((iban?: string) => (iban ? this.ibanIndicator.startLoading() : null)),
        switchMap((iban?: string) =>
          iban
            ? this._referenceBancaireService.isIbanValid(iban).pipe(
                map((response: GeneriqueResponse) => {
                  this.iban.setValid(response.Result);
                  this.ibanIndicator.stopLoading(this.iban.isValid());
                  return this.iban.isValid() ? iban : undefined;
                }),
                switchMap((iban?: string) =>
                  iban
                    ? this._referenceBancaireService
                        .getInfosBicDomiciliationRum(iban)
                        .pipe(map((response: GeneriqueResponse) => response.Result))
                    : NEVER
                )
              )
            : NEVER
        )
      )
      .subscribe((bankingInfo: InfosBicDomiciliationRumResponse) => {
        this.bankReferencesForm.get('domiciliation')?.setValue(bankingInfo.Domiciliation);
        this.bankReferencesForm.get('bic')?.setValue(bankingInfo.CodeBic);
        this._formBankingReferencesService.assignNewRUMNumberWithCondition(
          this.bankReferencesForm,
          this._route.snapshot.data['paymentInformations']
        );
      });
  }

  /**
   * Permet de configurer les actions à faire lors du changement
   * de la valeur du champ "BIC"
   */
  private setBicObservables(): void {
    this.bankReferencesForm
      .get('bic')
      ?.valueChanges.pipe(
        tap(() => this.bicIndicator.unsetIcon()),
        tap((bic: string) => {
          if ((bic?.length ?? 0) > 0) {
            this.bicIndicator.startLoading();
          }
        }),
        map((bic: string) => bic?.toUpperCase()),
        switchMap((bic: string) =>
          bic
            ? this._referenceBancaireService
                .isBicValid(bic, this.iban.getValue())
                .pipe(map((response: GeneriqueResponse) => response.Result))
            : NEVER
        )
      )
      .subscribe((bicValid: boolean) => this.bicIndicator.stopLoading(bicValid));
  }

  /**
   * Synthétise le traitement efféctué par le changement de valeur du mode de paiement
   * @param name Champ à traiter
   * @param mode Nouvelle valeur
   * @param fromGroup Si fournie, implique que le champ à traiter est à récupérer dans le groupe mentionné
   */
  private paymentMethodChangeControls(name: string, mode: EnumCodeModePaiement): void {
    let field: AbstractControl | null | undefined = this.bankReferencesForm.get(name);
    field?.clearValidators();

    this.ibanIndicator.unsetIcon();
    this.bicIndicator.unsetIcon();

    if (this._formBankingReferencesService.isAutomaticModes(mode)) {
      this._formBankingReferencesService.assignNewRUMNumberWithCondition(
        this.bankReferencesForm,
        this._route.snapshot.data['paymentInformations']
      );
    } else {
      ['rumNumber', 'rumDate'].includes(name)
        ? field?.reset(undefined, { emitEvent: false })
        : null;
    }

    this._formBankingReferencesService.getRequiredFields()[name]?.required(mode)
      ? field?.addValidators(Validators.required)
      : field?.reset(undefined, { emitEvent: false });

    this._formBankingReferencesService.getRequiredFields()[name]?.required(mode) &&
    !['radioBtnInternet', 'rumNumber', 'rumDate'].includes(name)
      ? field?.enable({ emitEvent: false })
      : field?.disable({ emitEvent: false });

    field?.updateValueAndValidity({ emitEvent: false });
  }
  //#endregion

  /**
   * Modale de l'historique des modes de paiements pour cet abonnement
   * Possibilité de sélectionner une ligne pour récupérer les informations
   */
  public showHistoric(): void {
    const historicModal: DynamicDialogRef = this._dialogService.open(BankHistoricComponent, {
      header: 'Historique des références bancaires',
      width: '70%',
      closable: false,
      data: {
        historic: this._referenceBancaireHistoriqueService.rechercherByAbonnement(
          this._dialogConfig.data.numeroAbonnement
        )
      }
    });
    historicModal.onClose.subscribe((reference: ReferenceHistorique) => {
      if (!reference) {
        return;
      }

      this.patchForm(reference);
    });
  }

  /**
   * Permet de vider les champs du formulaire ou de les initialiser à leur état d'origine
   * @param withDefault Permet de choisir si on remet les valeurs à leur état intiale ou à vide
   */
  public onFlushingFields(withDefault: boolean = false): void {
    if (withDefault) {
      let mode: EnumCodeModePaiement =
        (this.paymentModes.find(
          (mode: Data) =>
            this._route.snapshot.data['bankingInformations']?.LibelleModePaiement === mode.Value
        )?.Key as EnumCodeModePaiement) ?? EnumCodeModePaiement.Mensualisation;

      this._formBankingReferencesService.setRequiredFields(
        mode,
        this._route.snapshot.data['paymentInformations']
      );
    }
    Object.entries(this.bankReferencesForm.controls).forEach(
      (property: [string, AbstractControl]) => {
        let value: unknown = withDefault
          ? this._formBankingReferencesService.getRequiredFields()[property[0]].defaultValue
          : null;

        value =
          property[0] === 'consentType'
            ? this.acquistionChannel.find(
                (channel: Data) => channel.Value === EnumCanalAcquisition.TELEPHONIE
              )?.Key
            : value;

        property[1]?.reset(value, { emitEvent: false });
        property[1]?.updateValueAndValidity({ emitEvent: false });
      }
    );
  }

  /**
   * Cette méthode vérifie si l'état actuel est une étape et, si c'est
   * le cas, émet un événement pour retourner au formulaire précédent.
   */
  returnPreviousForm() {
    if (this.isAStep()) {
      this.previousForm.emit(true);
    }
  }

  /**
   * Permet de donner le format attendu pour l'exécution de la requête
   */
  public submit(): void {
    if (!this._userContextIsAllowed) {
      this._messageServiceUtils.info(this._resource.GlobalTexts.Auth.Fonction_Modif_NotAllowed);
      return;
    }

    if (!this.bankReferencesForm.valid) {
      this.bankReferencesForm.markAllAsTouched();
      return;
    }

    const body: BankReferences = BankReferences.fromFormGroup(
      this.bankReferencesForm,
      this._formBankingReferencesService,
      this._dialogConfig.data?.numeroAbonnement
    );

    if (this.isAStep()) {
      this.store.dispatch(fromWizard.updateReferenceBancaire({ payload: body.toWizard() }));
      this.validForm.emit(true);
      return;
    }

    if (!this.isLoadingButton) {
      this.isLoadingButton = true;
      this._abonnementService
        .mettreAJourModePaiement(body)
        .subscribe((response: GeneriqueResponse) => {
          this.isLoadingButton = false;
          if (response.Code !== 100) {
            this.bankReferencesForm.markAllAsTouched();
            this._messageServiceUtils.warning(
              response.Description ?? this._resource.toast.errorDescription
            );
            return;
          }
          this._messageServiceUtils.success();
          this._dialogRef.close(body);
        });
    }
  }
}
