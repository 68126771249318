import { Component, Input } from '@angular/core';
import { IbanControl } from 'src/app/shared/models/iban-control';
import { AppResource } from 'src/app/app.resource';
import { Resource } from 'src/app/resources/resource';
import { CommonModule } from '@angular/common';
import { InputNumberModule } from 'primeng/inputnumber';
import { DropdownModule } from 'primeng/dropdown';
import { ReactiveFormsModule } from '@angular/forms';
import { NgVarDirective } from 'src/app/shared/directives/ng-var.directive';
import { AutoTabDirective } from 'src/app/shared/directives/auto-tab.directive';

@Component({
  selector: 'waterp-iban-control',
  templateUrl: './iban-control.component.html',
  styleUrls: ['./iban-control.component.scss'],
  imports: [
    CommonModule,
    InputNumberModule,
    DropdownModule,
    ReactiveFormsModule,
    NgVarDirective,
    AutoTabDirective
  ],
  standalone: true
})
export class IbanControlComponent {
  @Input() ibanControl!: IbanControl;

  resource: Resource;

  constructor(private resources: AppResource) {
    this.resource = this.resources['resource'];
  }
}
