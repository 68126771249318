import { Option } from 'src/app/shared/models/option';
import { RessourceService } from 'src/app/api/services/ressource.service';
import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { Observable, map, switchMap } from 'rxjs';
import { GeneriqueResponse } from 'src/app/api/models/shared/generiqueResponse';
import { Procedure } from 'src/app/api/models/class/procedure';
import { Profil } from 'src/app/api/models/class/profil';
import { Unite } from 'src/app/api/models/class/unite';
import { WebFunction } from 'src/app/api/models/class/fonction-web';
import { ProfilService } from 'src/app/api/services/profil.service';

@Injectable({
  providedIn: 'root'
})
export class GetLightSearchListsResolver
  implements Resolve<{ name: string; options: Option<string, string>[] }[]>
{
  searchOptions: { label: string; name: string; options: Option<string, string>[] }[];

  constructor(
    private ressourceService: RessourceService,
    private profilService: ProfilService,
    private router: Router
  ) {
    this.searchOptions = new Array<{
      label: string;
      name: string;
      options: Option<string, string>[];
    }>();
  }

  /**
   * ! Ce résolveur est constitué de contrôle de token et n'est pas développé
   * ! proprement, lorsqu'on passera en HTTP2 :
   * ?    - Ce dernier devra être remplacé dans le app.routing.module par
   * ?  une assignation d'1 résolveur/liste (GetUniteResolver, GetProfilResolver...)
   * ?    - Un guard angular sur la route (app.routing.module) devra permettre
   * ?  de gérer la validité du token efficacement
   * ?    - De par le changement dans le app.routing.module la récupération de ces
   * ?  dernière dans le composant devront être revues par la même occasion
   * Résoud la récupération des différentes listes pour les dropdowns
   * du formulaire de recherche (respectivement: Unite, Profil, Procedure et Fonctions web)
   * @returns L'objet "searchOptions" qui vient regrouper en 1 objet les listes
   */
  resolve(): Observable<{ name: string; options: Option<string, string>[] }[]> {
    this.searchOptions = new Array<{
      label: string;
      name: string;
      options: Option<string, string>[];
    }>();
    return this.ressourceService.getUnities(true).pipe(
      switchMap((response: GeneriqueResponse) => {
        this.searchOptions.push({
          label: 'Unité',
          name: 'Unite',
          options: response.Result.map((unity: Unite) => new Unite(unity))
            .map((unity: Unite) => new Option<string, string>(unity.getNumero(), unity.getNom()))
            .sort((a: Option<string, string>, b: Option<string, string>) =>
              a.getValue().localeCompare(b.getValue())
            )
        });
        return this.profilService.getProfils().pipe(
          switchMap((response: GeneriqueResponse) => {
            this.searchOptions.push({
              label: 'Profil',
              name: 'Profil',
              options: response.Result.map((profil: Profil) => new Profil(profil))
                .map(
                  (profil: Profil) =>
                    new Option<string, string>(profil.getCode(), profil.getLibelle())
                )
                .sort((a: Option<string, string>, b: Option<string, string>) =>
                  a.getValue().localeCompare(b.getValue())
                )
            });
            return this.ressourceService.getProcesses().pipe(
              switchMap((response: GeneriqueResponse) => {
                this.searchOptions.push({
                  label: 'Procédure',
                  name: 'Procedure',
                  options: response.Result.map((process: Procedure) => new Procedure(process))
                    .map(
                      (process: Procedure) =>
                        new Option<string, string>(
                          process.getCode(),
                          process.getLibelle(),
                          process.getIsActive() !== ''
                        )
                    )
                    .sort((a: Option<string, string>, b: Option<string, string>) =>
                      a.getValue().localeCompare(b.getValue())
                    )
                });
                return this.ressourceService.getWebFunctions().pipe(
                  map((response: GeneriqueResponse) => {
                    this.searchOptions.push({
                      label: 'Fonctions web',
                      name: 'FonctionWeb',
                      options: response.Result.map(
                        (webFunction: WebFunction) => new WebFunction(webFunction)
                      )
                        .map(
                          (webFunction: WebFunction) =>
                            new Option<string, string>(
                              webFunction.getCode(),
                              webFunction.getLibelle()
                            )
                        )
                        .sort((a: Option<string, string>, b: Option<string, string>) =>
                          a.getValue().localeCompare(b.getValue())
                        )
                    });
                    return this.searchOptions;
                  })
                );
              })
            );
          })
        );
      })
    );
  }
}
