import { IZoneAvecCentres } from '../interface/autorisations/zoneAvecCentres.interface';
import { Ressource } from './Ressource';

export class CompleteRessource {
  private Ressource?: Ressource;
  private ZonesAvecCentres?: IZoneAvecCentres[];

  /**
   * Constructeur d'instance de l'entité CompleteRessource
   * @param distantRessource Dans le cas de reception de ressource d'une requête GET
   *                  les entités peuvent être directement données à ce constructeur
   */
  constructor(distantRessource?: CompleteRessource) {
    this.Ressource = new Ressource(distantRessource?.Ressource);
    this.ZonesAvecCentres = distantRessource?.ZonesAvecCentres ?? [];
  }

  public getRessource(): Ressource | undefined {
    return this.Ressource;
  }

  public setRessource(ressource: Ressource): void {
    this.Ressource = ressource;
  }

  public getZonesAvecCentres(): IZoneAvecCentres[] | undefined {
    return this.ZonesAvecCentres;
  }

  public setZonesAvecCentres(zonesAvecCentres: IZoneAvecCentres[]): void {
    this.ZonesAvecCentres = zonesAvecCentres;
  }
}
