import { isUndefined } from 'is-what';
import { AbonnementDataService } from './../../../../../shared/services/abonnement-data.service';
import { MessageServiceUtils } from './../../../../../shared/utils/messageServiceUtils';
import { MessageService } from 'primeng/api';
import {
  IconDefinition,
  faDownload,
  faFilePdf,
  faPaperPlane,
  faPlayCircle,
  faShare
} from '@fortawesome/free-solid-svg-icons';
import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DividerModule } from 'primeng/divider';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { AbonnementParTypeService } from 'src/app/api/models/class/abonnement-par-type-service';
import { TypeService } from 'src/app/api/models/class/type-service';
import { PipeModule } from 'src/app/shared/modules/pipe.module';
import { AppResource } from 'src/app/app.resource';
import { Resource } from 'src/app/resources/resource';
import { NgVarDirective } from 'src/app/shared/directives/ng-var.directive';
import { ServicesService } from '../services/services.service';
import { TypesServicesService } from 'src/app/api/services/types-services.service';
import { map, Subscription, switchMap, tap } from 'rxjs';
import { GeneriqueResponse } from 'src/app/api/models/shared/generiqueResponse';
import { TooltipModule } from 'primeng/tooltip';
import { Store } from '@ngrx/store';
import { State } from 'src/app/core/state/core.state';
import { updateStorePDF } from 'src/app/shared/utils/pdfUtils';
import { AbonnementService } from 'src/app/api/services/abonnement.service';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { WaterpSwal } from 'src/app/shared/models/waterp-swal';

@Component({
  selector: 'waterp-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.scss'],
  imports: [
    CommonModule,
    OverlayPanelModule,
    DividerModule,
    PipeModule,
    FontAwesomeModule,
    NgVarDirective,
    TooltipModule
  ],
  standalone: true
})
export class ServiceComponent implements OnInit, OnDestroy {
  @Input() subscribed?: AbonnementParTypeService;
  @Input() available?: TypeService;
  @Input() disabled?: boolean = false;
  disablePlaquette: boolean = false;
  subscriptions: Subscription[] = [];

  faFilePdf: IconDefinition = faFilePdf;
  faShare: IconDefinition = faShare;
  faPaperPlane: IconDefinition = faPaperPlane;
  faDownload: IconDefinition = faDownload;
  faPlayCircle: IconDefinition = faPlayCircle;

  resource: Resource;
  messageServiceUtils: MessageServiceUtils;
  isLoading: boolean = false;

  constructor(
    private store: Store<State>,
    private messageService: MessageService,
    private resources: AppResource,
    private abonnementService: AbonnementService,
    private abonnementDataService: AbonnementDataService,
    private typesServicesService: TypesServicesService,
    public servicesService: ServicesService
  ) {
    this.resource = this.resources['resource'];
    this.messageServiceUtils = new MessageServiceUtils(this.messageService, this.resources);
  }

  ngOnInit(): void {
    if (this.subscribed) {
      this.subscriptions.push(
        this.abonnementDataService.numAbonnement$
          .pipe(
            switchMap((value: string) =>
              this.typesServicesService
                .getNextEtatTypeService(value, this.subscribed?.getCodeTypeService() ?? '')
                .pipe(
                  tap((response: GeneriqueResponse) => {
                    if (
                      response.Code === 100 &&
                      !isUndefined(response.Result) &&
                      response.Result?.length > 0
                    ) {
                      this.subscribed?.setNextCode(response.Result[0].Code);
                      this.subscribed?.setLibelle(response.Result[0].Libelle);
                    }
                  })
                )
            )
          )
          .subscribe()
      );
    }

    this.subscriptions.push(
      this.abonnementDataService.numAbonnement$
        .pipe(
          switchMap((value: string) =>
            this.typesServicesService.getPlaquettePubServiceExists(
              value,
              this.subscribed?.getCodeTypeService() ?? this.available?.getCode() ?? ''
            )
          )
        )
        .subscribe((response: GeneriqueResponse) => {
          this.disablePlaquette = response.Result?.Code != 100;
        })
    );
  }

  ngOnDestroy(): void {
    if ((this.subscriptions?.length ?? 0) > 0) {
      this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
    }
  }

  /**
   * Permet d'envoyer la plaquette descriptive du service
   * @param code Code du service dont il faut envoyer et récupérer la plaquette
   */
  public sendAndShowBrochure(code: string): void {
    this.subscriptions.push(
      this.abonnementDataService.numAbonnement$
        .pipe(
          switchMap((value: string) =>
            this.abonnementService
              .SendPlaquettePubMail(value, code)
              .pipe(
                map((response: GeneriqueResponse) => ({ response: response, numAbonnement: value }))
              )
          )
        )
        .subscribe((brochure: { response: GeneriqueResponse; numAbonnement: string }) => {
          if (brochure.response.Code === 100) {
            this.messageServiceUtils.success();
          } else {
            if (brochure.response.Code === 697 || brochure.response.Code === 696) {
              Swal.fire(
                new WaterpSwal(this.resource, 'warning', {
                  text: this.resource.GlobalTexts.Generic.Email_ErrorSendEmail.replace(
                    ' {0}',
                    '<br>' + this.resource.GlobalTexts.Generic.Document_PrintSendManually
                  ),
                  buttonsText: { confirm: this.resource.swal.fermer },
                  withCancel: false
                })
              )
                .then((result: SweetAlertResult) => {
                  if (result.isConfirmed) {
                    this.isLoading = true;
                    this.typesServicesService
                      .getPlaquettePubService(brochure.numAbonnement, code)
                      .subscribe((response: GeneriqueResponse) => {
                        this.isLoading = false;
                        response.Result
                          ? updateStorePDF(response.Result, 'Plaquette', this.store)
                          : this.messageServiceUtils.warning(
                              response.Description ?? this.resource.toast.errorDescription
                            );
                      });
                  }
                })
                .catch(result => {
                  console.error('Error', result);
                });
            }
          }
        })
    );
  }
}
