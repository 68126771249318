import { isArray } from 'is-what';
import { Injectable } from '@angular/core';
import { TextDecoration } from '../types/text-decoration.type';
import { ColChip } from '../types/column/column-chip.type';
import { DateTimeFormat } from '../types/datetime-format.type';
import { Updatable } from '../types/column/column-updatable.type';
import { IconsFormat } from '../types/icons-format.type';
import { ColSuffix } from '../types/column/column-suffix.type';
import { KeyValuePair } from '../models/reference-data.model';
import { DictionaryConverter } from '../types/dictionary-converter.type';
import { ColLink } from '../types/column/column-link.type';

@Injectable({
  providedIn: 'root'
})
export class ConvertService {
  constructor() {}

  public toTextDecoration(value: any): TextDecoration {
    return {
      prefix: value?.prefix ?? '',
      suffix: value?.suffix ?? ''
    };
  }

  public toColumnLink(value: any): ColLink {
    return {
      route: value?.route ?? '/',
      toTransmit: value?.toTransmit
    };
  }

  public toColumnChip(value: any): ColChip {
    return {
      style: value?.style ?? '',
      icon: value?.icon ?? '',
      text: value?.text ?? '',
      suffixes: value?.suffixes
    };
  }

  public toColumnSuffix(value: any): ColSuffix {
    return {
      text: value?.text ?? '',
      isField: value?.isField ?? ''
    };
  }

  public toDateTimeFormat(value: any): DateTimeFormat {
    return ['dd/MM/yyyy', 'HH:mm', 'dd/MM/yyyy HH:mm'].includes(value) ? value : '';
  }

  public toUpdatable(value: any): Updatable {
    return {
      type: value?.type ?? 'text',
      class: value?.class ?? '',
      readonly: value?.readonly ?? false,
      value: value?.value,
      action: value?.action
    };
  }

  public toIconsFormat(value: any): IconsFormat {
    return {
      condition: value?.condition,
      icon: value?.icon,
      class: value?.class ?? '',
      tooltip: value?.tooltip,
      actionTrigger: value?.actionTrigger,
      action: value?.action
    };
  }

  public toListConverter(value: any): DictionaryConverter {
    return { converter: value?.converter ?? [] };
  }

  public toArray(value: any): Array<any> {
    return isArray(value) ? value : [value];
  }

  public toDictionary(value: any): Array<KeyValuePair<string, string>> {
    return isArray(value) ? value : [value];
  }

  public toBool(value: any): boolean {
    return value === 'true' || value === 1;
  }
}
