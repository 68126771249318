import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromRoot from 'src/app/core/state/core.state';
import * as fromDossierCompte from 'src/app/core/state/dossier-compte';
import * as fromInfosFinancieres from 'src/app/core/state/infos-financieres';
import { Observable, map } from 'rxjs';
import { AbonnementService } from 'src/app/api/services/abonnement.service';
import { GeneriqueResponse } from 'src/app/api/models/shared/generiqueResponse';
import { InformationsFinancierResponse } from 'src/app/api/models/response/abonnement/InformationsFinancierResponse';

@Injectable({
  providedIn: 'root'
})
export class GetBankingInformationsResolver implements Resolve<InformationsFinancierResponse> {
  constructor(private store: Store<fromRoot.State>, private abonnementService: AbonnementService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<InformationsFinancierResponse> {
    return this.abonnementService.getInformationsFinancier(route.params['numAbonnement']).pipe(
      map((response: GeneriqueResponse) => {
        let bankingInformations: InformationsFinancierResponse = response.Result ?? {
          NumeroAbonnement: '',
          NumeroDerniereFacture: 0,
          LibelleEvenementCoercition: '',
          LibelleModePaiement: '',
          HasEcheancierFpa: false,
          HasEcheancierFpaCb: false,
          NumberOfNonValeur: 0,
          NumberOfFactureManuelle: 0,
          HasFSL: false,
          HasBDF: false,
          CodeEvenementRelance: '',
          DelaiPaiementRelance: '',
          HasRemboursementEnCours: false
        };

        this.store.dispatch(
          fromInfosFinancieres.updateNombreNonValeur({
            nombreNonValeur: bankingInformations.NumberOfNonValeur ?? 0
          })
        );
        this.store.dispatch(
          fromInfosFinancieres.updateNombreFacturesManuelles({
            nombreFacturesManuelles: bankingInformations.NumberOfFactureManuelle ?? 0
          })
        );
        this.store.dispatch(
          fromDossierCompte.updateHasEcheancierFPA({
            hasEcheancierFPA: bankingInformations.HasEcheancierFpa ?? false
          })
        );
        this.store.dispatch(
          fromDossierCompte.updateHasEcheancierFPACB({
            hasEcheancierFPACB: bankingInformations.HasEcheancierFpaCb ?? false
          })
        );

        return bankingInformations;
      })
    );
  }
}
