<div class="max-w-5xl">
  <div *ngIf="ibanControl" class="flex flex-col">
    <label for="iban">{{
      resource.GlobalTexts.Abonnement.BankingReferences.Interface.Fields.iban
    }}</label>
    <div class="grid grid-cols-9 gap-2" [formGroup]="ibanControl">
      <div *ngIf="ibanControl.getCountriesOptionsCount() > 0" class="waterp-iban p-inputgroup">
        <p-dropdown
          inputId="iban"
          class="waterp-iban-addon"
          [options]="ibanControl.getCountriesOptions()"
          optionLabel="key"
          optionValue="key"
          optionDisabled="disabled"
          formControlName="country"></p-dropdown>
        <input
          #key
          class="waterp-inputtext"
          [autoTab]="2"
          pInputText
          type="text"
          formControlName="key" />
      </div>
      <ng-container *ngFor="let control of ibanControl.getControlsName()">
        <input
          #bban
          *ngIf="!['country', 'key', 'modulo'].includes(control)"
          [autoTab]="4"
          pInputText
          type="text"
          class="waterp-inputtext"
          [formControlName]="control" />
        <ng-container *ngIf="control === 'modulo'">
          <input
            #modulo
            *ngVar="ibanControl.getConfigModulo() as moduloValue"
            [autoTab]="moduloValue"
            pInputText
            type="text"
            [class]="'waterp-inputtext w-' + (moduloValue > 1 ? moduloValue + '/4' : '1/3')"
            [formControlName]="control" />
        </ng-container>
      </ng-container>
    </div>
    <div class="h-4">
      <small *ngIf="ibanControl?.invalid" class="p-error">
        {{ resource.message.errorMandatory }}
      </small>
    </div>
  </div>
</div>
