<div class="lg:mx-56 md:mx-40" [formGroup]="localisationForm">
  <div class="grid grid-cols-3 gap-5 max-w-[500px] mx-auto">
    <div class="col-span-2">
      <div class="flex-col">
        <label for="commune" class="label-input required">{{
          resource.demandeBranchement.commune
        }}</label>
        <p-dropdown
          [options]="cities"
          formControlName="commune"
          [filter]="true"
          filterBy="Key,Value"
          class="w-full"
          optionLabel="Value"
          [placeholder]="resource.form.phDropDownList"
          [virtualScroll]="true"
          [virtualScrollItemSize]="50">
        </p-dropdown>
        <small
          class="p-error"
          *ngIf="commune?.touched && commune?.invalid && (commune?.errors)!['required']"
          >{{ resource.message.errorMandatory }}</small
        >
        <!-- Affichage spécifiant que plusieurs communes ont ce même code insee -->
        <div class="exclamation" *ngIf="afficherAvertissementMemeCodeInsee">
          <i class="pi pi-exclamation-triangle"></i>
          {{ resource.demandeBranchement.communesAvecMemeCodeInsee }}
        </div>
      </div>
    </div>
    <div class="col-span-3">
      <div class="flex-col">
        <label for="rue" class="label-input required">{{ resource.demandeBranchement.rue }}</label>
        <p-dropdown
          [options]="streets"
          optionValue="key"
          formControlName="rue"
          [filter]="true"
          filterBy="key,value"
          class="w-full"
          [placeholder]="resource.form.phDropDownList"
          [virtualScroll]="true"
          [virtualScrollItemSize]="50">
          <ng-template let-item pTemplate="selectedItem">
            <div class="item-value flex gap-2">
              <span class="label">{{ item.key }}</span>
              <span class="label">{{ item.value }}</span>
            </div>
          </ng-template>
          <ng-template let-item pTemplate="item">
            <div class="item-value">
              <span class="badge">{{ item.key }}</span>
              <span class="label">{{ item.value }}</span>
            </div>
          </ng-template>
        </p-dropdown>
        <small class="p-error" *ngIf="rue?.touched && rue?.invalid && (rue?.errors)!['required']">{{
          resource.message.errorMandatory
        }}</small>
      </div>
    </div>
    <div>
      <label for="numeroRue" class="label-input">{{ resource.demandeBranchement.nRue }}</label>
      <input
        type="text"
        pInputText
        class="app-input w-full"
        formControlName="numeroRue"
        pKeyFilter="int"
        [maxlength]="4" />
    </div>
    <div>
      <label for="cpl" class="label-input">{{ resource.demandeBranchement.cpl }}</label>
      <input
        type="text"
        pInputText
        pKeyFilter="alpha"
        class="app-input w-full"
        formControlName="cpl"
        [maxlength]="1" />
      <small class="p-error" *ngIf="cpl?.touched && cpl?.invalid && (cpl?.errors)!['pattern']">
        {{ resource.message.errorAlphaOnly }}
      </small>
    </div>
    <div>
      <label for="etage" class="label-input">{{ resource.demandeBranchement.etage }}</label>
      <input
        type="text"
        pInputText
        class="app-input w-full"
        formControlName="etage"
        [maxlength]="3" />
    </div>
    <div class="col-span-3">
      <label for="complementAdresse" class="label-input">{{
        resource.demandeBranchement.complementAdresse
      }}</label>
      <input
        type="text"
        pInputText
        class="app-input w-full"
        formControlName="complementAdresse"
        [maxlength]="35" />
    </div>
  </div>
</div>
<div class="flex flex-1 items-center justify-end space-x-3 mx-6 px-6 mt-10">
  <button class="button-cancel flex mr-0">
    <img class="py-0.5 pr-1" [src]="resource.svgPath.close" alt="closeSVG" />{{
      resource.form.cancel
    }}
  </button>
  <button class="button-submit flex" (click)="submitForm()">
    {{ resource.form.next }}<img class="py-0.5 pl-1" [src]="resource.svgPath.east" alt="eastSvg" />
  </button>
</div>
