import { IToTreeNode } from '../../interface/to-tree-node.interface';
import { WaterpTreeNode } from '../../interface/waterp-tree-node.interface';
import { ZoneAvecCentres } from './ZoneAvecCentre';
import { IZoneAvecCentres } from '../../interface/autorisations/zoneAvecCentres.interface';
import { Centre } from './Centre';
import { IZone } from '../../interface/autorisations/zone.interface';
import { ICentre } from '../../interface/autorisations/centre.interface';

export class ZonesAvecCentres implements IToTreeNode {
  /** @var zonesAvecCentres liste de Zones avec Centres */
  private zonesAvecCentres: ZoneAvecCentres[];

  constructor(data: IZoneAvecCentres[], zoneN2?: boolean) {
    this.zonesAvecCentres =
      data === undefined ? [] : data.map(zone => new ZoneAvecCentres(zone, zoneN2));
  }

  public has(item: ZoneAvecCentres | Centre): boolean {
    return this.zonesAvecCentres.some(z => z.has(item));
  }

  public hasZoneAvecCentres(item: ZoneAvecCentres): boolean {
    return this.zonesAvecCentres.some(z => z.getCode() === item.getCode());
  }

  private isZoneAvecCentres(item: ZoneAvecCentres | Centre): item is ZoneAvecCentres {
    return item instanceof ZoneAvecCentres;
  }

  private isCentre(item: ZoneAvecCentres | Centre): item is Centre {
    return item instanceof Centre;
  }

  public getType() {
    return 'ZonesAvecCentres';
  }

  /**
   * Permet de convertir cet objet en TreeNode
   * @returns La version TreeNode de cet objet
   */
  public toTreeNode(): WaterpTreeNode {
    const root: WaterpTreeNode = {
      expanded: true,
      leaf: false,
      children: [],
      tooltip: { text: 'Zones avec Centres' }
    };

    this.zonesAvecCentres.forEach(zone => {
      const zoneNode = zone.toTreeNode();
      root.children?.push(zoneNode);
    });

    return root;
  }

  public addZoneAvecCentres(zoneAvecCentres: ZoneAvecCentres) {
    this.zonesAvecCentres.push(zoneAvecCentres);
  }

  public getZoneByCode(code: string | undefined): ZoneAvecCentres | undefined {
    return this.zonesAvecCentres.find(z => z.getCode() === code);
  }

  public set(params: {
    this: ZoneAvecCentres | Centre;
    in?: ZoneAvecCentres;
    from?: ZoneAvecCentres;
  }): void {
    const item = params.this;
    if (this.has(item)) {
      return;
    }
    if (this.isZoneAvecCentres(item)) {
      if (!this.hasZoneAvecCentres(item)) {
        this.addZoneAvecCentres(item);
      }
    } else {
      if (params.in === undefined) {
        return;
      }
      let zone = this.getZoneByCode(params.in.getCode());
      if (zone === undefined) {
        zone = params.in;
        this.addZoneAvecCentres(zone);
      }
      if (this.isCentre(item)) {
        zone.addCentre(item);
      }
    }
  }

  public removeZone(code: string | undefined): void {
    this.zonesAvecCentres = this.zonesAvecCentres.filter(
      z => z.getCode() !== (code ?? 'SANS_ZONE')
    );
  }

  public remove(params: {
    this: ZoneAvecCentres | Centre;
    in?: ZoneAvecCentres;
    from?: ZoneAvecCentres;
  }): void {
    const item = params.this;
    if (this.isZoneAvecCentres(item)) {
      this.removeZone(item.getCode());
    } else {
      if (params.in === undefined) {
        return;
      }
      const zone = this.getZoneByCode(params.in?.getCode());
      if (zone === undefined) {
        return;
      }
      if (this.isCentre(item)) {
        zone.removeCentre(item.getNumero());
      }
      if (zone?.getCentres()?.length == 0) {
        this.removeZone(zone.getCode());
      }
    }
  }

  public sort(ascend: boolean = true): void {
    this.zonesAvecCentres.sort((a, b) =>
      ascend ? a.getCode().localeCompare(b.getCode()) : b.getCode().localeCompare(a.getCode())
    );
    this.zonesAvecCentres.forEach(zone => {
      zone.sort();
    });
  }

  public getZones(): IZone[] {
    return this.zonesAvecCentres
      .filter(zone => zone.getCode() !== 'SANS_ZONE')
      .map(zone => ({
        Code: zone.getCode(),
        Libelle: zone.getLibelle()
      }));
  }

  public getCentres(): ICentre[] {
    return this.zonesAvecCentres.flatMap(
      zone =>
        zone.getCentres()?.map(centre => ({
          Numero: centre.getNumero(),
          Nom: centre.getNom()
        })) || []
    );
  }

  public doWhenSelect(...args: any) {}
  public doWhenUnselect(...args: any) {}
}
