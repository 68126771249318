import { Profil } from 'src/app/api/models/class/profil';
import { formatDateUnixToNumber } from 'src/app/shared/utils/dateUnixUtils';
import { Flag } from '../enum/flag.enum';
import { NiveauHabilitation } from '../interface/NiveauHabilitation';
import { OrigineTypeRessource } from '../interface/OrigineTypeRessource';
import { TypeRessource } from '../interface/TypeRessource';
import { RessourceLightDto } from '../request/ressource/ressource-light.dto';
import { DateStringNull } from '../type-alias/type-alias';
import { Unite } from './unite';
import { WebFonctions } from '../interface/WebFunction';
import { IRight } from '../interface/autorisations/right.interface';
import { IProcedure } from '../interface/autorisations/procedure.interface';
import { ISensible } from '../interface/autorisations/information-sensible.interface';
import { ToStringStandard } from 'src/app/shared/utils/formatDataUtils';

export class Ressource {
  private Matricule: string;
  private Nom: string;
  private IsActive: string;
  private NumeroPdaReleve: string;
  private ReferencePdaIntervention: string;
  private AdresseMailPassWord: string;
  private Email: string;
  private EmailsCopie: string;
  private NumeroTelUrgence: string;
  private LibelleUrgence: string;
  private LibelleHeureNonOuvrable: string;
  private NumeroTelNonOuvrable: string;
  private CodeIntervenant: string;
  private Login: string;
  private MotDePasse: string;
  private DateModificationMotDePasse?: Date;
  private CodeNiveauHierarchique: string;
  private MatriculeHierarchie: string;
  private CoordonneesGPSx: number;
  private CoordonneesGPSy: number;
  private DateSynchronisation?: Date;
  private CodeMatriculeVeolia: string;
  private IsAdmin: boolean;
  private AuthKey: string;
  private UseCrypto: boolean;
  private Salt: string;
  private IsPasswordExpirable: boolean;
  private NombreTentativeConnexion: number;
  private DateLockoutEnd: DateStringNull;
  private IndicateurValeurEditeur: boolean;
  private IndicateurMobiliteReleve: boolean;
  private IndicateurMobiliteIntervention: boolean;
  private Unite?: Unite;
  private FonctionsWeb!: WebFonctions;
  private InformationsSensiblesWeb!: IRight[];
  private Applications!: IRight[];
  private Procedures!: IProcedure[];
  private InformationsSensibles!: ISensible[];
  private TypeRessource!: TypeRessource;
  private NiveauHabilitation!: NiveauHabilitation;
  private OrigineTypeRessource!: OrigineTypeRessource;
  private Profils: Profil[] = [];
  private Profil: string = '';

  /**
   * Constructeur d'instance de l'entité Ressource
   * @param distantRessource Dans le cas de reception de ressource d'une requête GET
   *                  les entités peuvent être directement données à ce constructeur
   */
  constructor(distantRessource?: Ressource) {
    this.Matricule = distantRessource?.Matricule ?? '';
    this.Nom = distantRessource?.Nom ?? '';
    this.IsActive = distantRessource?.IsActive ?? '';
    this.NumeroPdaReleve = distantRessource?.NumeroPdaReleve ?? '';
    this.ReferencePdaIntervention = distantRessource?.ReferencePdaIntervention ?? '';
    this.AdresseMailPassWord = distantRessource?.AdresseMailPassWord ?? '';
    this.Email = distantRessource?.Email ?? '';
    this.EmailsCopie = distantRessource?.EmailsCopie ?? '';
    this.NumeroTelUrgence = distantRessource?.NumeroTelUrgence ?? '';
    this.LibelleUrgence = distantRessource?.LibelleUrgence ?? '';
    this.LibelleHeureNonOuvrable = distantRessource?.LibelleHeureNonOuvrable ?? '';
    this.NumeroTelNonOuvrable = distantRessource?.NumeroTelNonOuvrable ?? '';
    this.CodeIntervenant = distantRessource?.CodeIntervenant ?? '';
    this.Login = distantRessource?.Login ?? '';
    this.MotDePasse = distantRessource?.MotDePasse ?? '';
    this.DateModificationMotDePasse = distantRessource?.DateModificationMotDePasse
      ? new Date(formatDateUnixToNumber(distantRessource?.DateModificationMotDePasse))
      : undefined;
    this.CodeNiveauHierarchique = distantRessource?.CodeNiveauHierarchique ?? '';
    this.MatriculeHierarchie = distantRessource?.MatriculeHierarchie ?? '';
    this.CoordonneesGPSx = distantRessource?.CoordonneesGPSx ?? 0;
    this.CoordonneesGPSy = distantRessource?.CoordonneesGPSy ?? 0;
    this.DateSynchronisation = distantRessource?.DateSynchronisation
      ? new Date(formatDateUnixToNumber(distantRessource?.DateSynchronisation))
      : undefined;
    this.CodeMatriculeVeolia = distantRessource?.CodeMatriculeVeolia ?? '';
    this.IsAdmin = distantRessource?.IsAdmin ?? false;
    this.AuthKey = distantRessource?.AuthKey ?? '';
    this.UseCrypto = distantRessource?.UseCrypto ?? false;
    this.Salt = distantRessource?.Salt ?? '';
    this.IsPasswordExpirable = distantRessource?.IsPasswordExpirable ?? false;
    this.NombreTentativeConnexion = distantRessource?.NombreTentativeConnexion ?? 0;
    this.DateLockoutEnd = distantRessource?.DateLockoutEnd ?? '';
    this.IndicateurValeurEditeur = distantRessource?.IndicateurValeurEditeur ?? false;
    this.IndicateurMobiliteReleve = distantRessource?.IndicateurMobiliteReleve ?? false;
    this.IndicateurMobiliteIntervention = distantRessource?.IndicateurMobiliteIntervention ?? false;
    if (distantRessource) {
      this.Unite = distantRessource.Unite ? new Unite(distantRessource.Unite) : undefined;
      this.FonctionsWeb = distantRessource.FonctionsWeb;
      this.InformationsSensiblesWeb = distantRessource.InformationsSensiblesWeb;
      this.Applications = distantRessource.Applications;
      this.Procedures = distantRessource.Procedures;
      this.InformationsSensibles = distantRessource.InformationsSensibles;
      this.TypeRessource = distantRessource.TypeRessource;
      this.NiveauHabilitation = distantRessource.NiveauHabilitation;
      this.OrigineTypeRessource = distantRessource.OrigineTypeRessource;
      this.Profils = distantRessource.Profils.map((profil: Profil) => new Profil(profil));
      this.Profil = this.Profils.map((profil: Profil) =>
        profil.getLibelle() !== '' ? profil.getLibelle() : profil.getCode()
      ).join(',');
    }
  }

  //#region Accesseurs
  public getMatricule(): string {
    return this.Matricule;
  }

  public setMatricule(Matricule: string): void {
    this.Matricule = Matricule;
  }

  public getNom(): string {
    return this.Nom;
  }

  public setNom(Nom: string): void {
    this.Nom = Nom;
  }

  public getIsActive(): string {
    return this.IsActive;
  }

  public setIsActive(IsActive: string): void {
    this.IsActive = IsActive;
  }

  public getNumeroPdaReleve(): string {
    return this.NumeroPdaReleve;
  }

  public setNumeroPdaReleve(NumeroPdaReleve: string): void {
    this.NumeroPdaReleve = NumeroPdaReleve;
  }

  public getReferencePdaIntervention(): string {
    return this.ReferencePdaIntervention;
  }

  public setReferencePdaIntervention(ReferencePdaIntervention: string): void {
    this.ReferencePdaIntervention = ReferencePdaIntervention;
  }

  public getAdresseMailPassWord(): string {
    return this.AdresseMailPassWord;
  }

  public setAdresseMailPassWord(Email: string): void {
    this.AdresseMailPassWord = Email;
  }
  public getEmail(): string {
    return this.Email;
  }

  public setEmail(Email: string): void {
    this.Email = Email;
  }
  public getEmailsCopie(): string {
    return this.EmailsCopie;
  }

  public setEmailsCopie(EmailsCopie: string): void {
    this.EmailsCopie = EmailsCopie;
  }

  public getNumeroTelUrgence(): string {
    return this.NumeroTelUrgence;
  }

  public setNumeroTelUrgence(NumeroTelUrgence: string): void {
    this.NumeroTelUrgence = NumeroTelUrgence;
  }

  public getLibelleUrgence(): string {
    return this.LibelleUrgence;
  }

  public setLibelleUrgence(LibelleUrgence: string): void {
    this.LibelleUrgence = LibelleUrgence;
  }

  public getLibelleHeureNonOuvrable(): string {
    return this.LibelleHeureNonOuvrable;
  }

  public setLibelleHeureNonOuvrable(LibelleHeureNonOuvrable: string): void {
    this.LibelleHeureNonOuvrable = LibelleHeureNonOuvrable;
  }

  public getNumeroTelNonOuvrable(): string {
    return this.NumeroTelNonOuvrable;
  }

  public setNumeroTelNonOuvrable(NumeroTelNonOuvrable: string): void {
    this.NumeroTelNonOuvrable = NumeroTelNonOuvrable;
  }

  public getCodeIntervenant(): string {
    return this.CodeIntervenant;
  }

  public setCodeIntervenant(CodeIntervenant: string): void {
    this.CodeIntervenant = CodeIntervenant;
  }

  public getLogin(): string {
    return this.Login;
  }

  public setLogin(Login: string): void {
    this.Login = Login;
  }

  public getMotDePasse(): string {
    return this.MotDePasse;
  }

  public setMotDePasse(MotDePasse: string): void {
    this.MotDePasse = MotDePasse;
  }

  public getDateModificationMotDePasse(): Date | undefined {
    return this.DateModificationMotDePasse;
  }

  public setDateModificationMotDePasse(DateModificationMotDePasse: Date): void {
    this.DateModificationMotDePasse = DateModificationMotDePasse;
  }

  public getCodeNiveauHierarchique(): string {
    return this.CodeNiveauHierarchique;
  }

  public setCodeNiveauHierarchique(CodeNiveauHierarchique: string): void {
    this.CodeNiveauHierarchique = CodeNiveauHierarchique;
  }

  public getMatriculeHierarchie(): string {
    return this.MatriculeHierarchie;
  }

  public setMatriculeHierarchie(MatriculeHierarchie: string): void {
    this.MatriculeHierarchie = MatriculeHierarchie;
  }

  public getCoordonneesGPSx(): number {
    return this.CoordonneesGPSx ?? 0;
  }

  public setCoordonneesGPSx(CoordonneesGPSx: number): void {
    this.CoordonneesGPSx = CoordonneesGPSx;
  }

  public getCoordonneesGPSy(): number {
    return this.CoordonneesGPSy ?? 0;
  }

  public setCoordonneesGPSy(CoordonneesGPSy: number): void {
    this.CoordonneesGPSy = CoordonneesGPSy;
  }

  public getDateSynchronisation(): Date | undefined {
    return this.DateSynchronisation;
  }

  public setDateSynchronisation(DateSynchronisation: Date): void {
    this.DateSynchronisation = DateSynchronisation;
  }

  public getCodeMatriculeVeolia(): string {
    return this.CodeMatriculeVeolia;
  }

  public setCodeMatriculeVeolia(CodeMatriculeVeolia: string): void {
    this.CodeMatriculeVeolia = CodeMatriculeVeolia;
  }

  public getIsAdmin(): boolean {
    return this.IsAdmin ?? false;
  }

  public setIsAdmin(IsAdmin: boolean): void {
    this.IsAdmin = IsAdmin;
  }

  public getAuthKey(): string {
    return this.AuthKey;
  }

  public setAuthKey(AuthKey: string): void {
    this.AuthKey = AuthKey;
  }

  public getUseCrypto(): boolean {
    return this.UseCrypto ?? false;
  }

  public setUseCrypto(UseCrypto: boolean): void {
    this.UseCrypto = UseCrypto;
  }

  public getSalt(): string {
    return this.Salt;
  }

  public setSalt(Salt: string): void {
    this.Salt = Salt;
  }

  public getIsPasswordExpirable(): boolean {
    return this.IsPasswordExpirable ?? false;
  }

  public setIsPasswordExpirable(IsPasswordExpirable: boolean): void {
    this.IsPasswordExpirable = IsPasswordExpirable;
  }

  public getNombreTentativeConnexion(): number {
    return this.NombreTentativeConnexion ?? 0;
  }

  public setNombreTentativeConnexion(NombreTentativeConnexion: number): void {
    this.NombreTentativeConnexion = NombreTentativeConnexion;
  }

  public getDateLockoutEnd(): DateStringNull {
    return this.DateLockoutEnd;
  }

  public setDateLockoutEnd(DateLockoutEnd: DateStringNull): void {
    this.DateLockoutEnd = DateLockoutEnd;
  }

  public getIndicateurValeurEditeur(): boolean {
    return this.IndicateurValeurEditeur ?? false;
  }

  public setIndicateurValeurEditeur(IndicateurValeurEditeur: boolean): void {
    this.IndicateurValeurEditeur = IndicateurValeurEditeur;
  }

  public getIndicateurMobiliteReleve(): boolean {
    return this.IndicateurMobiliteReleve;
  }

  public setIndicateurMobiliteReleve(IndicateurMobiliteReleve: boolean): void {
    this.IndicateurMobiliteReleve = IndicateurMobiliteReleve;
  }

  public getIndicateurMobiliteIntervention(): boolean {
    return this.IndicateurMobiliteIntervention;
  }

  public setIndicateurMobiliteIntervention(IndicateurMobiliteIntervention: boolean): void {
    this.IndicateurMobiliteIntervention = IndicateurMobiliteIntervention;
  }

  public getUnite(): Unite | undefined {
    return this.Unite;
  }

  public setUnite(Unite: Unite): void {
    this.Unite = Unite;
  }

  public getFonctionsWeb(): WebFonctions {
    return this.FonctionsWeb;
  }

  public setFonctionsWeb(FonctionsWeb: WebFonctions): void {
    this.FonctionsWeb = FonctionsWeb;
  }

  public getInformationsSensiblesWeb(): IRight[] {
    return this.InformationsSensiblesWeb;
  }

  public setInformationsSensiblesWeb(InformationsSensiblesWeb: IRight[]): void {
    this.InformationsSensiblesWeb = InformationsSensiblesWeb;
  }

  public getApplications(): IRight[] {
    return this.Applications;
  }

  public setApplications(Applications: IRight[]): void {
    this.Applications = Applications;
  }

  public getProcedures(): IProcedure[] {
    return this.Procedures;
  }

  public setProcedures(Procedures: IProcedure[]): void {
    this.Procedures = Procedures;
  }

  public getZonesSensibles(): ISensible[] {
    return this.InformationsSensibles;
  }

  public setZonesSensibles(InformationsSensibles: ISensible[]): void {
    this.InformationsSensibles = InformationsSensibles;
  }

  public getTypeRessource(): TypeRessource {
    return this.TypeRessource;
  }

  public setTypeRessource(TypeRessource: TypeRessource): void {
    this.TypeRessource = TypeRessource;
  }

  public getNiveauHabilitation(): NiveauHabilitation {
    return this.NiveauHabilitation;
  }

  public setNiveauHabilitation(NiveauHabilitation: NiveauHabilitation): void {
    this.NiveauHabilitation = NiveauHabilitation;
  }

  public getOrigineTypeRessource(): OrigineTypeRessource {
    return this.OrigineTypeRessource;
  }

  public setOrigineTypeRessource(OrigineTypeRessource: OrigineTypeRessource): void {
    this.OrigineTypeRessource = OrigineTypeRessource;
  }

  public getProfils(): Profil[] {
    return this.Profils;
  }

  public setProfils(Profils: Profil[]): void {
    this.Profils = Profils;
  }

  public getProfil(): string {
    return this.Profil;
  }

  public setProfil(Profil: string): void {
    this.Profil = Profil;
  }
  //#endregion

  /**
   * Convertie l'entité en une RessourceLightDto
   * @returns Un RessourceLightDto
   */
  public toLightDto(): RessourceLightDto {
    return new RessourceLightDto(this);
  }

  /**
   * Permet de transformer l'objet en un format Exportable sur Excel
   * @returns Le format Excel attendu
   */
  public toExcelExportFormat(): {
    Matricule: string;
    Nom: string;
    IsActive: string;
    NumeroPdaReleve: string;
    ReferencePdaIntervention: string;
    AdresseMailPassWord: string;
    Email: string;
    EmailsCopie: string;
    NumeroTelUrgence: string;
    LibelleUrgence: string;
    LibelleHeureNonOuvrable: string;
    NumeroTelNonOuvrable: string;
    CodeIntervenant: string;
    Login: string;
    MotDePasse: string;
    DateModificationMotDePasse: string;
    CodeNiveauHierarchique: string;
    MatriculeHierarchie: string;
    CoordonneesGPSx: string;
    CoordonneesGPSy: string;
    DateSynchronisation: string;
    CodeMatriculeVeolia: string;
    IsAdmin: string;
    AuthKey: string;
    UseCrypto: string;
    Salt: string;
    IsPasswordExpirable: string;
    NombreTentativeConnexion: string;
    DateLockoutEnd: string;
    IndicateurValeurEditeur: string;
    IndicateurMobiliteReleve: string;
    IndicateurMobiliteIntervention: string;
    Unite: { Numero: string };
    FonctionsWeb: string;
    TypeRessource: string;
    NiveauHabilitation: string;
    OrigineTypeRessource: string;
    Profils: string;
  } {
    return {
      Matricule: this.Matricule ?? '',
      Nom: this.Nom ?? '',
      IsActive: this.IsActive === Flag.ACTIVE ? 'Actif' : 'Désactivé',
      NumeroPdaReleve: this.NumeroPdaReleve ?? '',
      ReferencePdaIntervention: this.ReferencePdaIntervention ?? '',
      AdresseMailPassWord: this.AdresseMailPassWord ?? '',
      Email: this.Email ?? '',
      EmailsCopie: this.EmailsCopie ?? '',
      NumeroTelUrgence: this.NumeroTelUrgence ?? '',
      LibelleUrgence: this.LibelleUrgence ?? '',
      LibelleHeureNonOuvrable: this.LibelleHeureNonOuvrable ?? '',
      NumeroTelNonOuvrable: this.NumeroTelNonOuvrable ?? '',
      CodeIntervenant: this.CodeIntervenant ?? '',
      Login: this.Login ?? '',
      MotDePasse: this.MotDePasse ?? '',
      DateModificationMotDePasse: this.DateModificationMotDePasse ? ToStringStandard(this.DateModificationMotDePasse) : '',
      CodeNiveauHierarchique: this.CodeNiveauHierarchique ?? '',
      MatriculeHierarchie: this.MatriculeHierarchie ?? '',
      CoordonneesGPSx: this.CoordonneesGPSx.toString() ?? '',
      CoordonneesGPSy: this.CoordonneesGPSy.toString() ?? '',
      DateSynchronisation: this.DateSynchronisation ? ToStringStandard(this.DateSynchronisation) : '',
      CodeMatriculeVeolia: this.CodeMatriculeVeolia ?? '',
      IsAdmin: this.IsAdmin ? 'OUI' : 'NON',
      AuthKey: '',
      UseCrypto: this.UseCrypto ? 'OUI' : 'NON',
      Salt: '',
      IsPasswordExpirable: this.IsPasswordExpirable ? 'OUI' : 'NON',
      NombreTentativeConnexion: this.NombreTentativeConnexion.toString() ?? '',
      DateLockoutEnd: this.DateLockoutEnd?.toString() ?? '',
      IndicateurValeurEditeur: this.IndicateurValeurEditeur ? 'OUI' : 'NON',
      IndicateurMobiliteReleve: this.IndicateurMobiliteReleve ? 'OUI' : 'NON',
      IndicateurMobiliteIntervention: this.IndicateurMobiliteIntervention ? 'OUI' : 'NON',
      Unite: {
        Numero: this.Unite ? this.Unite.getNumero() + ' - ' + this.Unite.getNom() : ''
      },
      FonctionsWeb: this.FonctionsWeb?.map(fonction => fonction.Libelle).join(', ') ?? '',
      TypeRessource: this.TypeRessource?.Libelle ?? '',
      NiveauHabilitation: this.NiveauHabilitation?.Libelle ?? '',
      OrigineTypeRessource: this.OrigineTypeRessource?.Libelle ?? '',
      Profils:
        this.Profils?.map((profil: Profil) =>
          profil.getLibelle() != '' ? profil.getLibelle() : profil.getCode()
        ).join(', ') ?? ''
    };
  }
}
