import { Resource } from 'src/app/resources/resource';
import { SweetAlertCustomClass, SweetAlertIcon } from 'sweetalert2';

/**
 * ? SURCOUCHE Waterp pour les déclarations de SweetAlertOptions
 */
export class WaterpSwal {
  public titleText?: string;
  public text?: string;
  public html?: string | HTMLElement | JQuery;
  public icon?: SweetAlertIcon;
  public showCloseButton?: boolean;
  public customClass?: SweetAlertCustomClass;
  public allowOutsideClick?: boolean;
  public allowEscapeKey?: boolean;

  public showConfirmButton?: boolean;
  public confirmButtonText?: string;
  public confirmButtonColor?: string;

  public showCancelButton?: boolean;
  public cancelButtonText?: string;
  public cancelButtonColor?: string;

  /**
   * Constructeur de configuration de SweetAlertOptions pour le projet Wat.erp Web
   * @param resource Service de référencement des textes de l'application (Non définitif)
   * @param context Indique par les options disponible quel sera le contexte de l'information
   * @param optionnal Suite des paramètres automatiquement gérés mais pouvant varier suivant les cas
   * @param optionnal.title Titre de la Swal
   * @param optionnal.text Contenue de la Swal, peut contenir du code HTML
   * @param optionnal.buttonsText Permet de préciser le texte pour le bouton confirm et cancel
   * @param optionnal.withConfirm Indique si le bouton de confirmation doit être affiché
   * @param optionnal.withCancel Indique si le bouton d'annulation doit être affiché
   */
  constructor(
    resource: Resource,
    context: SweetAlertIcon,
    optionnal?: {
      title?: string;
      text?: string;
      buttonsText?: { confirm?: string; cancel?: string };
      withConfirm?: boolean;
      withCancel?: boolean;
      showCloseButton?: boolean;
      allowOutsideClick?: boolean;
      sameColorButtons?: boolean;
    }
  ) {
    this.icon = context;
    this.showConfirmButton = optionnal?.withConfirm ?? true;
    this.showCancelButton = optionnal?.withCancel ?? true;
    this.showCloseButton = optionnal?.showCloseButton ?? false;
    this.allowOutsideClick = optionnal?.allowOutsideClick ?? false;

    switch (context) {
      case 'success':
        this.titleText = optionnal?.title ?? resource.GlobalTexts.Generic.Success_Title;
        this.text = optionnal?.text ?? resource.GlobalTexts.Generic.SaveSuccess;
        break;
      case 'warning':
        this.titleText = optionnal?.title ?? resource.GlobalTexts.Generic.SweetAlert_Warning;
        this.text = optionnal?.text ?? resource.GlobalTexts.Generic.Warning;
        break;
      case 'error':
        this.titleText = optionnal?.title ?? resource.GlobalTexts.Generic.Error_Title;
        this.text = optionnal?.text ?? resource.GlobalTexts.Generic.Error;
        break;
      case 'info':
      case 'question':
        this.titleText = optionnal?.title ?? '';
        this.text = optionnal?.text ?? '';
        break;
      default:
        break;
    }

    if (['success', 'error', 'info'].includes(context)) {
      this.confirmButtonText = optionnal?.buttonsText?.confirm ?? resource.swal.fermer;
      this.showCancelButton = optionnal?.withCancel ?? false;
      if (this.showCancelButton) {
        this.cancelButtonText = optionnal?.buttonsText?.cancel ?? resource.swal.non;
      }
    } else {
      this.confirmButtonText = optionnal?.buttonsText?.confirm ?? resource.swal.oui;
      this.cancelButtonText = optionnal?.buttonsText?.cancel ?? resource.swal.non;
    }

    if (optionnal?.text) {
      if (
        optionnal.text.includes('<br>') ||
        optionnal.text.includes('<') ||
        optionnal.text.includes('>')
      ) {
        this.html = optionnal.text;
      } else {
        this.text = optionnal.text;
      }
    }

    this.allowEscapeKey = false;
    this.confirmButtonColor = resource.swal.confirmer_color;
    this.cancelButtonColor = optionnal?.sameColorButtons
      ? resource.swal.confirmer_color
      : resource.swal.cancel_color;

    this.customClass = {
      confirmButton: resource.swal.confirmer_css,
      cancelButton: resource.swal.cancel_css
    };
  }
}
